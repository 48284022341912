/* eslint-disable max-len */
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useVendorService } from '@src/hooks/queries/vendors_services';
import { IBusinessVendor } from '@src/types/business_vendors';

import Form from '@src/components/ui_v2/form';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { TOption } from '@src/components/ui_v2/inputs';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';
import { CheckGreenIcon, FormVisibilityIcon, FormInvisibilityIcon } from '@src/components/utils/icomoon';

import { IBusinessAchAccountInput, businessAchAccountValidation } from './schema';

import styles from './styles.module.scss';

interface IAddAchBankAccountProps {
  vendor: IBusinessVendor
  formId?: string;
  onSubmit: SubmitHandler<IBusinessAchAccountInput>;
  setIsFormValid: (isValid: boolean) => void;
}

const AddAchBankAccount = ({
  vendor,
  formId,
  onSubmit,
  setIsFormValid,
}: IAddAchBankAccountProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IBusinessAchAccountInput>({
    resolver: yupResolver(businessAchAccountValidation),
  });

  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [selectedValues, setSelectedValues] = useState<TOption[]>([]);
  const [confirmAccountType, setConfirmAccountType] = useState('password');

  const accountNumber = watch('account_number');
  const confirmAccountNumber = watch('account_confirm_number');
  const businessName = watch('business_name');
  const bankName = watch('name');
  const email = watch('email');
  const routingNumber = watch('routing_number');
  const agreementChecked = watch('agreement');

  const isMatch = accountNumber === confirmAccountNumber;

  const isFormValid = !!(
    selectedValues.length > 0
    && businessName
    && bankName
    && email
    && routingNumber
    && accountNumber
    && confirmAccountNumber
    && agreementChecked
  );

  const query = useVendorService({ role: 'admin' });
  const businessServices = useMemo(() => {
    return query.data?.vendorServices?.map((business: any) => business) || [];
  }, [query.data]);

  const options = useMemo(() => {
    return businessServices.map((service: any) => ({
      label: service.business.legalName,
      value: service.business.id,
    }));
  }, [businessServices]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    setIsFormValid(isFormValid);
  }, [isFormValid, setIsFormValid]);

  useEffect(() => {
    const defaultOption = options.find((option) => option.value === vendor.businessId);
    if (defaultOption) {
      setSelectedValues([defaultOption]);
      setValue('business_ids', [defaultOption.value]);
    }
  }, [options, vendor.businessId, setValue]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <>
        <Wrapper hiddenLabel label="Business(es)">
          <div className={ styles['form-container'] }>
            <p className={ styles['form-label'] }>Business(es)</p>
            <div className={ styles['form-field'] }>
              <MultiSelectAddItem
                addItem
                menuClassName={ styles['dropdown-menu'] }
                options={ options }
                placeholder="Select Business(es)"
                value={ selectedValues }
                onChange={ (selectedOptions: TOption[]) => {
                  setSelectedValues(selectedOptions);
                  setValue('business_ids', selectedOptions.map((option: any) => option.value));
                } }
              />
            </div>
          </div>
        </Wrapper>
        <div className={ styles['toggle-container'] }>
          <span className={ styles['toggle-label'] }>Receiver Type</span>
          <div className={ styles['toggle-button'] }>
            <div className={ styles['toggle-option'] }>
              <input
                id="business"
                type="radio"
                value="business"
                { ...register('receiver_type') }
                defaultChecked
                className={ styles['toggle-option-input'] }
              />
              <label className={ styles['toggle-option-label'] } htmlFor="business">
                <span />
                Business
              </label>
            </div>
            <div className={ styles['toggle-option'] }>
              <input
                id="individual"
                type="radio"
                value="individual"
                { ...register('receiver_type') }
                className={ styles['toggle-option-input'] }
              />
              <label className={ styles['toggle-option-label'] } htmlFor="individual">
                <span />
                Individual
              </label>
            </div>
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Receiver Business Name</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Receiver Business Name"
              placeholder="Enter receiver business name"
              { ...register('business_name') }
              error={ errors.business_name?.message }
            />
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Receiver Bank Name</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Receiver Bank Name"
              placeholder="Enter bank name"
              { ...register('name') }
              error={ errors.name?.message }
            />
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Receiver Email Address</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Receiver Email Address"
              placeholder="example@domain.com"
              { ...register('email') }
              error={ errors.email?.message }
            />
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Account Number</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Account Number"
              placeholder="Enter account number"
              suffixIcon={ (
                <div style={ { marginBottom: '6px', marginLeft: '13px' } }>
                  {showAccountNumber ? (
                    <FormVisibilityIcon
                      fontSize={ 20 }
                      onClick={ () => setShowAccountNumber(!showAccountNumber) }
                    />
                  ) : (
                    <FormInvisibilityIcon
                      fontSize={ 20 }
                      onClick={ () => setShowAccountNumber(!showAccountNumber) }
                    />
                  )}
                </div>
          ) }
              type={ showAccountNumber ? 'text' : 'password' }
              { ...register('account_number') }
              error={ errors.account_number?.message }
            />
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Re-enter Account Number</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Confirm Account Number"
              labelClass={ styles['form-field-label'] }
              placeholder="Re-enter account number"
              suffixIcon={
            isMatch && confirmAccountNumber && (
              <div style={ { marginBottom: '6px', marginLeft: '13px' } }>
                <CheckGreenIcon fontSize={ 2 } />
              </div>
            )
          }
              type={ confirmAccountType }
              { ...register('account_confirm_number') }
              error={ errors.account_confirm_number?.message }
              onBlur={ () => setConfirmAccountType('password') }
              onFocus={ () => setConfirmAccountType('text') }
              onPaste={ handlePaste }
            />
          </div>
        </div>
        <div className={ styles['form-container'] }>
          <p className={ styles['form-label'] }>Routing Number</p>
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              label="Routing Number"
              placeholder="XXXXXXXXX"
              { ...register('routing_number') }
              error={ errors.routing_number?.message }
            />
          </div>
        </div>
        <div className={ styles['checkbox-container'] }>
          <div className={ styles['checkbox-container-row'] }>
            <input
              id="agreement"
              type="checkbox"
              { ...register('agreement') }
            />
            <label htmlFor="agreement">
              <span>
                By checking this box, you confirm you have received
              </span>
              <br />
              <span>
                written authorization from this vendor to:
              </span>
              <ul>
                <li>
                  Collect, use, and share the user’s personal identifying and banking account information with Docyt, Inc.
                </li>
                <li>Originate transfers to this bank account.</li>
              </ul>
              <span>
                You must be able to provide a copy of this authorization
              </span>
              <br />
              <span>
                to Docyt, Inc. at any time upon request.
              </span>
            </label>
          </div>
        </div>
      </>
    </Form>
  );
};

export { IAddAchBankAccountProps, AddAchBankAccount as default };
