import React from 'react';

import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { TID } from '@src/types/common';
import { isFeatureEnabled } from '@src/utils/config';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IInvoiceQueueHeaderProps {
  businessId: TID,
  type: string,
}

const InvoiceQueueHeader = ({
  type,
  businessId,
}: IInvoiceQueueHeaderProps): JSX.Element | null => {
  const service = useAccountsPayableServiceContext();

  const queueUrl = (queueType: string) => {
    return `/businesses/${businessId}/accounts_payable/queue?type=${queueType}`;
  };
  return (
    <div className="page-main-header clearfix">
      <div className={ styles['header-container'] }>
        <div className={ styles['header-title'] }>
          <Text fontSize={ 24 } fontVariant="bold">Invoice Queue</Text>
        </div>
        <ul className="nav sub-header-menu m-t-0 m-b-0">
          <li className={ `m-r-24 ${type === 'queued' && 'active'}` }>
            <a
              href={ queueUrl('queued') }
              role="button"
              tabIndex={ 0 }
            >
              { `QUEUED (${service?.countQueuedInvoices || 0})` }
            </a>
          </li>
          <li className={ `m-r-24 ${type === 'paid' && 'active'}` }>
            <a
              href={ queueUrl('paid') }
              role="button"
              tabIndex={ 0 }
            >
              { `PAID (${service?.countPaidInvoices || 0})` }
            </a>
          </li>
          <li className={ `${type === 'stopped' && 'active'}` }>
            <a
              href={ queueUrl('stopped') }
              role="button"
              tabIndex={ 0 }
            >
              { `STOPPED (${service?.countStoppedInvoices || 0})` }
            </a>
          </li>
        </ul>
        <div className="display-flex align-items-center">
          {
            isFeatureEnabled('import_invoices_from_qbo') && (
              <Button
                className={ styles['import-invoices-btn'] }
                variant="primary"
              >
                Import from QuickBooks
              </Button>
            )
          }
          <Button
            className="font-semi-bold"
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="primary"
          >
            Add invoice
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceQueueHeader;
