import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { PaidIcon, BusinessIcon, CashIcon, CreditCardIcon, VendorCreditIcon } from '@src/components/utils/icomoon';

interface IMarkPaidInvoicesActionProps {
  invoiceIds: TID[],
}

const MarkPaidInvoicesAction = ({
  invoiceIds,
}: IMarkPaidInvoicesActionProps) => {
  const handleCashSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Cash'`);
  }, [invoiceIds]);

  const handleCardSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Credit Card'`);
  }, [invoiceIds]);

  const handleManualSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Manual Check'`);
  }, [invoiceIds]);

  const handleBankSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Bank Account(ACH/Debit Card)'`);
  }, [invoiceIds]);

  const handleSettlementSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Advance Settlement...'`);
  }, [invoiceIds]);

  const handleVendorSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Mark as Paid - Vendor Credit'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Submenu
      icon={ <PaidIcon fontSize={ 18 } /> }
      name="Mark as paid"
    >
      <ActionsDropdown.Action
        icon={ <CashIcon fontSize={ 18 } /> }
        name="Cash"
        onClick={ handleCashSelect }
      />
      <ActionsDropdown.Action
        icon={ <CreditCardIcon fontSize={ 18 } variant="simple" /> }
        name="Credit Card"
        onClick={ handleCardSelect }
      />
      <ActionsDropdown.Action
        icon={ <PaidIcon fontSize={ 18 } /> }
        name="Manual Check"
        onClick={ handleManualSelect }
      />
      <ActionsDropdown.Action
        icon={ <BusinessIcon fontSize={ 18 } /> }
        name="Bank Account(ACH / Debit Card)"
        onClick={ handleBankSelect }
      />
      <ActionsDropdown.Action
        icon={ <PaidIcon fontSize={ 18 } /> }
        name="Advanced Settlement..."
        onClick={ handleSettlementSelect }
      />
      <ActionsDropdown.Action
        icon={ <VendorCreditIcon fontSize={ 18 } /> }
        name="Vendor Credit"
        onClick={ handleVendorSelect }
      />
    </ActionsDropdown.Submenu>
  );
};

export default MarkPaidInvoicesAction;
