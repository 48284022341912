/* eslint-disable max-len */
import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CountryOptions } from '@src/constants/country_options';
import { UsaStateOptions } from '@src/constants/usa_state_options';
import { IAddress } from '@src/types/address';
import { TID } from '@src/types/common';

import SelectForm from '@src/components/ui/form';
import Form from '@src/components/ui_v2/form';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import { IUpdateAddressBookInput, businessAddressBookValidation } from './schema';

import styles from './styles.module.scss';

interface IAddressBookModalFormProps {
  formId?: string;
  businessId: TID;
  vendorId: TID;
  onSubmit: SubmitHandler<IUpdateAddressBookInput >;
  setIsFormValid: (isValid: boolean) => void;
  address?: IAddress;
}

const AddressBookModalForm = ({
  formId,
  businessId,
  vendorId,
  onSubmit,
  setIsFormValid,
  address,
}: IAddressBookModalFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IUpdateAddressBookInput>({
    defaultValues: {
      address: {
        addressCountry:  address?.addressCountry,
        name:            address?.name,
        company:         address?.company,
        addressLine1:    address?.addressLine1,
        addressCity:     address?.addressCity,
        addressState:    address?.addressState,
        addressZip:      address?.addressZip,
        addressableId:   address?.addressableId,
        id:              address?.id,
        addressableType: address?.addressableType,
        createdAt:       address?.createdAt,
        isGlobal:        address?.isGlobal,
        isLobVerified:   address?.isLobVerified,
        updatedAt:       address?.updatedAt,
      },
    },
    resolver: yupResolver(businessAddressBookValidation),
  });
  const checkPayableTo = watch('address.name');
  const addressLine = watch('address.addressLine1');
  const addressCountry = watch('address.addressCountry');
  const addressState = watch('address.addressState');
  const addressCity = watch('address.addressCity');
  const addressZip = watch('address.addressZip');

  const isFormValid = !!(
    checkPayableTo
    && addressLine
    && addressCountry
    && addressState
    && addressCity
    && addressZip
  );

  useEffect(() => {
    setValue('address.businessId', businessId);
    setValue('address.vendorId', vendorId);
  }, [businessId, setValue, vendorId]);

  useEffect(() => {
    setIsFormValid(isFormValid);
  }, [isFormValid, setIsFormValid]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <input type="hidden" value={ businessId } { ...register('address.businessId') } />
      <input type="hidden" value={ vendorId } { ...register('address.vendorId') } />
      <div className={ styles['form-container'] }>
        <p className={ styles['form-label'] }>Check Payable to</p>
        <div className={ styles['form-field'] }>
          <Form.TextField
            hiddenLabel
            hideClear
            label="Check Payable to"
            placeholder="Name"
            { ...register('address.name') }
            className={ styles['form-field-border'] }
            error={ errors.address?.name?.message }
          />
        </div>
      </div>
      <div className={ styles['form-container'] }>
        <p className={ styles['form-label'] }>
          <span>Company</span>
          <span className={ styles['optional-text'] }> (Optional)</span>
        </p>
        <div className={ styles['form-field'] }>
          <Form.TextField
            hiddenLabel
            hideClear
            label="Company"
            placeholder="Company name"
            { ...register('address.company') }
            className={ styles['form-field-border'] }
          />
        </div>
      </div>
      <div className={ styles['form-container'] }>
        <p className={ styles['form-label'] }>Address</p>
        <div className={ styles['form-field'] }>
          <Form.TextField
            hiddenLabel
            hideClear
            label="Address"
            placeholder="Street"
            { ...register('address.addressLine1') }
            className={ styles['form-field-border'] }
            error={ errors.address?.addressLine1?.message }
          />
        </div>
      </div>
      <div className={ styles['address-row-container'] }>
        <div className={ styles['address-row-column'] }>
          <Form.TextField
            hiddenLabel
            hideClear
            label="City"
            placeholder="City"
            { ...register('address.addressCity') }
            className={ styles['form-field-border'] }
            error={ errors.address?.addressCity?.message }
          />
        </div>
        <div className={ styles['address-row-column'] }>
          {
              watch('address.addressCountry') === 'US' ? (
                <SelectForm.SelectField
                  { ...register('address.addressState') }
                  className={ styles['select-field'] }
                  defaultValue="AL"
                  options={ UsaStateOptions }
                />
              ) : (
                <Form.TextField
                  hiddenLabel
                  hideClear
                  label="State"
                  placeholder="State"
                  { ...register('address.addressState') }
                  className={ styles['form-field-border'] }
                  error={ errors.address?.addressState?.message }
                />
              )
            }
        </div>
      </div>
      <div className={ styles['address-row-container'] }>
        <div className={ styles['address-row-field'] }>
          <Form.TextField
            hiddenLabel
            hideClear
            label="Zip"
            placeholder="ZIP"
            { ...register('address.addressZip') }
            className={ styles['form-field-border'] }
            error={ errors.address?.addressZip?.message }
          />
        </div>
      </div>
      <div className={ styles['address-row-container'] }>
        <div className={ styles['address-row-field'] }>
          <SelectForm.SelectField
            { ...register('address.addressCountry') }
            hideLabel
            className={ styles['select-field'] }
            defaultValue="US"
            options={ CountryOptions }
          />
        </div>
      </div>
      <div className={ styles['checkbox-address-container'] }>
        <div className={ styles['checkbox-address-container-row'] }>
          <input
            id="is_authorized"
            type="checkbox"
            { ...register('address.isAuthorized') }
          />
          <label htmlFor="is_authorized">
            Set as Authorized Address
          </label>
          <Tooltip.Hover
            content="Only an authorized signatory can authorize payment addresses to be used when making payments by Docyt Check."
          >
            <DocytSupportIcon className={ styles['help-icon'] } />
          </Tooltip.Hover>
        </div>
      </div>
    </Form>

  );
};

export { IAddressBookModalFormProps, AddressBookModalForm as default };
