import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

const canHoldRecurring = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return !['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.recurringBillId !== null
    && serviceDocument.scheduledBillState === 'scheduled'
    && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER');
};

const canRescheduleRecurring = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return !['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.recurringBillId !== null
    && serviceDocument.scheduledBillState === 'on_hold'
    && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER');
};

const canPayNow = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return !['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.recurringBillId !== null
    && (serviceDocument.scheduledBillState === 'scheduled' || serviceDocument.scheduledBillState === 'on_hold')
    && (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER');
};

const canDestroyRecurring = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return !['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.recurringBillId !== null
    && (serviceDocument.scheduledBillState === 'scheduled' || serviceDocument.scheduledBillState === 'on_hold')
    && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER');
};

const canApprove = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): boolean => {
  if ((userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
    && (
      serviceDocument.state === 'approval'
      || (!['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
      && serviceDocument.scheduledDetail !== null
      && serviceDocument.serviceDocumentApprovers === null)
    )
  ) {
    let currentUserApproved = false;
    if (serviceDocument.serviceDocumentApprovers) {
      for (let i = 0; i < serviceDocument.serviceDocumentApprovers.length; i += 1) {
        if (serviceDocument.serviceDocumentApprovers[i].id === currentUserId) {
          currentUserApproved = true;
        }
      }
      if (!currentUserApproved) {
        return true;
      }
    }
  }
  return false;
};

const canVerify = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole === 'VERIFIER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
    && ['unprocessable', 'processing', 'verifying'].includes(serviceDocument.state);
};

const canVerifyAndApprove = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
    && ['unprocessable', 'processing', 'verifying'].includes(serviceDocument.state);
};

const canResolveDuplicate = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole === 'VERIFIER' || userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
    && ['unprocessable', 'processing', 'verifying'].includes(serviceDocument.state);
};

const canUnApprove = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): boolean => {
  if ((userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
    && (serviceDocument.state === 'ready_for_payment'
      || (!['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
        && serviceDocument.scheduledDetail !== null
        && serviceDocument.serviceDocumentApprovers !== null)
    )
  ) {
    return true;
  }
  let currentUserApproved = false;
  if (serviceDocument.serviceDocumentApprovers) {
    for (let i = 0; i < serviceDocument.serviceDocumentApprovers.length; i += 1) {
      if (serviceDocument.serviceDocumentApprovers[i].id === currentUserId) {
        currentUserApproved = true;
      }
    }
    if (currentUserApproved) {
      if (serviceDocument.state === 'approval') {
        return true;
      }
    } else if ((userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
      && (['ready_for_payment', 'ready_for_credit', 'approval'].includes(serviceDocument.state))
    ) {
      return false;
    }
  }
  return false;
};

const canPayViaDocyt = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
    && (serviceDocument.state === 'approval'
      || serviceDocument.state === 'ready_for_payment'
    );
};

const canPayViaAch = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
    && (serviceDocument.state === 'approval'
      || serviceDocument.state === 'ready_for_payment');
};

const canViewCheck = (
  serviceDocument: IAccountsPayableServiceDocument,
): boolean => {
  return serviceDocument.state === 'paid'
    && serviceDocument.checkId !== null
    && (['docyt_check', 'bank_check', 'bank_account', 'advanced_settlement'].includes(serviceDocument.paymentMode));
};

const canMoveBack = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return serviceDocument.state === 'do_not_pay'
    && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER');
};

const canMarkPaid = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (serviceDocument.state === 'approval'
    || serviceDocument.state === 'ready_for_payment')
    && userRole !== 'UPLOADER';
};

const canSplit = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return (userRole !== 'UPLOADER'
    && (['unprocessable', 'processing', 'verifying'].includes(serviceDocument.state)))
    || (['approval', 'ready_for_payment', 'ready_for_credit'].includes(serviceDocument.state)
      && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER'));
};

const canEdit = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return userRole !== 'UPLOADER'
    && ['approval', 'ready_for_payment', 'ready_for_credit'].includes(serviceDocument.state);
};

const canViewVendor = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  if (!['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.recurringBillId !== null
    && (serviceDocument.scheduledBillState === 'scheduled'
      || serviceDocument.scheduledBillState === 'on_hold')
    && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')) {
    return true;
  }
  return userRole !== 'UPLOADER'
    && ['approval', 'ready_for_payment', 'ready_for_credit'].includes(serviceDocument.state);
};

const canDonPay = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return ((userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
    && ['unprocessable', 'processing', 'verifying', 'approval'].includes(serviceDocument.state))
    || ((userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')
      && serviceDocument.state === 'ready_for_payment');
};

const canCancelPay = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return userRole !== 'UPLOADER'
    && serviceDocument.state === 'paid'
    && (!['docyt_check', 'bank_check', 'bank_account', 'advanced_settlement'].includes(serviceDocument.paymentMode));
};

const canCancelScheduledPay = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  return !['do_not_pay', 'paid', 'credited'].includes(serviceDocument.state)
    && serviceDocument.scheduledDetail !== null
    && (((userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
      && serviceDocument.scheduledDetail.bookType === 'PAY_AFTER_APPROVAL')
      || (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER'));
};

const canVoidCheck = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  if (userRole !== 'UPLOADER'
    && ['docyt_check', 'bank_check', 'bank_account', 'advanced_settlement'].includes(serviceDocument.paymentMode)) {
    return !((serviceDocument.state === 'paid')
      && ((serviceDocument.paymentMode === 'docyt_check'
        && serviceDocument.cancellable)
        || ['bank_check', 'bank_account', 'advanced_settlement'].includes(serviceDocument.paymentMode)));
  }
  return false;
};

const canCancelCheck = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  if (userRole !== 'UPLOADER'
    && serviceDocument.state === 'paid'
    && ['docyt_check', 'bank_check', 'bank_account', 'advanced_settlement'].includes(serviceDocument.paymentMode)) {
    return serviceDocument.paymentMode !== 'docyt_check';
  }
  return false;
};

const canCancelDocytCheck = (
  serviceDocument: IAccountsPayableServiceDocument,
): boolean => {
  return serviceDocument.state === 'paid'
    && serviceDocument.paymentMode === 'docyt_check'
    && serviceDocument.cancellable;
};

const canForward = (
  serviceDocument: IAccountsPayableServiceDocument,
): boolean => {
  return !![
    'unprocessable',
    'processing',
    'verifying',
    'approval',
    'ready_for_payment',
    'ready_for_credit',
  ].includes(serviceDocument.state);
};

const canDelete = (
  serviceDocument: IAccountsPayableServiceDocument,
  userRole: string | undefined,
): boolean => {
  if (serviceDocument.settlementBusiness !== null && serviceDocument.state === 'ready_for_payment') {
    return false;
  }
  if (serviceDocument.state === 'do_not_pay') {
    return true;
  }
  return [
    'unprocessable',
    'processing',
    'verifying',
    'approval',
    'ready_for_payment',
    'ready_for_credit',
  ].includes(serviceDocument.state)
    || (serviceDocument.state === 'paid'
      && (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER'));
};

const isSameState = (
  serviceDocuments: IAccountsPayableServiceDocument[],
): boolean => {
  let state = '';
  for (let i = 0; i < serviceDocuments.length; i += 1) {
    if (state === '') {
      state = serviceDocuments[i].state;
    } else if (state !== serviceDocuments[i].state
      && !['ready_for_payment', 'ready_for_credit'].includes(serviceDocuments[i].state)) {
      return false;
    }
  }
  return true;
};

const isSameVendor = (
  serviceDocuments: IAccountsPayableServiceDocument[],
): boolean => {
  let vendorId: number | undefined = 0;
  for (let i = 0; i < serviceDocuments.length; i += 1) {
    if (serviceDocuments[i].vendor !== null) {
      if (vendorId === 0) {
        vendorId = serviceDocuments[i].vendor?.id;
      } else if (vendorId !== serviceDocuments[i].vendor?.id) {
        return false;
      }
    }
  }
  return true;
};

const canBulkEditCategory = (
  serviceDocuments: IAccountsPayableServiceDocument[],
): boolean => {
  return serviceDocuments[0].recurringBillId !== null
    || serviceDocuments[0].state === 'processing';
};

const canBulkVerifyInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments)) {
    for (let i = 0; i < serviceDocuments.length; i += 1) {
      if (!canVerify(serviceDocuments[i], userRole)) {
        if (!canVerifyAndApprove(serviceDocuments[i], userRole)) {
          return false;
        }
      }
    }
    return true;
  }
  return false;
};

const canBulkApproveInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  currentUserId: TID,
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments)) {
    for (let i = 0; i < serviceDocuments.length; i += 1) {
      if (!canApprove(serviceDocuments[i], currentUserId, userRole)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const canBulkUnApproveInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  currentUserId: TID,
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments)) {
    for (let i = 0; i < serviceDocuments.length; i += 1) {
      if (!canUnApprove(serviceDocuments[i], currentUserId, userRole)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const canBulkEditInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  for (let i = 0; i < serviceDocuments.length; i += 1) {
    if (!canEdit(serviceDocuments[i], userRole)) {
      return false;
    }
  }
  return true;
};

const canBulkDeleteInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  for (let i = 0; i < serviceDocuments.length; i += 1) {
    if (!canDelete(serviceDocuments[i], userRole)) {
      return false;
    }
  }
  return true;
};

const canBulkMarkPaidInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments) && isSameVendor(serviceDocuments)) {
    if (!['ready_for_payment', 'ready_for_credit'].includes(serviceDocuments[0].state)) {
      for (let i = 0; i < serviceDocuments.length; i += 1) {
        if (!canMarkPaid(serviceDocuments[i], userRole)) {
          return false;
        }
      }
    }
    return true;
  }
  return false;
};

const canBulkPayViaAchInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments) && isSameVendor(serviceDocuments) && serviceDocuments[0].state !== 'approval') {
    for (let i = 0; i < serviceDocuments.length; i += 1) {
      if (!canPayViaAch(serviceDocuments[i], userRole)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const canBulkPayViaDocytInvoices = (
  serviceDocuments: IAccountsPayableServiceDocument[],
  userRole: string | undefined,
): boolean => {
  if (isSameState(serviceDocuments) && isSameVendor(serviceDocuments) && serviceDocuments[0].state !== 'approval') {
    for (let i = 0; i < serviceDocuments.length; i += 1) {
      if (!canPayViaDocyt(serviceDocuments[i], userRole)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export {
  canHoldRecurring,
  canRescheduleRecurring,
  canPayNow,
  canDestroyRecurring,
  canApprove,
  canVerify,
  canVerifyAndApprove,
  canResolveDuplicate,
  canUnApprove,
  canPayViaDocyt,
  canPayViaAch,
  canViewCheck,
  canMoveBack,
  canMarkPaid,
  canSplit,
  canEdit,
  canViewVendor,
  canDonPay,
  canCancelPay,
  canCancelScheduledPay,
  canVoidCheck,
  canCancelCheck,
  canCancelDocytCheck,
  canForward,
  canDelete,
  canBulkEditCategory,
  canBulkVerifyInvoices,
  canBulkApproveInvoices,
  canBulkUnApproveInvoices,
  canBulkEditInvoices,
  canBulkDeleteInvoices,
  canBulkMarkPaidInvoices,
  canBulkPayViaAchInvoices,
  canBulkPayViaDocytInvoices,
};
