// @ts-nocheck
import React, { useMemo } from 'react';

import { useGetAnalytics } from '@src/hooks/queries/ai_analytics/ai_analytics';
import useBusinessFeatureFlag from '@src/hooks/use_business_feature_flag';
import { aiAnalyticspath } from '@src/routes';
import { TID } from '@src/types/common';

import { convertTime } from '@src/components/ai_analytics/utils';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '@src/components/header/styles.module.scss';

const AIAnalyticsAction: React.FC<{ businessId: TID }> = ({ businessId }) => {
  const featureFlag = window.Docyt.Common.Constants.AI_ANALYTICS_FLAG;
  const aaEnabled = useBusinessFeatureFlag(featureFlag, businessId);
  const analyticsQuery = useGetAnalytics({
    business_id: businessId,
    time_frame:  0,
  }, Boolean(aaEnabled));
  const analytics = useMemo(() => {
    return analyticsQuery.data || { aiAnalytic: { timeSavings: 0 } };
  }, [analyticsQuery.data]);

  const {
    aiAnalytic: {
      timeSavings,
    },
  } = analytics;

  if (aaEnabled) {
    return (
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href={ aiAnalyticspath(businessId) }
      >
        <div className={ styles['ai-analytics-item'] }>
          <span>AI Analytics</span>
          <span>
            {`${convertTime(timeSavings)} saved`}
          </span>
        </div>
      </ActionsDropdown.LinkAction>
    );
  }
  return null;
};

export default AIAnalyticsAction;
