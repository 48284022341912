module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="servicemenu-region"></div>\n<ul class="nav nav-sidebar">\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'queue' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/queue?type=unverified">\n      <span class="icon-grid-list-blue in-purple-account service-icon-font"><span class="path1 in-purple-200"></span><span class="path2 in-purple-200"></span></span>\n      <span class="nav-item-text">Receipt List</span>\n      <span class="nav-item-number">');
    
      __out.push(__sanitize(this.service.get('unverified_receipts_count')));
    
      __out.push('</span>\n    </a>\n  </li>\n\n  ');
    
      if (this.rp_settings) {
        __out.push('\n    <li class="');
        __out.push(__sanitize(this.tab === 'settings' ? 'active' : ''));
        __out.push('">\n      <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/settings">\n        <span class="icon-settings-green in-green-500 service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Settings</span>\n      </a>\n    </li>\n  ');
      }
    
      __out.push('\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}