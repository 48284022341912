import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';

interface IEditCategoryInvoicesActionProps {
  invoiceIds: TID[],
}

const EditCategoryInvoicesAction = ({
  invoiceIds,
}: IEditCategoryInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Edit Category'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <EditIcon fontSize={ 18 } /> }
      name="Edit Category"
      onClick={ handleSelect }
    />
  );
};

export default EditCategoryInvoicesAction;
