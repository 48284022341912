import { useMutation, useQueryClient, useQuery } from 'react-query';

import {
  IGetMetricsParams,
  IGetMetricsResponse,
  IGetMetricsTotalCountResponse,
  IAddMetricsParams,
  IUpdateMetricParams,
  IShowMetricParams,
  getMetrics,
  getMetricsTotalCount,
  addMetric,
  updateMetric,
  showMetric,
} from '@src/requests/metrics';
import { IMetric } from '@src/types/metrics';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const METRICS_QUERY = 'metrics';

const useGetMetrics = createUseGetInfiniteCollection<
  IMetric,
  IGetMetricsParams,
  IGetMetricsResponse
>({
  queryKey: METRICS_QUERY,
  request:  getMetrics,
});

// To check proper permission
interface IGetMetricsParamsWithPermissionFlag extends IGetMetricsParams {
  hasMetricsServiceRole?: boolean
}

const useGetMetricsTotalCount = (params: IGetMetricsParamsWithPermissionFlag) => {
  return useQuery<IGetMetricsTotalCountResponse, Error>(
    [METRICS_QUERY, params],
    () => {
      if (params.hasMetricsServiceRole) {
        return getMetricsTotalCount(params);
      }
      //For BusinessAdmin user if permission is none, then the component should not be hidden. So that returning -1
      return {
        totalCount: -1,
      };
    },
    {
      refetchOnMount: false,
    },
  );
};

const useShowMetric = (params: IShowMetricParams) => {
  return useQuery<IMetric, Error>(
    [METRICS_QUERY, params],
    () => showMetric(params),
  );
};

const useAddMetric = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IAddMetricsParams>(
    addMetric,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(METRICS_QUERY);
      },
    },
  );
};

const useUpdateMetric = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateMetricParams>(
    updateMetric,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(METRICS_QUERY);
      },
    },
  );
};

export {
  METRICS_QUERY,
  useGetMetrics,
  useGetMetricsTotalCount,
  useShowMetric,
  useAddMetric,
  useUpdateMetric,
};
