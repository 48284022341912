import React, { useCallback, useState } from 'react';

import { AlertIcon } from '@src/components/utils/icomoon';
import CheckCircleIcon from '@src/components/utils/icomoon/check_circle_large_icon';

interface IInvoiceQueueBannerProps {
  showReloadNotification?: boolean,
  showBulkActionResult?: boolean,
}

const InvoiceQueueBanner = ({
  showReloadNotification,
  showBulkActionResult,
}: IInvoiceQueueBannerProps): JSX.Element | null => {
  const [showReload, setShowReload] = useState<boolean|undefined>(showReloadNotification);
  const [showResult, setShowResult] = useState<boolean|undefined>(showBulkActionResult);

  const handleReloadInvoices = useCallback(() => {
    setShowReload(false);
  }, []);
  const handleBulkActionResult = useCallback(() => {
    setShowResult(false);
  }, []);

  return (
    <>
      {
        showReload && (
          <div className="pointer new-data-reload-container">
            <a
              className="pointer new-data-link"
              role="button"
              tabIndex={ 0 }
              onClick={ handleReloadInvoices }
            >
              New data available. Click here to reload.
            </a>
          </div>
        )
      }
      {
        showResult && (
          <ul
            className="pointer bulk-action-result-container m-t-5 m-b-5"
            onClick={ handleBulkActionResult }
          >
            <div className="font-16 result-item-wrapper success-result-item">
              <CheckCircleIcon />
              <span className="success-result m-l-4">
                2 Invoices were verified
              </span>
            </div>
            <hr className="m-t-10 m-b-10 sparation-line" />
            <div className="font-16 result-item-wrapper fail-result-item">
              <AlertIcon className="in-red-400" />
              <span
                className="fail-result m-l-4 in-red-400"
              >
                1 invoice could not be verified because of missing details.
              </span>
            </div>
          </ul>
        )
      }
    </>
  );
};

export default InvoiceQueueBanner;
