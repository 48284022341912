import React, { useState } from 'react';

import { TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';
import { BankingAccountBlankIcon } from '@src/components/utils/img_icons';

import NewAccount from './new_account/new_account';

import styles from './styles.module.scss';

interface IBlankAccountsPageProps {
  businessId?: TID,
}

const BlankAccountsPage = ({
  businessId,
}: IBlankAccountsPageProps) => {
  const [isAddAccount, setIsAddAccount] = useState<boolean>(false);
  return (
    <div className={ styles['blank-account-page'] }>
      <span className="blank-account-header">Accounts</span>
      <div className="blank-account-body">
        <BankingAccountBlankIcon fontSize={ 24 } mb={ 20 } />
        <span>You do not have any banking accounts assigned to this business yet.</span>
        <span>
          Get started by clicking on the button below to add accounts from
          { ' ' }
          your connected financial institutions.
        </span>
        <span>
          Or visit
          { ' ' }
          <a
            className="font-bold pointer"
            href={ `/financial_institution_connection_center/${businessId}` }
            role="button"
            tabIndex={ 0 }
          >
            Financial Connections
          </a>
          { ' ' }
          to add new financial institutions.
        </span>
        {
          isAddAccount ? (
            <NewAccount
              isBlankPage
              onCancel={ () => setIsAddAccount(false) }
              onDone={ () => setIsAddAccount(false) }
            />
          ) : (
            <Button
              bsColor="blue"
              onClick={ () => setIsAddAccount(true) }
            >
              <PlusIcon mr={ 10 } />
              Add Account
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default BlankAccountsPage;
