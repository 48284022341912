import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdatePaymentAccount } from '@src/hooks/queries/payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { formatDate } from '@src/utils/date_helpers';

import SideView from '@src/components/ui/side_view';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAccountSettingsInput } from './schema';

interface IEmployeeSettingsProps {
  isOpen?: boolean,
  paymentAccount: IPaymentAccount,
  onDone: () => void,
  onCancel: () => void,
}

const EmployeeSettings = ({
  isOpen = true,
  paymentAccount,
  onCancel,
  onDone,
}: IEmployeeSettingsProps): JSX.Element => {
  const business = useBusinessContext();

  const update = useUpdatePaymentAccount();
  const { mutate } = update;

  const handleSubmit = useCallback((data: IAccountSettingsInput) => {
    if (!paymentAccount) return;

    mutate({
      id:             paymentAccount?.id,
      businessId:     business.id,
      paymentAccount: {
        name:            data.name,
        qboId:           data.qbo.id,
        qboName:         data.qbo.name,
        startImportDate: formatDate(data.startImportDate, 'YYYY/MM/DD'),
        startBalance:    data.startBalance,
        last4:           data.last4,
        accountOwner:    data.accountOwner,
      },
    }, {
      onSuccess: onDone,
    });
  }, [paymentAccount, mutate, business.id, onDone]);

  return (
    <SideView.Form
      isRoot
      isOpen={ isOpen }
      title="Account Settings"
      onCancel={ onCancel }
    >
      { ({ formId }) => (
        <div className="account-settings-view">
          <MutationStatus mutation={ update } successMessage="Updated successfully." />
          <SideView.Subtitle>{ paymentAccount?.name }</SideView.Subtitle>
          <p>{ business.name }</p>

          <hr />

          { (paymentAccount) && (
            <Form
              // This `key` property is required here to rebuild whole subtree on account change
              // (including rebuilding form with new values and removing child side view
              key={ paymentAccount?.id }
              businessId={ business.id }
              formId={ formId }
              paymentAccount={ paymentAccount }
              onSubmit={ handleSubmit }
            />
          )}
        </div>
      )}
    </SideView.Form>
  );
};

export default EmployeeSettings;
