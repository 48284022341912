import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSetLinkBusinessLaborReportsDepartment } from '@src/hooks/queries/report_service/report_datas';
import { IReportItem } from '@src/types/report_service/report_item';

import { Button } from '@src/components/ui_v2/buttons';
import { SelectInput, TOption } from '@src/components/ui_v2/inputs';
import MutationStatus from '@src/components/utils/mutation_status';

import { IEditMappingLaborProps } from '../hooks';

import styles from '../styles.module.scss';

interface IBusinessReportsEditMappingMapDimensionProps extends IEditMappingLaborProps {
  department: { dimensionCode: string, dimensionLabel: string }[],
}

const BusinessReportsEditMappingMapDimension = ({
  data,
  parentId,
  childId,
  department
}: IBusinessReportsEditMappingMapDimensionProps) => {
  const [dimenstion, setDimension] = useState<string>('');

  const linkDimention = useSetLinkBusinessLaborReportsDepartment();
  const { mutate } = linkDimention;
  const item = useMemo(() => data.items.find((itm: IReportItem) => 
    itm.id === parentId || itm.id === childId),
  [data.items, parentId, childId]);

  useEffect(() => {
    setDimension('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDimension, department, data.departments]);

  const handleChange = useCallback((option: TOption | null) => {
    setDimension(option?.label as string);
  }, [setDimension]);

  const handleClick = useCallback(() => {
    mutate({
      reportId: data.report.id as string,
      dimensionCode: item?.metricDimensionCode as string,
      dimensionLabel: dimenstion,
      itemId: childId ? childId as string : parentId as string
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:link:created');
        setDimension('');
      },
    });
  }, [mutate, data.report, item, parentId, childId, dimenstion]);

  return (
    <>
      <p className={ styles.title }>Map Dimension Label</p>
      <div className={ styles['action-item'] }>
        <p className={ styles.label }>Dimension Label</p>
        <SelectInput
          hideClear
          isDisabled={ department.length !== 0 }
          options={ data.departments as TOption[] }
          placeholder="Select Dimension"
          styles={ {
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              backgroundColor: isSelected || isFocused ? '#f9fafb' : 'white',
              color: 'black'
            }),
            valueContainer: provided => ({
              ...provided,
              width: 150,
            }),
          } }
          value={ data.departments?.find((f: TOption) => f.label === dimenstion) }
          onChange={ handleChange }
        />
        <Button
          disabled={ dimenstion === '' || department.length !== 0 }
          variant="outline"
          onClick={ handleClick }
        >
          Apply
        </Button>
      </div>
      <p className={ styles.info }>
        Dimension label in Labour Report refers to the list of department names that is sourced from Paychex
      </p>
      <MutationStatus mutation={ linkDimention } />
    </>
  );
};

export default React.memo(BusinessReportsEditMappingMapDimension);
