import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Content from '@src/components/reconciliation_center/journal_entry/content';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

const JournalEntry: React.FC<{ businessId: TID }> = ({ businessId }) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <DetailsRegion>
          <Content />
        </DetailsRegion>
      </BusinessProvider>
    </Root>
  );
};

export default JournalEntry;
