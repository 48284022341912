import React, { useCallback, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { businessDashboardWidgetPath } from '@src/routes';
import { TID, TMongoID, TDateRange } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import BriefNoDataView from '@src/components/business_dashboards/widgets/brief_view/brief_no_data_view';

import { getDefaultDateRange, getRealDateRange } from '../charts/chart_helpers';
import ChartView from '../charts/chart_view';
import Header from './brief_view_header';

import styles from './styles.module.scss';

interface IBriefChartViewProps extends IUIStyleProps {
  businessId: TID,
  dashboardId: TMongoID,
  widget: IWidget,
}

const BriefChartView = ({
  businessId,
  dashboardId,
  widget,
  ...props
}: IBriefChartViewProps): JSX.Element | null => {
  const business = useBusinessContext();
  const realRange: TDateRange = useMemo(() => {
    const defaultRange = getDefaultDateRange(widget?.scale);
    const dateRange: TDateRange = {
      startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
      endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
    };
    return getRealDateRange(
      dateRange,
      business.bookkeepingStartDate,
      widget?.scale,
      widget?.startDate,
    );
  }, [business.bookkeepingStartDate, widget?.scale, widget?.startDate]);

  const [classes] = uiStyleProps(
    styles['brief-view-wrapper'],
    props,
  );

  const handleClick = useCallback(() => {
    Backbone.history.navigate(
      businessDashboardWidgetPath(businessId, dashboardId, widget.id),
      { trigger: true },
    );
  }, [businessId, dashboardId, widget.id]);

  return (
    <div
      className={ classes }
      role="navigation"
      onClick={ handleClick }
    >
      <div className={ styles.content }>
        <Header
          widget={ widget }
        />
        <div className={ styles.body }>
          {
          widget.dataSets
          && widget.dataSets.find((data) => data.values && data.values.length > 0) ? (
            <ChartView
              briefView
              dataSets={ widget.dataSets }
              dateRange={ realRange }
              scale={ widget?.scale }
              viewOption={ widget.briefViewOption }
            />
            ) : (
              <BriefNoDataView />
            )
        }
        </div>
      </div>
    </div>
  );
};

export default BriefChartView;
