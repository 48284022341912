import React from 'react';

import { BusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessQuery } from '@src/hooks/queries/businesses';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IBusinessProviderProps {
  businessId: TID,
  children: React.ReactNode,
  useLocalSpinner?: boolean,
}

const BusinessProvider = ({
  businessId,
  children,
  useLocalSpinner,
}: IBusinessProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetBusinessQuery(businessId);

  if (isLoading) {
    return <Spinner localSpinnerId={ useLocalSpinner && 'detail-region' } />;
  }

  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Business loaded" />;

  return (
    <BusinessContext.Provider value={ data }>
      { children }
    </BusinessContext.Provider>
  );
};

export default BusinessProvider;
