import React, { useState } from 'react';

import { feedbackPath } from '@src/routes';
import { TID } from '@src/types/common';
import {
  LDFeatureForUserEnabled,
  LDFeatureValueForUser,
} from '@src/utils/config';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import ContactAccountManagerForm from './contact_account_manager';

import styles from '../styles.module.scss';

declare let pendo: any;

type TActionsProps = {
  businessId?: TID,
  isClientView: boolean,
}
const Actions = ({
  businessId,
  isClientView,
}: TActionsProps) => {
  const [showSuggestFeature, setShowSuggestFeature] = useState<string | boolean | undefined>(
    LDFeatureValueForUser(
      window.Docyt.Common.Constants.PENDO_SUGGESTION_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ),
  );
  const handleSuggestFeatures = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    pendo.feedback.loginAndRedirect({ anchor: event.target });
  };

  if (showSuggestFeature === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.PENDO_SUGGESTION_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setShowSuggestFeature(res);
    });
  }

  return (
    <>
      <span className={ styles['settings-section'] }>Help Center</span>
      <ActionsDropdown.LinkAction
        backboneNavigate={ false }
        className={ styles['settings-item'] }
        href="https://university.docyt.com/"
        title="Docyt University"
      />
      <ActionsDropdown.Divider />
      <ActionsDropdown.LinkAction
        backboneNavigate={ false }
        className={ styles['settings-item'] }
        href="https://pages.docyt.com/knowledge"
        title="Knowledge Base"
      />
      {
        showSuggestFeature && (
          <ActionsDropdown.Divider />
        )
      }
      {
        showSuggestFeature && (
          <a
            className={ styles['settings-pendo-item'] }
            href="#"
            rel="noreferrer"
            target="_blank"
            onClick={ handleSuggestFeatures }
          >
            Suggest Features
          </a>
        )
      }
      <ActionsDropdown.Divider />
      {
        !showSuggestFeature && (
          <>
            <ActionsDropdown.LinkAction
              className={ styles['settings-item'] }
              href={ feedbackPath() }
              title="Feedback"
            />
            <ActionsDropdown.Divider />
          </>
        )
      }
      {
        isClientView ? (
          <ActionsDropdown.LinkAction
            backboneNavigate={ false }
            className={ styles['settings-item'] }
            href="mailto:support@docyt.com"
            title="Contact Us"
          />
        ) : (
          <ContactAccountManagerForm
            businessId={ businessId }
          />
        )
      }
    </>
  );
};

export default Actions;
