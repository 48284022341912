import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CheckCardIcon } from '@src/components/utils/icomoon';

interface IDocytCheckInvoicesActionProps {
  invoiceIds: TID[],
}

const DocytCheckInvoicesAction = ({
  invoiceIds,
}: IDocytCheckInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Pay by Docyt Check'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <CheckCardIcon fontSize={ 18 } /> }
      name="Pay by Docyt Check"
      onClick={ handleSelect }
    />
  );
};

export default DocytCheckInvoicesAction;
