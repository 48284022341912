import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

interface IServiceSidebarItemProps {
  showDivider?: boolean,
  icon?: React.ReactNode,
  href: string,
  badge?: number,
  badgeIcon?: React.ReactNode,
  disabled?: boolean,
  title: string,
  launchDarklyFeature?: TLaunchDarklyFeature,
  isHidden?: boolean,
  children?: React.ReactNode,
  active?: boolean,
}

const ServiceSidebarItem = ({
  showDivider,
  icon,
  href,
  badge,
  badgeIcon,
  disabled,
  title,
  launchDarklyFeature,
  isHidden,
  children,
  active,
}: IServiceSidebarItemProps): JSX.Element | null => {
  const location = useLocation();

  const itemClasses = classNames(
    styles['dropdown-btn'],
    {
      'item-divider': showDivider,
      'active':       active ?? (location.pathname === href || `${location.pathname}${location.search}` === href),
      'disabled':     disabled,
    },
  );

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      return;
    }

    Backbone.history.navigate(href, { trigger: true });
  }, [href, disabled]);

  if (isHidden) return null;

  return (
    <li className={ itemClasses } data-global-feature-toggle={ launchDarklyFeature }>
      <a className="submenu-title" href={ href } onClick={ handleClick }>
        {
          icon && (
            <span className={ styles.icon }>
              { icon }
            </span>
          )
        }

        <Tooltip.Hover className={ styles['submenu-item-container'] } content={ title }>
          <span className="nav-item-text">{title}</span>
        </Tooltip.Hover>

        {
          badge && <span className="nav-item-number">{ badge }</span>
        }
        {
          badgeIcon && (
            <span className={ styles.icon }>
              { badgeIcon }
            </span>
          )
        }
      </a>
      { children && (
        <ul className={ styles['dropdown-menu'] } role="menu">
          { children }
        </ul>
      ) }
    </li>
  );
};

export default ServiceSidebarItem;
