import React, { useCallback } from 'react';

import { TDateRange } from '@src/types/common';
import { TDateFilter, TFilterData } from '@src/types/filter';
import { formatDate } from '@src/utils/date_helpers';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { DateRangeInput } from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface IDateFilterFieldProps<TFilter extends TFilterData> extends IFilterFieldUIProps {
  name: keyof TFilter;
  startPlaceholder?: string;
  endPlaceholder?: string;
  hidePeriods?: boolean;
  minDate?: string;
  maxDate?: string;
  filterDate?(startDate: Date, endDate: Date): boolean;
  hideClear?: boolean;
  hideAllTime?: boolean;
  periodType?: 'daily' | 'weekly' | 'monthly';
}

const formatValueTooltip = (value?: TDateRange): string | null => {
  if (!value) return null;
  if (!value.startDate && !value.endDate) return null;

  const ranges = [];
  if (value.startDate) {
    ranges.push(`Start Date: ${formatDate(value.startDate)}`);
  }
  if (value.endDate) {
    ranges.push(`End Date: ${formatDate(value.endDate)}`);
  }
  return ranges.join(' – ');
};

const DateFilterField = <TFilter extends TFilterData>({
  name,
  hideAllTime,
  hidePeriods,
  filterDate,
  periodType,
  ...props
}: IDateFilterFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, TDateFilter>(name);

  const handleChange = useCallback(
    (dateRange: TDateRange) => {
      if (!dateRange.startDate && !dateRange.endDate) {
        update(undefined);
        return;
      }

      update({
        gte: dateRange.startDate,
        lte: dateRange.endDate,
      });
    },
    [update]
  );

  const [wrapperProps] = useFilterFieldProps(props);

  const valueRange = {
    startDate: value?.gte,
    endDate: value?.lte,
  };

  return (
    <Wrapper { ...wrapperProps } tooltip={ formatValueTooltip(valueRange) }>
      <DateRangeInput
        filterDate={ filterDate }
        hideAllTime={ hideAllTime }
        hideClear={ props.hideClear }
        hidePeriods={ hidePeriods }
        maxDate={ props.maxDate }
        minDate={ props.minDate }
        periodType={ periodType }
        value={ valueRange }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(DateFilterField);
