import React, { useCallback } from 'react';

import { useUpdateStarredFlag } from '@src/hooks/queries/documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import { StarIcon } from '@src/components/utils/fa_icons';

interface IInvoiceStarFlagProps {
  document: IAccountsPayableServiceDocument,
  currentUserId: TID,
}

const InvoiceStarFlag = ({
  document,
  currentUserId,
}: IInvoiceStarFlagProps) => {
  const update = useUpdateStarredFlag();
  const { mutate } = update;

  const handleStarFlagClick = useCallback(() => {
    mutate({
      id:          document.documentId,
      starredFlag: !document.starredById,
    }, {
      onSuccess: () => {
        document.starredById = currentUserId;
      },
    });
  }, [currentUserId, document, mutate]);

  return (
    <StarIcon
      fontSize={ 18 }
      type={ document.starredById ? 'yellow' : 'outline' }
      onClick={ handleStarFlagClick }
    />
  );
};

export default React.memo(InvoiceStarFlag);
