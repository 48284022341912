import React, { useMemo } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  bankStatementsPath,
  allTransactionsPath,
} from '@src/routes';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { ExportCSVBanner } from '@src/components/common/actions/export_csv';
import EndingBalanceChangedBanner
  from '@src/components/reconciliation_center/month_end/ending_balance_changed_banner/ending_balance_changed_banner';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Table from '@src/components/ui_v2/table';
import TableSection from '@src/components/ui_v2/table_section';

import Drawers from './drawers';
import MonthEndHeader from './header';
import MonthEndRow from './row';
import StatementEndingBalance from './statement_ending_balance/statement_ending_balance';
import Step from './step';
import { buildItemTypesFromArray, usePaymentAccountName, getReconciliationDateRange, isVerified, nonZeroAmount } from './utils';

import styles from './styles.module.scss';

const MonthEndTable = ({ reconciliationPaymentAccounts }: { reconciliationPaymentAccounts: IReconciliationPaymentAccount[] }) => {
  const business = useBusinessContext();
  const baReconciliation = useBankAccountReconciliationContext();

  const items = useMemo(
    () => buildItemTypesFromArray(baReconciliation.bankAccountReconciliationItems),
    [baReconciliation.bankAccountReconciliationItems],
  );
  const [dismiss, showDismiss] = React.useState(true);

  const bankStatement = useBankStatementContext();

  const tableHeader = (
    <Table.Head>
      <Table.Row>
        <Table.HCell width="30%" />
        <Table.HCell>Open Items</Table.HCell>
        <Table.HCell width="5%" />
        <Table.HCell width="12%">Acknowledged On</Table.HCell>
        <Table.HCell>Acknowledged By</Table.HCell>
        <Table.HCell width="5%" />
        <Table.HCell width="15%" />
      </Table.Row>
    </Table.Head>
  );

  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);
  const bankStatementPathStr = bankStatementsPath(
    business.id,
    bankStatement?.docytId,
    bankStatement?.state,
    bankStatementsDocRequestEnabled,
  );

  const [startDate, endDate] = getReconciliationDateRange(baReconciliation, bankStatement);
  const paymentAccountName = usePaymentAccountName();
  const documentType = items.undocumentedTransactions.itemType === 'undocumented_transactions' ? 'undocumented' : '';
  const undocumentedTransactionsPath = allTransactionsPath(
    business.id,
    {
      transaction_date_start: startDate,
      transaction_date_end:   endDate,
      has_related_document:   documentType,
      ...paymentAccountName,
    },
  );

  const pdtNoteRequired = items.pushedByDocytTransactions.amount !== baReconciliation.endingBalance;

  const handleDismissClick = () => {
    showDismiss(false);
  };

  const tipsMake = () => {
    let domWrap = null;
    if (baReconciliation.calculateLoading) {
      domWrap = (
        <div className={ styles.tips }>
          <span className={ styles['tips-text'] }>
            <i className="icon font-20 icon-clock" />
            Please wait while the data is being updated...
          </span>
        </div>
      );
    } else {
      domWrap = (
        <div className={ `${styles.tips} ${styles['tips-success']}` }>
          <span className={ styles['tips-text'] }>
            <i className="icon icon-success font-20">
              <span className="path1" />
              <span className="path2" />
            </i>
            The account is up to date for the current month.
          </span>
          <span
            className={ styles['tips-dismiss'] }
            role="button"
            tabIndex={ 0 }
            onClick={ handleDismissClick }
          >
            Dismiss
          </span>
        </div>
      );
    }
    // domWrap= <div className={ `${styles.tips} ${styles.failed}` }>
    //        Please wait while the data is being updated...
    //      </div>
    return domWrap;
  };

  return (
    <DetailsRegion>
      <MonthEndHeader endDate={ endDate } startDate={ startDate } />
      <ExportCSVBanner atomKey="monthEndTransactions" />
      <EndingBalanceChangedBanner />

      <TableSection>
        {dismiss && tipsMake()}
        <TableSection.Caption className={ styles['table-section-caption'] }>Reconcile</TableSection.Caption>
        <TableSection.Panel className={ styles['table-section-panel'] }>
          <Table className={ styles['main-table'] }>
            { tableHeader }
            <Table.Body>
              <Step tooltip="Docyt collects real time bank feed for your bank accounts using third party banking APIs. This reconciliation step ensures that there are no missed or duplicate transactions.">
                { isVerified(bankStatement) ? <span className={ styles['arrow-down'] } /> : null }
                Step 1: Bank Feed Reconciliation
              </Step>

              <MonthEndRow
                hasSecondLevelRows
                item={ items.bankFeedVerification }
                linkTo={ bankStatementPathStr }
                noteRequired={ !isVerified(bankStatement) }
              />

              <MonthEndRow
                item={ items.pushedByDocytTransactions }
                linkTo={ <Drawers.PushedByDocytTransactions items={ items } /> }
                noteRequired={ pdtNoteRequired }
              />

              <MonthEndRow
                item={ items.uncategorizedTransactions }
                // eslint-disable-next-line max-len
                linkTo={ <Drawers.UncategorizedTransactions reconciliationItem={ items.uncategorizedTransactions } /> }
                noteRequired={ nonZeroAmount(items.uncategorizedTransactions.amount) }
              />

              <MonthEndRow
                item={ items.flaggedTransactions }
                // eslint-disable-next-line max-len
                linkTo={ <Drawers.FlaggedTransactions reconciliationItem={ items.flaggedTransactions } /> }
                noteRequired={ nonZeroAmount(items.flaggedTransactions.amount) }
              />

              <MonthEndRow
                item={ items.unmatchedTransfers }
                // eslint-disable-next-line max-len
                linkTo={ <Drawers.UnmatchedTransfers endDate={ endDate } reconciliationItem={ items.unmatchedTransfers } reconciliationPaymentAccounts={ reconciliationPaymentAccounts } startDate={ startDate } /> }
                noteRequired={ nonZeroAmount(items.unmatchedTransfers.amount) }
              />

              <Step tooltip="This reconciliation step ensures that all of your journal entries in general ledger are accounted for in Docyt. It requires the bank balance in QuickBooks to match with adjusted ledger balance in Docyt.">
                Step 2: Ledger Balance Reconciliation
              </Step>

              <StatementEndingBalance
                items={ items }
              />
            </Table.Body>
          </Table>
        </TableSection.Panel>
      </TableSection>

      <TableSection>
        <TableSection.Caption className={ styles['table-section-caption'] }>Acknowledge documentation status</TableSection.Caption>
        <TableSection.Panel className={ styles['table-section-panel'] }>
          <Table className={ styles['main-table'] }>
            { tableHeader }
            <Table.Body>
              <MonthEndRow
                item={ items.undocumentedTransactions }
                linkTo={ undocumentedTransactionsPath }
              />
            </Table.Body>
          </Table>
        </TableSection.Panel>
      </TableSection>
    </DetailsRegion>
  );
};

export default React.memo(MonthEndTable);
