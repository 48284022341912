import React, { useCallback, useState } from 'react';

import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { useDebouncedCallback } from '@src/hooks/utils';
import { getRemainingApproverNames } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import Tooltip from '@src/components/ui_v2/tooltip';

import { getInvoiceStatus, getInvoiceStatusClass } from '../../utils';

interface IInvoiceStatusProps {
  document: IAccountsPayableServiceDocument,
  currentUserId: TID,
}

const InvoiceStatus = ({
  document,
  currentUserId,
}: IInvoiceStatusProps) => {
  const service = useAccountsPayableServiceContext();
  const [approverNames, setApproverNames] = useState<string | undefined>(undefined);

  const handleMouseOver = useDebouncedCallback(() => {
    if (document.state === window.configData.ap_invoice_states.DOCUMENT_STATE_APPROVAL) {
      getRemainingApproverNames(document.id).then((res) => {
        setApproverNames(`Needs Approval: ${res.approverNames}`);
      });
    }
  }, [], 300);

  const handleEmpty = useCallback(() => {}, []);

  const status = getInvoiceStatus(document, currentUserId, service.businessServiceRole);
  if (!status) return null;

  return (
    <Tooltip.Hover
      content={ approverNames || document.paymentTransactionError }
    >
      <div onFocus={ () => handleEmpty } onMouseOver={ handleMouseOver }>
        <span className={ `invoice-status-panel font-bold text-nowrap ${getInvoiceStatusClass(document, status)}` }>
          { status }
        </span>
      </div>
    </Tooltip.Hover>
  );
};

export default React.memo(InvoiceStatus);
