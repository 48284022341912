import React from 'react';

import {
  AccountsPayableIcon,
  AllTransactionsIcon,
  MonthEndDocumentsIcon,
  NewBankStatementIcon,
  NewExpenseReportIcon,
  ReceiptBoxIcon,
  RevenueChartIcon,
} from '@src/components/utils/icomoon';

const SearchResultIcon = ({
  iconName,
}: {iconName: string}) => {
  switch (iconName) {
    case 'invoice':
      return <AccountsPayableIcon fontSize={ 28 } />;
    case 'transaction':
      return <AllTransactionsIcon fontSize={ 28 } />;
    case 'balance_sheet_statement':
      return <MonthEndDocumentsIcon fontSize={ 28 } />;
    case 'bank_statement':
      return <NewBankStatementIcon fontSize={ 28 } />;
    case 'expense_report':
      return <NewExpenseReportIcon fontSize={ 28 } />;
    case 'receipt':
      return <ReceiptBoxIcon fontSize={ 28 } />;
    case 'revenue_report':
      return <RevenueChartIcon fontSize={ 28 } />;
    default:
      return null;
  }
};

export default React.memo(SearchResultIcon);
