import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { IBusiness } from '@src/types/businesses';
import { amountToPercent } from '@src/utils/currency';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';

export const checkCategoryReadonly = (
  business: IBusiness,
  serviceDocument: IAccountsPayableServiceDocument,
  serviceDocumentSplits: IServiceDocumentSplit[],
) => {
  let isCategoryReadonly = serviceDocument.recurringBillId !== null;
  const isReadOnly = serviceDocument.isFrozen || isCategoryReadonly;

  let isDocumentVerified = isReadOnly;
  if (!isDocumentVerified && serviceDocument.serviceDocumentSplitsCount) {
    isDocumentVerified =
      [
        window.configData.ap_invoice_states.DOCUMENT_STATE_CREDITED,
        window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_CREDIT,
        window.configData.ap_invoice_states.DOCUMENT_STATE_PAID,
        window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_PAYMENT,
      ].includes(serviceDocument.state);
  }

  if (isDocumentVerified
    && serviceDocumentSplits
      .filter((split) => (split.receivableBusinessId !== business.id)).length > 0) {
    isCategoryReadonly = true;
  }

  return isCategoryReadonly || isReadOnly;
};

const getSplitChartOfAccountName = (
  splitAccount: IServiceDocumentSplit,
) => {
  let name = '';
  if (splitAccount.chartOfAccountParentName) {
    name = `${splitAccount.chartOfAccountParentName} ▸ `;
  }
  name += splitAccount.chartOfAccountName;

  if (splitAccount.chartOfAccountNumber) {
    name = `${splitAccount.chartOfAccountNumber}: ${name}`;
  }
  return name;
};

const makeSingleAccountsSplits = (
  business: IBusiness,
  serviceDocument: IAccountsPayableServiceDocument,
): ICategorySplit => {
  return {
    businessId:          business.id,
    businessName:        business.name,
    chartOfAccountId:    serviceDocument.chartOfAccount?.id,
    chartOfAccountName:  serviceDocument.chartOfAccount?.displayName,
    accountingClassId:   serviceDocument.accountingClass?.id,
    accountingClassName: serviceDocument.accountingClass?.name,
    memo:                serviceDocument.memo,
    amount:              serviceDocument.invoiceAmount,
    percentage:          amountToPercent(
      serviceDocument.invoiceAmount,
      serviceDocument.invoiceAmount,
    ),
  };
};

export const getCategorySplits = (
  business: IBusiness,
  serviceDocument: IAccountsPayableServiceDocument,
  serviceDocumentSplits: IServiceDocumentSplit[],
) => {
  const categorySplits: ICategorySplit[] = [];

  if (serviceDocumentSplits.length > 0) {
    serviceDocumentSplits.forEach((splitAccount) => {
      const categorySplit: ICategorySplit = {};
      if (splitAccount.receivableBusinessId && splitAccount.receivableBusinessId !== business.id) {
        categorySplit.businessId = splitAccount.receivableBusinessId;
        categorySplit.businessName = splitAccount.receivableBusinessName;
        categorySplit.chartOfAccountId =
        splitAccount.receivableBusinessAccountId
        || splitAccount.chartOfAccountId;
      } else {
        categorySplit.businessId = business.id;
        categorySplit.businessName = business.name;
        categorySplit.chartOfAccountId = splitAccount.chartOfAccountId;
      }
      categorySplit.chartOfAccountName = getSplitChartOfAccountName(splitAccount);

      categorySplit.accountingClassId = splitAccount.accountingClass?.id;
      categorySplit.accountingClassName = splitAccount.accountingClass?.name;

      categorySplit.memo = splitAccount.memo;
      categorySplit.amount = splitAccount.amount;
      categorySplit.percentage =
      amountToPercent(splitAccount.amount, serviceDocument.invoiceAmount);

      categorySplits.push(categorySplit);
    });
  } else {
    categorySplits.push(
      makeSingleAccountsSplits(business, serviceDocument),
    );
  }

  return categorySplits;
};

export const copyCategorySplits = (
  splits: ICategorySplit[],
) => {
  const categorySplits: ICategorySplit[] = [];

  splits.forEach((split) => (categorySplits.push(split)));
  return categorySplits;
};
