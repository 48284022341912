import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface IDeleteInvoicesActionProps {
  invoiceIds: TID[],
}

const DeleteInvoicesAction = ({
  invoiceIds,
}: IDeleteInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Delete Invoices'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.DangerAction
      icon={ <TrashcanIcon fontSize={ 18 } /> }
      name="Delete Invoices"
      onClick={ handleSelect }
    />
  );
};

export default DeleteInvoicesAction;
