import React from 'react';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { ITSDCollection } from '../hooks';
import Table from './table';

interface IAllTransactionsListProps {
  collection: ITSDCollection,
}

const AllTransactionsList = ({
  collection,
}: IAllTransactionsListProps) => {
  return (
    <Section.Provider section={ collection.section }>
      <TableSection>
        <Table collection={ collection } />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(AllTransactionsList);
