import React from 'react';

import pluralize from 'pluralize';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { Button } from '@src/components/ui_v2/buttons';

interface IChartOfAccountSplitsViewProps {
  document: IAccountsPayableServiceDocument,
  onEdit: () => void,
}

const ChartOfAccountSplitsView = ({
  document,
  onEdit,
}: IChartOfAccountSplitsViewProps) => {
  const isCategoryReadonly = document.recurringBillId !== null;

  const serviceDocumentSplits = document.serviceDocumentSplits || [];
  const serviceDocumentSplitsCount =
    document.serviceDocumentSplitsCount || serviceDocumentSplits.length;

  const serviceLineItemsCount = document.serviceDocumentLineItems?.length || 0;
  const filedValue = document.chartOfAccount?.displayName;

  let predictedChartOfAccountLineItem;
  if (serviceDocumentSplitsCount === 1 && document.serviceDocumentLineItems) {
    predictedChartOfAccountLineItem =
      document.serviceDocumentLineItems[0]?.predictedChartOfAccountName;
  }

  const selectCategory = isCategoryReadonly ? 'View Category' : 'Select Category';
  const value = predictedChartOfAccountLineItem || filedValue;
  const count = serviceDocumentSplitsCount === 0
    ? serviceLineItemsCount
    : serviceDocumentSplitsCount;

  if (count === 0) {
    if (!value) {
      return <Button variant="link" onClick={ onEdit }>{ selectCategory }</Button>;
    }
    return (
      <>
        { value }
        <br />
        <Button variant="link" onClick={ onEdit }>Edit</Button>
      </>
    );
  }

  return (
    <Button variant="link" onClick={ onEdit }>
      { `${count} ${pluralize('Split', count)}` }
    </Button>
  );
};

export default React.memo(ChartOfAccountSplitsView);
