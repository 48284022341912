import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { useGetAccountsPayableServiceDocumentsWithPossibleMatches } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetAccountsPayableServiceDocumentsResponse } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import {
  IAccountsPayableServiceDocument,
  IAccountsPayableServiceDocumentsFilter,
  IAccountsPayableServiceDocumentsQueryFilter,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { amountFilterToQuery, dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseInvoiceQueueCollectionParams {
  businessId: TID,
  type: string,
}

interface IInvoiceQueueCollection {
  query: UseInfiniteQueryResult<IGetAccountsPayableServiceDocumentsResponse, Error>,
  records: IAccountsPayableServiceDocument[],
  section: TSection,
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
  selectedRecords: IAccountsPayableServiceDocument[],
}

const defaultSorting: ISortingParams<TAccountsPayableServiceDocumentsSortColumn> = {};

const filterToQuery = (
  filterData: IAccountsPayableServiceDocumentsFilter | undefined,
  type: string,
): IAccountsPayableServiceDocumentsQueryFilter | undefined => {
  const query = omit(filterData || { queueType: type }, ['amount', 'invoice_date']);
  query.queueType = type;

  return {
    ...query,
    ...dateFilterToQuery(filterData?.invoice_date, 'invoice_date') || {},
    ...amountFilterToQuery(filterData?.amount, 'invoice_amount', 'value') || {},
  };
};

const useInvoiceQueueCollection = ({
  businessId,
  type,
}: IUseInvoiceQueueCollectionParams): IInvoiceQueueCollection => {
  const service = useAccountsPayableServiceContext();

  const section = useMemo(() => {
    return {
      businessId,
      section: window.Docyt.Common.Constants.SERVICE_TYPES.AP_SERVICE,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => {
    return filterToQuery(filterData, type);
  }, [filterData, type]);

  const sorting = useSorting<TAccountsPayableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetAccountsPayableServiceDocumentsWithPossibleMatches({
    serviceId: service.id,
    filters:   filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.documentId));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    sorting,
  };
};

export {
  IInvoiceQueueCollection,
  useInvoiceQueueCollection,
};
