import { TID } from '@src/types/common';
import { IReconciliationPaymentAccountData, IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut } from './helpers';

interface IGetReconciliationPaymentAccountsParams {
  businessId: TID
  noConsiderArchive?: boolean,
  isArchived?: boolean,
}

interface IGetReconciliationPaymentAccountsResponse {
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[]
}

interface IPutReconciliationPaymentAccountParams {
  accountId: TID,
  businessId: TID,
  data: IReconciliationPaymentAccountData,
}

interface IPutReconciliationPaymentAccountResponse {
  reconciliationPaymentAccount: IReconciliationPaymentAccount,
}

const getReconciliationPaymentAccounts = (
  params: IGetReconciliationPaymentAccountsParams,
): Promise<IGetReconciliationPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/reconciliation_payment_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetReconciliationPaymentAccountsResponse);
};

const putReconciliationPaymentAccount = (
  params: IPutReconciliationPaymentAccountParams,
): Promise<IPutReconciliationPaymentAccountResponse> => {
  return apiPut(
    `/api/v1/reconciliation_payment_accounts/${params.accountId}`,
    underscoreKeys({
      businessId:                   params.businessId,
      reconciliationPaymentAccount: params.data,
    }),
  ).then((data) => camelizeKeys(data) as IPutReconciliationPaymentAccountResponse);
};

export {
  IGetReconciliationPaymentAccountsParams,
  IPutReconciliationPaymentAccountParams,
  IGetReconciliationPaymentAccountsResponse,
  IPutReconciliationPaymentAccountResponse,
  getReconciliationPaymentAccounts,
  putReconciliationPaymentAccount,
};
