import React, { useCallback, useMemo } from 'react';

import { useGetReceivableAccountsPayableServices } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';

import {
  ISelectInputProps,
  SelectInput, TOption,
} from '@src/components/ui_v2/inputs';

interface IReceivableAccountPayableServiceInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  isDisabled?: boolean,
  value?: TID | null,
  onChange?: (value: TID | null, option: TOption | null) => void,
}

const ReceivableAccountPayableServiceInput = ({
  businessId,
  isDisabled,
  value,
  onChange,
  ...props
}: IReceivableAccountPayableServiceInputProps): JSX.Element => {
  const query = useGetReceivableAccountsPayableServices({ businessId });

  const handleChange = useCallback((newValue: TOption | null) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null, newValue);
  }, [onChange]);

  const options = useMemo(() => {
    return query.data?.map((account) => ({
      label: account.businessName,
      value: String(account.businessId),
    })) || [];
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return options.find((acc) => acc.value === String(value)) || null;
  }, [value, options]);

  return (
    <SelectInput
      isDisabled={ isDisabled }
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(ReceivableAccountPayableServiceInput);
