import React from 'react';

import classNames from 'classnames';

import { LongArrowIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface ISortIndicatorProps extends React.ComponentPropsWithoutRef<'span'> {
  direction: 'asc' | 'desc' | undefined,
}

const SortIndicator = ({
  className,
  direction,
  ...props
}: ISortIndicatorProps) => {
  const classes = classNames(styles['sort-indicator'], direction, className);

  return (
    <span className={ classes } { ...props }>
      <LongArrowIcon variant="down" />
      <LongArrowIcon variant="up" />
    </span>
  );
};

export default SortIndicator;
