import React, { useCallback } from 'react';

import { IPopoverParams } from '@src/hooks/popover';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import ClickContainer from './click_tooltip_container';

import styles from './styles.module.scss';

const defaultPopoverParams = {
  showDelay: 1000,
};

interface IClickTooltipProps extends React.ComponentPropsWithoutRef<typeof ClickContainer> {
  title?: string,
  content?: any,
  showTooltip?: boolean,
  popoverParams?: IPopoverParams,
  onClick: (agree: boolean) => void;
}

const ClickTooltip = ({
  title,
  content,
  showTooltip,
  onClick,
  popoverParams = defaultPopoverParams,
  ...props
}: IClickTooltipProps) => {
  const handleDone = useCallback(() => {
    onClick(true);
  }, [onClick]);

  const handleCancel = useCallback(() => {
    onClick(false);
  }, [onClick]);

  return (
    <Tooltip.Provider popoverParams={ popoverParams }>
      <ClickContainer
        showTooltip={ showTooltip }
        { ...props }
      >
        { content }
      </ClickContainer>
      {
        showTooltip && (
          <Tooltip>
            <div className={ styles['button-tooltip-form'] }>
              <span className={ styles['button-tooltip-title'] }>{ title }</span>
              <div className={ styles['button-container'] }>
                <Button
                  variant="link"
                  onClick={ handleCancel }
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={ handleDone }
                >
                  Yes
                </Button>
              </div>
            </div>
          </Tooltip>
        )
      }
    </Tooltip.Provider>
  );
};

export default ClickTooltip;
