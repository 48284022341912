module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header flex-center-wrapper">\n        <i class="icon icon-warning-triangle font-34"></i>\n        <h4 class="modal-title font-28">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body ');
    
      __out.push(__sanitize(this.type === 'delete' || this.type === 'multi_delete' ? 'text-center' : ''));
    
      __out.push('">\n        ');
    
      if (this.type === 'revenue') {
        __out.push('\n        <p class="m-t-40 m-b-40 font-16">Categories that are connected to Revenue Reports cannot be deleted.</p>\n        ');
      } else if (this.type === 'delete') {
        __out.push('\n        <p class="m-t-34 m-b-40 font-16">This account will be deleted from QuickBooks as well.</p>\n        ');
      } else if (this.type === 'multi_delete') {
        __out.push('\n        <p class="m-t-34 m-b-40 font-16">Categories will be deleted from QuickBooks as well.</p>\n        ');
      } else if (this.type === 'empty') {
        __out.push('\n        <p class="m-t-25 m-b-38 font-16">\n          Only an empty category can be deleted. This category is connected to existing documents.<br><br>\n          Please change the category for each associated documents, then try deleting again.\n        </p>\n        ');
      } else {
        __out.push('\n        <p class="m-t-43 m-b-45 font-16">\n          You\'ve chosen to move all items to the <b>"');
        __out.push(__sanitize(this.moveTo));
        __out.push('"</b> category.<br><br>\n          ');
        __out.push(this.categoryDetail);
        __out.push('\n        </p>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="modal-footer ');
    
      __out.push(__sanitize(!(this.type === 'delete' || this.type === 'move' || this.type === 'multi_delete') ? 'flex-center-wrapper' : ''));
    
      __out.push('">\n        ');
    
      if (this.type === 'move' || this.type === 'delete' || this.type === 'multi_delete') {
        __out.push('\n        <a id=\'btn-cancel\' class=\'cancel-link pointer font-18 font-semi-bold in-blue-900\'>Cancel</a>\n        <button type=\'button\' class="btn ');
        __out.push(__sanitize(this.type === 'move' ? 'btn-move' : 'btn-delete'));
        __out.push('" data-style="expand-right">\n            <span>');
        __out.push(__sanitize(this.type === 'move' ? 'Move' : 'Delete'));
        __out.push('</span>\n        </button>\n        ');
      } else {
        __out.push('\n        <button type=\'button\' id="btn-cancel" class="btn btn-done"><span>Done</span></button>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}