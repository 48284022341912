module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, ref, status;
    
      __out.push('<td class="checkbox-clickable relative pointer">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" id="check-register-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push(' class="check-register pointer">\n    <label for="check-register-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(this.check_number));
    
      __out.push('</span>\n</td>\n<td class="check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</span>\n</td>\n<td class="check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(this.payment_account_name));
    
      __out.push('</span>\n</td>\n<td class="check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('</span>\n</td>\n<td class="check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  ');
    
      if (this.status === 'cleared') {
        __out.push('\n  <span class="font-12 vertical-align-top">Cleared</span>\n  ');
      } else if (this.status !== 'voided') {
        __out.push('\n  <span class="font-12 vertical-align-top">');
        __out.push(__sanitize(this.outstanding_days));
        __out.push(' ');
        __out.push(__sanitize(this.outstanding_days > 1 ? 'days' : 'day'));
        __out.push('</span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="check-detail-column relative ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(this.check_memo));
    
      __out.push('</span>\n</td>\n<td class="check-status-panel-wrapper">\n  <div class="relative">\n    <span class="font-11 vertical-align-top font-semi-bold check-status-label no-wrap-text ');
    
      __out.push(__sanitize(this.formated_status));
    
      __out.push('">');
    
      __out.push(__sanitize(this.display_status));
    
      __out.push('</span>\n    ');
    
      if (this.check_status) {
        __out.push('\n    <div class="status-steps-wrapper hidden">\n      <div class="arrow"></div>\n      <ul class="steps">\n        ');
        ref = Docyt.Common.Constants.CHECK_TRACKING_STATES;
        for (index = i = 0, len = ref.length; i < len; index = ++i) {
          status = ref[index];
          __out.push('\n        <li class="step ');
          if (index < this.active_status_index) {
            __out.push(__sanitize('step-success'));
          }
          __out.push(' ');
          if (index === this.active_status_index) {
            __out.push(__sanitize('step-active'));
          }
          __out.push('">\n          <div class="step-content">\n            <span class="step-circle"></span>\n            <div class="step-name-wrapper">\n              ');
          if (this.checkStatusDate(index)) {
            __out.push('\n              <span class="step-date">');
            __out.push(__sanitize(this.checkStatusDate(index)));
            __out.push('</span>\n              ');
          } else {
            __out.push('\n              <span class="step-date">&lowbar;</span>\n              ');
          }
          __out.push('\n              <span class="step-text">');
          __out.push(__sanitize(status.name));
          __out.push('</span>\n            </div>\n          </div>\n        </li>\n        ');
        }
        __out.push('\n      </ul>\n      <div class="step-info-wrapper">\n        ');
        if (this.active_status) {
          __out.push('\n          <div class="status-title">\n             <span>Status: </span>\n             <span class="title-text ');
          if (this.active_status_index === Docyt.Common.Constants.CHECK_TRACKING_STATES.length - 1) {
            __out.push(__sanitize('returned-status-text'));
          }
          __out.push('">');
          __out.push(__sanitize(this.active_status.name));
          __out.push('</span>\n          </div>\n          <p class="status-detail">');
          __out.push(__sanitize(this.active_status.detail));
          __out.push('</p>\n          ');
          if (this.active_status_index === Docyt.Common.Constants.CHECK_TRACKING_STATES.length - 1) {
            __out.push('\n          <p class="status-detail">Please contact <a href="mailto:support@docyt.com" class="in-blue-950">support@docyt.com</a> for more information.</p>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="font-semi-bold text-right check-detail-column ');
    
      __out.push(__sanitize(this.check_id ? 'pointer' : ''));
    
      __out.push('">\n  <span class="font-12 vertical-align-top">');
    
      __out.push(__sanitize(this.currency_amount));
    
      __out.push('</span>\n</td>\n<td class="check-action-column">\n  ');
    
      if (this.check_id) {
        __out.push('\n  <a class="in-black pointer check-icon-column" title="');
        __out.push(__sanitize(this.check_title));
        __out.push('">\n    ');
        if (this.transaction_type === 'check') {
          __out.push('\n      ');
          if (this.document_source === 'SelfPrintCheck') {
            __out.push('\n        <span class="icon-print font-14"></span>\n      ');
          } else {
            __out.push('\n        <span class="icon-docyt-check-card font-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n      ');
          }
          __out.push('\n    ');
        } else {
          __out.push('\n    <span class="icon-manual-check font-14"></span>\n    ');
        }
        __out.push('\n  </a>\n  ');
      }
    
      __out.push('\n</td>\n<td class="check-chat-column">\n  ');
    
      if (this.check_id) {
        __out.push('\n  <div class="chat-icon-wrapper">\n    <a href="/document/');
        __out.push(__sanitize(this.check_id));
        __out.push('/chat">\n      <span class="font-30 icon-chat-empty"></span>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n</td>\n<td class="check-action-column">\n  ');
    
      if (this.check_id || this.invoice_id) {
        __out.push('\n  <div class="relative">\n    <a class="btn dropdown-toggle" type="button" id="payment-transaction-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n    </a>\n    <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="payment-transaction-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n      ');
        if (this.check_id) {
          __out.push('\n      <li><a tabindex="-1" href="/document/');
          __out.push(__sanitize(this.check_id));
          __out.push('/chat" class="send-message-btn"><span class="icon-chat-round m-r-22"></span>Send a Message</a></li>\n      <li>\n        <a tabindex="-1" href="/document/');
          __out.push(__sanitize(this.check_id));
          __out.push('" class="pointer view-check-btn">\n          <span class="icon icon-check-card font-18 m-r-18"></span>\n          View Check\n        </a>\n      </li>\n      ');
        }
        __out.push('\n      ');
        if (this.invoice_id) {
          __out.push('\n      <li>\n        <a href="/document/');
          __out.push(__sanitize(this.invoice_id));
          __out.push('" tabindex="-1" class="pointer">\n          <span class="icon-content font-18 m-r-18"></span>\n          View Invoice\n        </a>\n      </li>\n      ');
        }
        __out.push('\n      ');
        if (this.invoice_id) {
          __out.push('\n        ');
          if (Docyt.checkDocytUserByEmail('docyt.com')) {
            __out.push('\n          <li>\n            <a tabindex="-1" class="pointer report-issue-btn">\n              <span class="icon icon-wrench font-18 m-r-18"></span>\n              Report an Issue\n            </a>\n          </li>\n        ');
          }
          __out.push('\n        <li>\n          <a tabindex="-1" class="pointer cancel-check-btn">\n            <span class="icon-stop font-18 m-r-18"></span>\n            Cancel Check\n          </a>\n        </li>\n      ');
        }
        __out.push('\n    </ul>\n  </div>\n  ');
      }
    
      __out.push('\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}