module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="mapping-category-table-wrapper" class="mapping-category-table-wrapper">\n  <table class="table bg-white" style="table-layout: fixed;">\n    <colgroup span="4">\n      <col></col>\n      <col></col>\n      <col></col>\n      <col></col>\n      <col></col>\n    </colgroup>\n    <thead>\n      <tr>\n        <th class="relative" style=\'width:40px\'></th>\n        <th class="relative sortable width-20-percent" data-orderby="number" data-orderdir="ASC">Code</th>\n        <th class="relative sortable width-30-percent" data-orderby="account_type" data-orderdir="ASC">Type</th>\n        <th class="relative sortable width-50-percent sorted-desc" data-orderby="category" data-orderdir="ASC">Account</th>\n        <th></th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}