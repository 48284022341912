import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useBankAccountModal } from './modal';

import styles from '../styles.module.scss';

interface IAchBankAccountInfoProps {
  businessId: number
  vendor: IBusinessVendor
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>;
}

const AchBankAccountInfo = ({ vendor, businessId, setVendorData } : IAchBankAccountInfoProps) => {
  const confirmModal = useBankAccountModal();

  return (
    <div className="payee-bank-container">
      {vendor.dwollaFundingSource?.id ? (
        <div className="content">
          <div className={ styles.content }>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Bank Name</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{vendor.dwollaFundingSource?.name ? vendor.dwollaFundingSource.name : '-'}</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Account Number</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>
                  {vendor.dwollaFundingSource?.last4 ? `**** **** **** ${vendor.dwollaFundingSource.last4}` : '-'}
                </p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Routing Number</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>
                  {vendor.dwollaFundingSource?.routingNumber ? vendor.dwollaFundingSource.routingNumber : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-content">
          <i className="icon icon-bank-transactions-src bank-empty-icon">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
            <span className="path7" />
          </i>
          <h4 className={ styles['no-info-txt'] }>
            No information available
          </h4>
          <Button
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="outline"
            onClick={ confirmModal.open }
          >
            Add Bank Account Info
          </Button>
        </div>
      )}
      <confirmModal.Component
        formId="ach_bank_form"
        setVendorData={ setVendorData }
        { ...confirmModal.props }
        businessId={ businessId }
        vendor={ vendor }
      />
    </div>

  );
};

export default React.memo(AchBankAccountInfo);
