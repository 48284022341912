import React, { useCallback } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { PreviewIcon } from '@src/components/utils/icomoon';

interface IDocumentPreviewProps {
  document: IAccountsPayableServiceDocument,
}

const DocumentPreview = ({
  document,
}: IDocumentPreviewProps) => {
  const handlePreviewClick = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:preview', [document.documentId, document.vendor?.name]);
  }, [document]);

  return (
    <div>
      {
        (document.isDocumentUploaded) && (
          <PreviewIcon pointer fontSize={ 24 } onClick={ handlePreviewClick } />
        )
      }
    </div>
  );
};

export default React.memo(DocumentPreview);
