module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, splitTemplate;
    
      __out.push('<div class="display-flex align-items-center bottom-split-dialog-header">\n  <span class="split-dialog-title m-r-auto">Split Category</span>\n  <div class="template-dropdown-wrapper width-200px">\n    <div class="dropdown-toggle template-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n      <span class="template-label-js"></span>\n      <span class="fa fa-caret-down"></span>\n    </div>\n    <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n      <div class="account-menu-wrapper">\n        ');
    
      ref = this.splitTemplates;
      for (i = 0, len = ref.length; i < len; i++) {
        splitTemplate = ref[i];
        __out.push('\n        <li id="template-item-js" data-id=\'');
        __out.push(__sanitize(splitTemplate.id));
        __out.push('\'>\n          <div class=\'display-flex align-items-center\'>\n            <span class=\'text m-t-3\'>');
        __out.push(__sanitize(splitTemplate.get('name')));
        __out.push('</span>\n            <i class=\'icon icon-trashcan hover-action-item m-l-auto\'></i>\n          </div>\n        </li>\n        ');
      }
    
      __out.push('\n      </div>\n      <li class="split-btn save-template-js">\n        <div class=\'display-flex align-items-center\'>\n          <i class=\'font-14 fa fa-plus\'></i>\n          <span class=\'text m-t-3 m-l-10\'>Save Split Template</span>\n        </div>\n      </li>\n    </ul>\n  </div>\n  <div class="percent-control-wrapper">\n    <div class="percent-control-group display-flex pull-right">\n      <button class="btn cancel">\n        <span class="icon icon-not-a-chargeback-icon font-25"></span>\n      </button>\n    </div>\n    <div class="percent-control-group display-flex align-items-center percent-controls-js">\n      ');
    
      if (this.is_invoice) {
        __out.push('\n        ');
        if (this.state === "verifying" && this.is_ai_line_item_activated !== null && (!this.is_ai_line_item_activated || !this.can_access_ai_invoice_line_items_extraction)) {
          __out.push('\n\n          <button class="btn btn-blue-on-hover show-activate-line-items-modal"  style="width:70%; margin-right:20px;">\n            <img class="width-8-percent m-r-8" src="');
          __out.push(__sanitize(configData.images["upgrade_icon"]));
          __out.push('">\n            Extract Item using Docyt AI\n          </button>\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n      <button class="btn btn-blue-on-hover ');
    
      __out.push(__sanitize(this.service_documents.length > 1 ? 'hidden' : ''));
    
      __out.push(__sanitize(this.split_by_percentage === 0 ? 'active' : ''));
    
      __out.push(' btn-split-by-amount">$</button>\n      <button class="btn btn-blue-on-hover m-l-10 ');
    
      __out.push(__sanitize(this.split_by_percentage === 1 ? 'active' : ''));
    
      __out.push(' btn-split-by-percentage">%</button>\n    </div>\n  </div>\n</div>\n<div>\n  <table class="table table-responsive split-dialog-table" style="margin-bottom: 0;">\n    <thead>\n      <tr>\n        <th class="split-table-header" width="20%">Business</th>\n        <th class="split-table-header" width="20%">Department</th>\n        <th class="split-table-header" width="25%">Chart of Account</th>\n        <th class="split-table-header" width="10%">Amount</th>\n        <th class="split-table-header" width="25%">Description</th>\n        <th class="split-table-header ');
    
      __out.push(__sanitize((!this.is_readonly || this.can_split_paid_invoice) ? 'width-120px' : ''));
    
      __out.push('"></th>\n      </tr>\n    </thead>\n  </table>\n</div>\n<div class="fixed-table">\n  <table class="table table-responsive split-dialog-table">\n    <tbody class="split-business-container"></tbody>\n    <tfoot>\n      <tr>\n        <td colspan="5"></td>\n        <td colspan="2">\n          ');
    
      if (!this.is_readonly || this.can_split_paid_invoice) {
        __out.push('\n            <a tabindex="-1" class="btn split-action-btn font-14 pointer split-btn-js pull-right"><span class="icon-plus-circle font-14 m-r-10"></span>Add Split</a>\n          ');
      }
    
      __out.push('\n        </td>\n      </tr>\n    </tfoot>\n  </table>\n</div>\n<div class="bottom-split-dialog-header-footer">\n  <div class="container">\n    <div class="row">\n      <div class="col-md-3">\n        <a class="btn cancel font-14 font-semi-bold">Cancel</a>\n      </div>\n      <div class="col-md-6">\n        <div class="total-amount-wrapper">\n          <span class="font-14 font-semi-bold in-dark-250">Total amount: <span class="total-amount-label-js">');
    
      __out.push(__sanitize(this.total_amount));
    
      __out.push('</span></span>\n          <span class="header-separator"></span>\n          <span class="font-14 font-semi-bold in-dark-250">Invoice amount: ');
    
      __out.push(__sanitize(this.invoice_total));
    
      __out.push('</span>\n          <span class="header-separator"></span>\n          <span class="font-14 font-semi-bold in-dark-250">Difference: <span class="difference-amount-label-js">');
    
      __out.push(__sanitize(this.difference));
    
      __out.push('</span></span>\n        </div>\n      </div>\n      <div class="col-md-3">\n        <button type=\'button\' class="btn btn-blue ladda-button save-js">');
    
      __out.push(__sanitize(this.is_category_readonly && !this.can_split_paid_invoice ? 'Close' : 'Save'));
    
      __out.push('</button>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}