import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { ChatIcon } from '@src/components/utils/icomoon';

type TInvoiceQueueListActionsProps = {
  document: IAccountsPayableServiceDocument,
}

const InvoiceQueueListActions = ({
  document,
}: TInvoiceQueueListActionsProps) => {
  return (
    <>
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}/chat` }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
      />
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}` }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Detail"
      />
    </>
  );
};

export default InvoiceQueueListActions;
