import { IBusiness } from '@src/types/businesses';
import { TID, TMongoID, TUUID } from '@src/types/common';
import { IExportReportConfiguration } from '@src/types/data_exports';
import { IReport, IReportBudgetComparison, ITemplate } from '@src/types/report_service/report';

import { getReportBTFEndPoint }  from '@src/utils/config';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut, apiPost, apiDelete, apiPatch } from '../helpers';

interface IGetReportBySlugParams {
  businessId: TID,
  slug: string,
}

interface IGetReportByBusinessParams {
  businessId: TID,
  templateId: string,
}

interface ICreateReportParams {
  reportServiceId: TID,
  name: string,
  templateId: string,
}

interface IUpdateReportParams {
  reportId: string,
  name?: string,
  userIds?: TID[],
  acceptedChartOfAccountIds?: TID[],
  report?: {
    userIds: TID[],
  },
}

interface IReportsFileParams {
  reportServiceId?: TID,
  exportType: string,
  startDate: string,
  endDate: string,
  reportID?: string,
  multiBusinessReportId?: string,
  filter?: object,
  reportIds?: TUUID[],
  businessIds?: TID[],
  columns?: TMongoID[],
  includeDrillDown?: boolean,
}

interface IAdvancedReportsFileParams {
  export_type: string,
  start_date: string,
  end_date: string,
  report_id?: string,
  filter: object,
  file_type?: string,
  is_daily?: boolean,
  include_drill_down?: boolean,
  report_ids?: string[]
}

interface IReportsFileResponse {
}

interface ICopyMappingParams {
  currentBusinessId: TID,
  srcReportId: string,
  srcReportServiceId: TID,
  tarReportId: string,
  templateId: string,
}

interface IResetDefaultMappingParams {
  reportId: string
}

interface IGetReportLastUpdatedDateParams {
  businessId: TID,
  slug?: string,
  from: string,
  to: string,
}

interface IGetReportLastUpdatedDateResponse {
  lastUpdatedDate: string,
}

const getReportById = (reportId: TMongoID): Promise<IReport> => {
  return apiGet(`/reports/api/v1/reports/${reportId}`)
    .then((data) => camelizeKeys(data.report) as IReport);
};

const getReportByBusinessId = (params: IGetReportByBusinessParams): Promise<IReport[]> => {
  return apiGet('/reports/api/v1/reports/by_business', underscoreKeys(params))
    .then((data) => camelizeKeys(data.reports) as IReport[]);
};

const getReportBySlug = (params: IGetReportBySlugParams): Promise<IReport> => {
  return apiGet('/reports/api/v1/reports/by_slug', underscoreKeys(params))
    .then((data) => camelizeKeys(data.report) as IReport);
};

const getReports = (reportServiceId: TID): Promise<IReport[]> => {
  return apiGet('/reports/api/v1/reports', underscoreKeys({ reportServiceId }))
    .then((data) => camelizeKeys(data.reports) as IReport[]);
};

const getLightReportsList = (reportServiceId: TID): Promise<IReport[]> => {
  const endPoint = getReportBTFEndPoint(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.REPORTS_LIST)
  return apiGet(`/${endPoint}/api/v1/reports/reports_list`, underscoreKeys({ reportServiceId }))
    .then((data) => camelizeKeys(data.reports) as IReport[]);
};

const createReport = (params: ICreateReportParams): Promise<IReport> => {
  return apiPost(
    '/reports/api/v1/advanced_reports',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.report) as IReport);
};

const updateReport = (
  params: IUpdateReportParams,
): Promise<IReport> => {
  return apiPut(
    `/reports/api/v1/reports/${params.reportId}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.report) as IReport);
};

const refreshReport = (reportId: string): Promise<IReport> => {
  return apiPost(
    `/reports/api/v1/reports/${reportId}/update_report`,
    {},
  ).then((data) => camelizeKeys(data.report) as IReport);
};

const deleteReport = (reportId: string): Promise<void> => {
  return apiDelete(`/reports/api/v1/reports/${reportId}`);
};

const exportReport = (params: IReportsFileParams): Promise<IReportsFileResponse> => {
  return apiPost(
    '/reports/api/v1/export_reports',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IReportsFileResponse);
};

const exportReportData = (params: IAdvancedReportsFileParams): Promise<IReportsFileResponse> => {
  return apiPost(
    '/reports/api/v1/export_reports',
    params,
  ).then((data) => camelizeKeys(data) as IReportsFileResponse);
};

const getReportAvailableBusinesses = (templateId: string): Promise<IBusiness[]> => {
  return apiGet(
    '/reports/api/v1/reports/available_businesses',
    underscoreKeys({ templateId }),
  ).then((data) => camelizeKeys(data.available_businesses) as IBusiness[]);
};

const copyReportMapping = (
  params: ICopyMappingParams,
): Promise<void> => {
  return apiPost(
    `/reports/api/v1/reports/${params.tarReportId}/item_accounts/copy_mapping`,
    underscoreKeys(params),
  );
};

const resetDefaultMapping = (params: IResetDefaultMappingParams): Promise<void> => {
  return apiPost(`/reports/api/v1/reports/${params.reportId}/item_accounts/load_default_mapping`, {});
};

const getReportTemplates = (standardCategoryId: TID): Promise<ITemplate[]> => {
  return apiGet('/reports/api/v1/templates', underscoreKeys({ standardCategoryId }))
    .then((data) => camelizeKeys(data.templates) as ITemplate[]);
};

const getReportBudgetComparison = (params: {report_service_id: TID; 'report_comparer_ids[]': string[]}): Promise<IReportBudgetComparison[]> => {
  return apiGet('/reports/api/v1/report_comparers/by_ids', underscoreKeys(params))
  .then((data) => camelizeKeys(data.report_comparers) as IReportBudgetComparison[]);
}

const getReportBudgetComparerIds = (reportId: string, params: {from: string; to: string}): Promise<string[]> => {
  return apiGet(`/reports/api/v1/reports/${reportId}/report_datas/report_comparer_ids`, underscoreKeys(params))
  .then((data) => camelizeKeys(data.report_comparer_ids) as string[]);
}

const getReportLastUpdatedDate = (
  params: IGetReportLastUpdatedDateParams,
): Promise<IGetReportLastUpdatedDateResponse> => {
  return apiGet(
    '/reports/api/v1/reports/last_updated_date',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      lastUpdatedDate: cdata.lastUpdatedDate,
    };
  });
};

export {
  IGetReportBySlugParams,
  IGetReportByBusinessParams,
  ICreateReportParams,
  IExportReportConfiguration,
  IUpdateReportParams,
  IReportsFileParams,
  IAdvancedReportsFileParams,
  IReportsFileResponse,
  ICopyMappingParams,
  IResetDefaultMappingParams,
  IGetReportLastUpdatedDateParams,
  IGetReportLastUpdatedDateResponse,
  getReportById,
  getReportBySlug,
  getReportByBusinessId,
  getReports,
  getLightReportsList,
  createReport,
  updateReport,
  refreshReport,
  deleteReport,
  exportReport,
  exportReportData,
  copyReportMapping,
  resetDefaultMapping,
  getReportAvailableBusinesses,
  getReportTemplates,
  getReportLastUpdatedDate,
  getReportBudgetComparison,
  getReportBudgetComparerIds
};
