import * as yup from 'yup';
import { object, string, array } from 'yup';

import { IVendor } from '@src/types/business_vendors';

const businessAchAccountValidation = object({
  business_ids:           array().of(yup.number()).min(1, 'Business(es) is required.').required('Business(es) is required.'),
  receiver_type:          string().oneOf(['individual', 'business'], 'Receiver Type is required.'),
  business_name:          string().trim().required('Receiver Business Name is required.'),
  name:                   string().trim().required('Business Name is required.'),
  email:                  string().email('Enter a valid email address').required('Email Address is required.'),
  account_number:         string().trim().required('Account Number is required.').matches(/^[0-9]+$/, 'Enter a valid bank account number'),
  account_confirm_number: string()
    .oneOf([yup.ref('account_number'), null], 'Bank account number does not match')
    .required('Confirm Account Number is required.'),
  routing_number: string().trim().required('Routing Number is required.').matches(/^[0-9]+$/, 'Enter a valid routing number'),
});

const businessAddressBookValidation = object({
  address: object().shape({
    name:           string().trim().required('Name is required.'),
    addressLine1:   string().trim().required('Address is required.'),
    addressCity:    string().trim().required('City is required.'),
    addressState:   string().trim().required('State is required.'),
    addressZip:     string().trim().required('Zip is required.').matches(/^\d{5}(-\d{4})?$/, 'ZIP Code must be in a valid ZIP or ZIP+4'),
    addressCountry: string().trim().required('Country is required.'),
  }),
});

interface IBusinessAchAccountInput extends IVendor {
  business_ids: number[],
  business_name: string,
  receiver_type: string,
  name: string,
  email: string,
  account_number: string,
  account_confirm_number: string,
  routing_number: string,
  agreement: boolean,
}

interface IAddress {
  addressCity: string;
  addressCountry: string;
  addressLine1: string;
  addressState: string;
  addressZip: string;
  businessId: number;
  name: string;
  company: string;
  isAuthorized?:boolean;
  vendorId: number;
}

interface IUpdateAddress extends IAddress {
  addressableId: number;
  addressableType: string;
  createdAt: string;
  id: number;
  isGlobal: boolean;
  isLobVerified: boolean;
  updatedAt: string;
}

interface IBusinessAddressBookInput {
  address: IAddress;
}

interface IUpdateAddressBookInput {
  address: IUpdateAddress;
}

export {
  IBusinessAchAccountInput,
  IBusinessAddressBookInput,
  IUpdateAddressBookInput,
  businessAchAccountValidation,
  businessAddressBookValidation,
};
