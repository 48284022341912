import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ForwardOIcon } from '@src/components/utils/icomoon';

interface IForwardInvoicesActionProps {
  invoiceIds: TID[],
}

const ForwardInvoicesAction = ({
  invoiceIds,
}: IForwardInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Forward Invoices'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <ForwardOIcon fontSize={ 18 } /> }
      name="Forward"
      onClick={ handleSelect }
    />
  );
};

export default ForwardInvoicesAction;
