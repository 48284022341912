import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Big from 'big.js';
import moment from 'moment/moment';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { InvalidateQueryFilters, useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useCreateRcJournalEntry, useUpdateRcJournalEntry } from '@src/requests/adjustment_entries';
import { FormValues } from '@src/types/adjustment_entries';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { Button } from '@src/components/ui_v2/buttons';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import AttachIcon from '../../../utils/icomoon/attach';
import DateSelect from './date_select';
import { tableFormSchema, editDataToFormData, formDataToApiData } from './form_utilities.ts';
import TableFooter from './table_footer';
import TableRow from './table_row';

import styles from './styles.module.scss';

interface TableProps {
  close: () => void;
  editData?: any;
  additionalParams?: Record<string, any>;
  onSuccess?: () => Promise<void> | void;
  defaultRows?: object[]
  dateRange?: Date[]
}

const Table: FC<TableProps> = (props) => {
  const { close, editData, additionalParams, onSuccess, defaultRows, dateRange } = props;
  const editModel = Boolean(editData);
  const { mutateAsync } = useCreateRcJournalEntry();
  const { mutateAsync:  updateMutateAsync } = useUpdateRcJournalEntry();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const business = useBusinessContext();
  const businessId = business.id;

  const queryClient = useQueryClient();

  let defaultValues: any = { rows: defaultRows || [{}, {}], date: moment().format('YYYY-MM-DD') };

  if (dateRange) {
    defaultValues.date = moment(dateRange[0]).format('YYYY-MM-DD');
  }

  if (editModel) {
    const formData = editDataToFormData(editData);
    defaultValues = {
      id:   formData.id,
      rows: formData.rows,
      date: formData.date,
    };
  }

  const formMethods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(tableFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name:    'rows',
  });

  const rows = formMethods.watch('rows');
  const debits = rows.reduce((acc, current) => acc.add(Big(current.debit || 0)), Big(0));
  const credits = rows.reduce((acc, current) => acc.add(Big(current.credit || 0)), Big(0));
  const difference = debits.sub(credits).toNumber();
  const diffClassName = debits.eq(credits) ? styles.success : styles.warning;
  const nonZeroDbCr = rows.some((rrr) => rrr.credit !== undefined || rrr.debit !== undefined);

  const onSubmit = async (data: FormValues) => {
    const params = formDataToApiData(data, editModel, businessId, additionalParams);

    try {
      setIsSubmitting(true);
      if (editModel) {
        await updateMutateAsync(params);
        if (onSuccess) {
          await onSuccess();
        } else {
          const section = `${QueryKey.adjustmentEntry}-${params.id}`;
          await queryClient.invalidateQueries(section);
        }
        toastr.success('Journal entry Edited successfully!', 'Success');
      } else {
        await mutateAsync(params);
        if (onSuccess) {
          await onSuccess();
        } else {
          const section = { businessId, section: 'adjustment-entries' };
          await queryClient.invalidateQueries(section as InvalidateQueryFilters);
        }
        toastr.success('Journal Entry Created Successfully.', 'Success');
      }
      close();
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors) {
        const errorMessages = Object.values(errors);
        const errorMessageString = errorMessages.join(' ');
        toastr.error(errorMessageString, 'Error');
      } else {
        toastr.error(`Failed to ${editModel ? 'Edit' : 'Create'} Journal Entry.`, 'Error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteLine = (i: number) => {
    remove(i);
    formMethods.trigger(['totalAmount', 'totalLines']);
  };

  const addLine = () => {
    append({});
    formMethods.trigger('totalLines');
  };

  const btnText = editModel ? 'Save' : 'Add';

  return (
    <>
      <div className={ styles['table-container'] }>
        <FormProvider { ...formMethods }>
          <section className={ styles['table-info'] }>
            <DateSelect dateRange={ dateRange } />
          </section>

          <section className={ styles.table }>
            <div className={ styles['table-header'] }>
              <span>Sr No.</span>
              <span>Chart of Account</span>
              <span>Description</span>
              <span>Vendor/Customer</span>
              <span>Department</span>
              <span>Debits</span>
              <span>Credits</span>
              <span />
            </div>

            <div className={ styles['table-body'] }>
              {fields.map((field, index) => (
                <TableRow
                  key={ field.id }
                  deleteLine={ deleteLine }
                  editModel={ editModel }
                  index={ index }
                />
              ))}
              <TableFooter />
            </div>

            <section className={ styles['add-new-line'] }>
              <button type="button" onClick={ addLine }>
                <AttachIcon fontSize={ 16 } />
                Add Another Row
              </button>
            </section>
          </section>
        </FormProvider>
      </div>
      <div className={ styles['review-footer'] }>
        <div>
          <Button variant="link" onClick={ close }>
            Cancel
          </Button>
        </div>
        <div className={ styles['review-summary'] }>
          <span>
            Debits:
            { ' ' }
            { currencyLocaleFormatter(debits.toNumber()) }
          </span>
          <span>
            Credits:
            { ' ' }
            { currencyLocaleFormatter(credits.toNumber()) }
          </span>
          <span className={ nonZeroDbCr ? diffClassName : '' }>
            Difference:
            { ' ' }
            { currencyLocaleFormatter(difference) }
          </span>
        </div>
        <div>
          <Button disabled={ isSubmitting } variant="primary" onClick={ formMethods.handleSubmit(onSubmit) }>
            { isSubmitting ? <SpinnerIcon spin /> : btnText }
          </Button>
        </div>
      </div>
    </>
  );
};

export default Table;
