import React, { useCallback, useRef, useEffect } from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

import BlankAccountsPage from './blank_accounts_page';
import NewAccount from './new_account/new_account';

interface INewBankingAccountsContainerProps {
  businessId: TID,
  isBlankList?: boolean,
  onDone: () => void,
}

const NewBankingAccountsContainer = ({
  businessId,
  isBlankList,
  onDone,
}: INewBankingAccountsContainerProps): JSX.Element => {
  // TODO: We didn't implement main table for accounts.
  // All logic of listRegionRef should be removed while implementing main table.
  const listRegionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    listRegionRef.current = document.getElementById('banking-accounts-settings-region');
    if (listRegionRef.current) { listRegionRef.current.classList.remove('hidden'); }
  }, []);

  const handleCloseModal = useCallback(() => {
    if (listRegionRef.current) { listRegionRef.current.classList.add('hidden'); }

    onDone();
  }, [onDone]);

  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <SideView.Provider>
          <div className="page-main width-100-percent">
            { isBlankList ? (
              <BlankAccountsPage businessId={ businessId } />
            ) : (
              <NewAccount
                onCancel={ handleCloseModal }
                onDone={ handleCloseModal }
              />
            ) }
          </div>
          <SideView.Render />
        </SideView.Provider>
      </BusinessProvider>
    </Root>
  );
};

export default NewBankingAccountsContainer;
