import React, { useCallback } from 'react';

import { useUpdateTsdFlaggedState } from '@src/hooks/queries/transaction_service_documents';
import { documentPath } from '@src/routes';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon, FolderOpenIcon, TimesIcon, CheckCircleIcon } from '@src/components/utils/fa_icons';
import MutationStatus from '@src/components/utils/mutation_status';

type TAllTransactionsListActionsProps = {
  document: ITransactionServiceDocument,
}

const AllTransactionsListActions = ({
  document,
}: TAllTransactionsListActionsProps) => {
  const updateState = useUpdateTsdFlaggedState();
  const { mutateAsync } = updateState;

  const handleAction = useCallback((eventName: string) => {
    mutateAsync({ ...document, flaggedState: eventName === 'closeIssue' ? null : eventName });
  }, [document, mutateAsync]);

  const handleResolvedOrOpen = useCallback(() => {
    handleAction(document.flaggedState === 'open' ? 'resolved' : 'open');
  }, [handleAction, document.flaggedState]);

  const handleCloseIssue = useCallback(() => {
    handleAction('closeIssue');
  }, [handleAction]);

  return (
    <>
      <ActionsDropdown.LinkAction
        href={ documentPath(document.documentId) }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Transaction Detail"
      />
      {document?.flaggedState && (
        <>
          <ActionsDropdown.Action
            icon={ document.flaggedState === 'open' ? <CheckCircleIcon fontSize={ 20 } variant="o" /> : <FolderOpenIcon fontSize={ 20 } variant="o" /> }
            title={ document.flaggedState === 'open' ? 'Mark as Reviewed' : 'Re-open Issue' }
            onSelect={ handleResolvedOrOpen }
          />
          <ActionsDropdown.Action
            icon={ <TimesIcon fontSize={ 20 } /> }
            title="Close Issue"
            onSelect={ handleCloseIssue }
          />
        </>
      )}
      <MutationStatus mutation={ updateState } />
    </>
  );
};

export default AllTransactionsListActions;
