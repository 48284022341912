import { TID } from '@src/types/common';
import { IPaymentAccount, IPaymentAccountData } from '@src/types/payment_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from './helpers';

interface IGetPaymentAccountsParams {
  businessId: TID
  noConsiderArchive: boolean
  excludeSystemAccounts?: boolean
  accountType?: string,
}

interface IGetPaymentAccountsResponse {
  paymentAccounts: IPaymentAccount[]
}

interface ICreatePaymentAccountParams {
  businessId: TID,
  financialInstitutionBankAccountId?: TID,
  paymentAccount: IPaymentAccountData,
}

interface IUpdatePaymentAccountParams {
  id: TID,
  businessId: TID,
  paymentAccount: IPaymentAccountData,
}

const getPaymentAccounts = (
  params: IGetPaymentAccountsParams,
): Promise<IGetPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/payment_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetPaymentAccountsResponse);
};

const createPaymentAccount = (
  params: ICreatePaymentAccountParams,
): Promise<void> => {
  return apiPost('/api/v1/payment_accounts', underscoreKeys(params));
};

const updatePaymentAccount = (
  params: IUpdatePaymentAccountParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/payment_accounts/${params.id}`,
    underscoreKeys(params),
  );
};

export {
  IGetPaymentAccountsParams,
  ICreatePaymentAccountParams,
  IUpdatePaymentAccountParams,
  IGetPaymentAccountsResponse,
  getPaymentAccounts,
  createPaymentAccount,
  updatePaymentAccount,
};
