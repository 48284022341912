import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ISpinnerIconProps extends Omit<IFaIconProps, 'icon'> {
  spin?: boolean
}

const SpinnerIcon = (props: ISpinnerIconProps): JSX.Element => {
  return <FaIcon icon="spinner" { ...props } />;
};

export default SpinnerIcon;
