import React from 'react';

import { basicReportsPath, businessSettingsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';

interface IRegularReportServiceItemsProps {
  businessId: TID,
  businessInfo: IBusinessServicesInfo | undefined,
}

const RegularReportServiceItems = ({
  businessId,
  businessInfo: info,
}: IRegularReportServiceItemsProps) => {
  if (!info?.isReportAdminUser) {
    return null;
  }

  return (
    <ServiceItem
      serviceUrl={
        info?.reportServiceReady
          ? basicReportsPath(businessId)
          : businessSettingsPath(businessId)
        }
      title="Basic"
    />
  );
};

export default RegularReportServiceItems;
