import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import Table from '@src/components/ui/table';

import Item from './financial_institution_connection_item';

interface IFinancialInstitutionConnectionTableProps {
  financialInstitutionConnections: IFinancialInstitutionConnection[],
}

const FinancialInstitutionConnectionTable = ({
  financialInstitutionConnections,
}: IFinancialInstitutionConnectionTableProps) => {
  return (
    <Table
      className="finacial-institution-connection-center-table"
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 25 }>Financial Institution Account</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Last 4 digits</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Management Groups</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Banking Account</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Connection</Table.HCell>
          <Table.HCell widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          financialInstitutionConnections.map((financialInstitutionConnection) => (
            <Item
              key={ financialInstitutionConnection.id }
              financialInstitutionConnection={ financialInstitutionConnection }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(FinancialInstitutionConnectionTable);
