import React from 'react';

import { TID } from '@src/types/common';

import Table from '@src/components/accounts_payable/invoice_queue/list/table';
import TableSection from '@src/components/ui_v2/table_section';

import { IInvoiceQueueCollection } from '../hooks';

interface IInvoiceQueueListProps {
  collection: IInvoiceQueueCollection,
  currentUserId: TID,
}

const InvoiceQueueList = ({
  collection,
  currentUserId,
}: IInvoiceQueueListProps) => {
  return (
    <TableSection>
      <Table
        currentUserId={ currentUserId }
        infiniteQuery={ collection.query }
        records={ collection.records }
        sorting={ collection.sorting }
      />
    </TableSection>
  );
};

export default React.memo(InvoiceQueueList);
