import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { getServiceDocumentSplits } from '@src/requests/accounts_payable/service_document_splits';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { useCategorySplitsModal } from '@src/components/common_v2/category_splits_modal/category_splits_modal';
import {
  ICategorySplit,
  ICategorySplitsData,
} from '@src/components/common_v2/category_splits_modal/schema';
import { ErrorNotification } from '@src/components/ui/notification';
import MutationStatus from '@src/components/utils/mutation_status';

import {
  checkCategoryReadonly,
  getCategorySplits,
  copyCategorySplits,
} from './chart_of_account_splits_helper';
import ChartOfAccountSplitsView from './chart_of_account_splits_view';

import styles from '../../../styles.module.scss';

interface IChartOfAccountViewProps {
  document: IAccountsPayableServiceDocument,
}

const ChartOfAccountView = ({
  document,
}: IChartOfAccountViewProps) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [splits, setSplits] = useState<ICategorySplit[]>([]);
  const [isReadonly, setIsReadonly] = useState<boolean>(false);

  const business = useBusinessContext();

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();
  const { mutate } = createServiceDocumentSplits;

  const handleProceed = useCallback((dataSplits: ICategorySplit[]) => {
    mutate({
      documentId:            document.documentId,
      serviceDocumentSplits: dataSplits,
    }, {
      onSuccess: () => {
        setSuccessMsg(
          dataSplits.length > 1
            ? window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SPLIT
            : window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SET,
        );
      },
    });
  }, [document.documentId, mutate]);

  const handleContinue = useCallback(() => {
    handleProceed(splits);
  }, [handleProceed, splits]);

  const confirmDepartmentModal = useConfirmDeleteModal({
    onDone: handleContinue,
  });

  const handleDone = useCallback((data: ICategorySplitsData) => {
    if (isReadonly || data.splits.length === 0) return;

    setSplits(copyCategorySplits(data.splits));

    const hasAccountClasses = data.splits.filter((split) => !split.accountingClassId).length > 0;
    if (business.askClass && hasAccountClasses) {
      confirmDepartmentModal.open();
    } else {
      handleProceed(data.splits);
    }
  }, [business.askClass, confirmDepartmentModal, handleProceed, isReadonly]);

  const splitModal = useCategorySplitsModal({ onDone: handleDone });

  const adjustmentAmount = document.invoiceAmount;

  const handleEditCategorySplits = useCallback(() => {
    getServiceDocumentSplits({
      documentID: document.documentId,
    }).then((res) => {
      setIsReadonly(checkCategoryReadonly(business, document, res.serviceDocumentSplits));
      setSplits(getCategorySplits(business, document, res.serviceDocumentSplits));
      splitModal.open();
    }).catch((error) => {
      setErrorMsg(error.message);
    });
  }, [business, document, splitModal]);

  return (
    <div className={ styles['chart-of-account-item'] }>
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <confirmDepartmentModal.Component
        { ...confirmDepartmentModal.props }
        confirmStyle="primary"
        confirmTitle="Proceed"
        text={ (
          <span>
            You have not assigned a department to this invoice.
            <br />
            Do you wish to continue?
          </span>
        ) }
        title="Department not assigned"
      />
      <splitModal.Component
        adjustmentAmount={ adjustmentAmount }
        business={ business }
        isReadonly={ isReadonly }
        splits={ splits }
        summaryTitle="Invoice"
        { ...splitModal.props }
      />
      <ChartOfAccountSplitsView
        document={ document }
        onEdit={ handleEditCategorySplits }
      />
    </div>
  );
};

export default React.memo(ChartOfAccountView);
