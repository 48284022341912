import { useMemo } from 'react';

import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { UseQueryResult } from 'react-query';

import { useGetNoRevenueDates } from '@src/hooks/queries/no_revenue_dates';
import { IGetNoRevenueDateFilterParams, IGetNoRevenueDatesResponse } from '@src/requests/no_revenue_dates';
import { TAmount, TDate, TID } from '@src/types/common';
import { INoRevenueDate } from '@src/types/no_revenue_dates';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { TRevenueServiceCategorySection } from '@src/types/revenue_service_categories';
import { IRevenueServiceDocument, IRevenueServiceDocumentsFilter } from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { endOfMonthApiDate, parseApiMonth, startOfMonthApiDate } from '@src/utils/date_helpers';

const NO_REPORT_ID = -1;

interface ITypeCategoryIdsForSectionParams {
  service: IRevenueService,
  section: TRevenueServiceCategorySection,
  reportTypeId: TID,
}

const typeCategoryIdsForSection = memoize(({
  service,
  section,
  reportTypeId,
}: ITypeCategoryIdsForSectionParams): TID[] | null => {
  const reportType = service.revenueReportTypes.find((rt) => {
    return rt.id === reportTypeId;
  });
  if (!reportType) return null;

  const categoryIds = service.revenueServiceCategories.filter((c) => {
    return c.section === section;
  }).map((c) => c.id);

  return reportType?.revenueReportTypeCategories.filter((tc) => {
    return categoryIds.includes(tc.revenueServiceCategoryId);
  }).map((tc) => tc.id);
}, ({ service, section, reportTypeId }) => `${service.id}_${section}_${reportTypeId}`);

interface ICategoryDisplayValuesForSectionParams {
  document: IRevenueServiceDocument,
  section: TRevenueServiceCategorySection,
  service: IRevenueService,
}

const documentDisplayValuesForSection = ({
  document,
  section,
  service,
}: ICategoryDisplayValuesForSectionParams): TAmount[] | null => {
  if (!document.revenueReportTypeId) return null;

  const typeCategoryIds = typeCategoryIdsForSection({
    service,
    section,
    reportTypeId: document.revenueReportTypeId,
  });
  if (!typeCategoryIds) return null;

  const values = document.revenueServiceDocumentCategoryValues.filter((v) => {
    return typeCategoryIds.includes(v.revenueReportTypeCategoryId);
  });

  return values.map((v) => v.displayValue);
};

const documentDisplayValuesSumForSection = (
  params: ICategoryDisplayValuesForSectionParams,
): number | null => {
  const values = documentDisplayValuesForSection(params);
  if (!values || values.length === 0) return null;

  return sum(values.map(Number));
};

const amountForPaymentProcessor = (
  revenue: IRevenueServiceDocument,
  paymentProcessorId: TID,
) => {
  const amountInfo = revenue.totalAmountsByPaymentProcessor.find(
    (item) => item.id === paymentProcessorId,
  );
  return amountInfo?.value;
};

const reconciledForPaymentProcessor = (
  revenue: IRevenueServiceDocument,
  paymentProcessorId: TID,
) => {
  const amountInfo = revenue.totalAmountsByPaymentProcessor.find(
    (item) => item.id === paymentProcessorId,
  );
  return amountInfo?.reconciled;
};

const revenueReconciliationIdForPaymentProcessor = (
  revenue: IRevenueServiceDocument,
  paymentProcessorId: TID,
) => {
  const amountInfo = revenue.totalAmountsByPaymentProcessor.find(
    (item) => item.id === paymentProcessorId,
  );
  return amountInfo?.revenueReconciliationId;
};

const totalAmountForPaymentProcessor = (
  revenues: IRevenueServiceDocument[],
  paymentProcessorId: TID,
) => {
  const values = revenues.map((revenue) => {
    return amountForPaymentProcessor(revenue, paymentProcessorId) || '0.0';
  });
  return sum(values.map(Number));
};

const emptyRSD = (
  revenueServiceId: TID,
  date: TDate,
  noRevenueDates: INoRevenueDate[],
): IRevenueServiceDocument => {
  const noRevenueDate = noRevenueDates.find((n) => n.date === date);

  const rowKey = `${date}-${NO_REPORT_ID}`;
  const status = noRevenueDate ? 'no_revenue_day' : 'missing';
  return {
    id:                                   NO_REPORT_ID,
    revenueExtractedFields:               [],
    revenueServiceDocumentCategoryValues: [],
    revenueServiceId,
    startDate:                            date,
    endDate:                              date,
    state:                                'processing',
    status,
    totalAmountsByPaymentProcessor:       [],
    rowKey,
  };
};

interface INoRevenueDateRecordsParams {
  revenueSystem: IRevenueSystem,
  filterData: IRevenueServiceDocumentsFilter | undefined,
}

interface IUseNoRevenueDateRecordsReturn {
  noRevenueDatesQuery: UseQueryResult<IGetNoRevenueDatesResponse, Error>,
  noRevenueDatesRecords: INoRevenueDate[]
}

const useNoRevenueDateRecords = ({
  revenueSystem,
  filterData,
}: INoRevenueDateRecordsParams): IUseNoRevenueDateRecordsReturn => {
  let filter: IGetNoRevenueDateFilterParams | null = null;

  if (filterData?.month) {
    const date = parseApiMonth(filterData.month);
    filter = {
      date: {
        gte: startOfMonthApiDate(date),
        lte: endOfMonthApiDate(date),
      },
    };
  }
  if (filterData?.endDate) {
    filter = { date: filterData.endDate };
  }

  const noRevenueDatesQuery = useGetNoRevenueDates({
    revenueSystemId: revenueSystem.id,
    filter,
  });

  return useMemo(() => ({
    noRevenueDatesRecords: noRevenueDatesQuery?.data?.collection || [],
    noRevenueDatesQuery,
  }), [noRevenueDatesQuery]);
};

interface IPaymentProcessorsForRevenueReportTypeParams {
  revenueReportTypeId: TID,
  revenueService: IRevenueService,
}

const paymentProcessorsForRevenueReportTypeId = memoize(({
  revenueReportTypeId,
  revenueService,
}: IPaymentProcessorsForRevenueReportTypeParams): IPaymentProcessor[] => {
  const revenueReportType = revenueService.revenueReportTypes.find((item) => {
    return item.id === revenueReportTypeId;
  });
  if (!revenueReportType) return [];

  const revenueServiceCategoryIdsForSystem =
    revenueReportType.revenueReportTypeCategories.map((a) => a.revenueServiceCategoryId) || [];
  const serviceCategories = revenueService.revenueServiceCategories.filter((r) => {
    return r.paymentProcessorId !== null && revenueServiceCategoryIdsForSystem.includes(r.id);
  });
  const pps = uniqBy(serviceCategories, (r) => r.paymentProcessorId).map((sc) => ({
    id:   sc.paymentProcessorId!,
    name: sc.paymentProcessorName!,
    code: sc.paymentProcessorCode!,
  }));
  return sortBy(pps, ['name']);
}, ({ revenueReportTypeId, revenueService }) => `revenue_service_${revenueService.id}_report_type_${revenueReportTypeId}`);

function sortRevenueSystems(revenueSystems: IRevenueSystem[]) {
  return revenueSystems.slice().sort((a: IRevenueSystem, b: IRevenueSystem) => {
    if (a.isArchived === b.isArchived) {
      return a.name.localeCompare(b.name);
    }
    return a.isArchived ? 1 : -1;
  });
}

export {
  NO_REPORT_ID,
  documentDisplayValuesForSection,
  documentDisplayValuesSumForSection,
  amountForPaymentProcessor,
  reconciledForPaymentProcessor,
  revenueReconciliationIdForPaymentProcessor,
  totalAmountForPaymentProcessor,
  emptyRSD,
  useNoRevenueDateRecords,
  paymentProcessorsForRevenueReportTypeId,
  sortRevenueSystems,
};
