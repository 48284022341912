import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';

interface IApproveInvoicesActionProps {
  invoiceIds: TID[],
}

const ApproveInvoicesAction = ({
  invoiceIds,
}: IApproveInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Approve Invoices'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <ThumbsUpIcon fontSize={ 18 } /> }
      name="Approve Invoices"
      onClick={ handleSelect }
    />
  );
};

export default ApproveInvoicesAction;
