import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { AppLink } from '@src/components/ui_v2/buttons';
import { ChatIcon } from '@src/components/utils/icomoon';

import styles from '../../styles.module.scss';

interface IInvoiceChatProps {
  document: IAccountsPayableServiceDocument,
}

const InvoiceChat = ({
  document,
}: IInvoiceChatProps) => {
  const hasMessages = document.unreadMessagesCount > 0;
  const icon = (
    <>
      <ChatIcon fontSize={ 26 } variant={ hasMessages ? 'filled' : 'empty' } />
      {
        hasMessages && (
          <span className={ styles['unread-messages-count'] }>
            { document.unreadMessagesCount < 10 ? document.unreadMessagesCount : '9+' }
          </span>
        )
      }
    </>
  );

  return (
    <AppLink
      newWindow
      className={ hasMessages ? 'relative' : '' }
      href={ `/document/${document.documentId}/chat` }
    >
      { icon }
    </AppLink>
  );
};

export default React.memo(InvoiceChat);
