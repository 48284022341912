import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { ChatIcon } from '@src/components/utils/icomoon';
import { TDate } from '@src/types/common';

type TMatchDocumentsExpensesListActionsProps = {
  document: ITransactionServiceDocument,
  disabledRow: boolean
}

const MatchDocumentsExpensesListActions = ({
  document,
  disabledRow
}: TMatchDocumentsExpensesListActionsProps) => {
  return (
    <>
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}/chat` }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
        disabled={disabledRow}
      />
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}` }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Transaction Detail"
      />
    </>
  );
};

export default MatchDocumentsExpensesListActions;
