import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import Tooltip from '@src/components/ui/tooltip';
import { SplitBadgeIcon, ManualTransactionIcon, DuplicatedTransactionIcon, VerifyDataIcon, VerifyManualIcon } from '@src/components/utils/icomoon';

interface ITransactionTypeBadgeProps {
  transaction: ITransactionServiceDocument,
  widthPercentage?: any,
}

const TransactionTypeBadge = ({
  transaction,
  widthPercentage = 30,
}: ITransactionTypeBadgeProps) => {
  const isSplitted = Boolean(transaction.parentBankTransactionId);
  const manuallyCreated = transaction.manuallyCreated;
  const isDuplicated = transaction.duplicated;
  const duplicatedOriginTransaction = transaction.duplicateOriginTransaction;
  const isVerified = Boolean(transaction.bankStatement?.state === 'verified');

  const classes = uiStyleClassNames(
    `display-inline-flex ${isDuplicated ? 'duplicated-transaction' : ''}`,
    {
      pull:         'left',
      pl:           4,
      pt:           7,
      widthPercent: widthPercentage,
    },
  );

  let content;
  if (isSplitted) {
    content = (
      <SplitBadgeIcon fontSize={ 18 } />
    );
  } else if (isDuplicated) {
    content = (
      <Tooltip
        className="pointer"
        mr={ 10 }
        title={
          '<span class="in-grey-800 font-bold">Possible Duplicate</span><br/>'
          + '<span class="in-grey-800 font-12">Docyt has detected that this transaction might be a possible duplicate of the following transaction</span><br/>'
          + `<span class="in-grey-800 font-12">${duplicatedOriginTransaction.description} | ${duplicatedOriginTransaction.docytId} | ${duplicatedOriginTransaction.transactionDate} | ${duplicatedOriginTransaction.amount}</span>`
        }
      >
        <DuplicatedTransactionIcon fontSize={ 18 } />
      </Tooltip>
    );
  } else if (isVerified && manuallyCreated) {
    content = (
      <VerifyManualIcon fontSize={ 18 } />
    );
  } else if (manuallyCreated) {
    content = (
      <Tooltip
        className="pointer"
        mr={ 10 }
        title="Manually Created"
      >
        <ManualTransactionIcon fontSize={ 18 } />
      </Tooltip>
    );
  } else if (isVerified) {
    content = (
      <VerifyDataIcon fontSize={ 18 } />
    );
  }

  return (
    <div className={ classes }>
      { content }
    </div>
  );
};

export default TransactionTypeBadge;
