module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="servicemenu-region"></div>\n<ul class="nav nav-sidebar">\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'vendors' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('">\n      <span class="icon-money-exchange service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">My Vendors</span>\n      <span class="nav-item-number">');
    
      __out.push(__sanitize(this.count_vendors));
    
      __out.push('</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'docyt_network' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/docyt_network">\n      <span class="icon-Payees service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Docyt Business Network</span>\n      <span class="nav-item-number"></span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'eligibility_1099_report' ? 'active' : ''));
    
      __out.push('" data-global-feature-toggle="vendors-service-1099">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/1099_reports">\n      <span class="icon-1099-reports font-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>\n      <span class="nav-item-text">1099 Reports</span>\n    </a>\n  </li>\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}