import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon, AddressLocationIcon } from '@src/components/utils/icomoon';

import { useAddressBookModal } from './modal/address_book_modal';
import Address from './table/address_table';

import styles from '../styles.module.scss';

interface IAddressBookProps {
  businessId: number
  vendor: IBusinessVendor
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
}

const AddressBook = ({
  vendor,
  businessId,
  setVendorData,
} : IAddressBookProps) => {
  const addressBookModal = useAddressBookModal();

  const addNewAddressButton = (
    <Button
      className={ styles['transparent-bg'] }
      prefixIcon={ <PlusIcon fontSize={ 14 } /> }
      variant="outline"
      onClick={ addressBookModal.open }
    >
      Add New Address
    </Button>
  );

  return (
    <div className="payee-bank-container">
      <div className={ styles['vendor-profile-second-content-row'] }>
        <div className={ styles.header }>
          <div className={ styles.item }>
            <p className={ styles.title }>
              {vendor.addresses && vendor.addresses.length > 0
                ? 'Physical Check Address Book'
                : 'Address Book'}
            </p>
          </div>
          {vendor.addresses && vendor.addresses.length > 0 && (
          <div>{addNewAddressButton}</div>
          )}
        </div>
        {vendor.addresses && vendor.addresses.length > 0 ? (
          <Address businessVendor={ vendor } setVendorData={ setVendorData } />
        ) : (
          <div className={ styles['section-content'] }>
            <div className={ styles['no-data-container'] }>
              <AddressLocationIcon fontSize={ 70 } />
              <h4 className={ styles['no-info-txt'] }>
                No address available
              </h4>
              {addNewAddressButton}
            </div>
          </div>
        )}
        <addressBookModal.Component
          formId="address_book_form"
          setVendorData={ setVendorData }
          { ...addressBookModal.props }
          businessId={ businessId }
          vendor={ vendor }
        />
      </div>
    </div>
  );
};

export default React.memo(AddressBook);
