module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, itemAccount, j, len, len1, ref, ref1;
    
      __out.push('<div class="display-flex-column in-black">\n  <div class="display-flex align-items-center m-t-16">\n    <div class="display-flex-column m-l-28">\n      ');
    
      if (this.childItem.id) {
        __out.push('\n      <span class="inline-block font-12">');
        __out.push(__sanitize(this.parentItem.get('name')));
        __out.push(' /</span>\n      <span class="inline-block font-semi-bold font-16">');
        __out.push(__sanitize(this.childItem.get('name')));
        __out.push('</span>\n      ');
      } else {
        __out.push('\n      <span class="inline-block font-semi-bold font-16">');
        __out.push(__sanitize(this.parentItem.get('name')));
        __out.push('</span>\n      ');
      }
    
      __out.push('\n    </div>\n    <div class="display-flex-column m-l-auto m-r-12">\n      <span class="inline-block in-grey-1050 font-semi-bold">YTD Balance</span>\n      <span class="inline-block font-semi-bold font-14">$0.00</span>\n    </div>\n    <div class="relative">\n      <a class="btn dropdown-toggle in-grey-800" type="button" id="map-action-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n        <span class="icon icon-3-dots font-24"></span>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-right font-16" role="menu" aria-labelledby="map-action-dropdown-btn">\n        <li class="hidden">\n          <a class="pointer rename-btn">\n            <div class=\'dropdown-menu-action-item\'>\n              <div class=\'action-icon\'><i class="icon icon-edit action-icon"></i></div>\n              <span>Rename</span>\n            </div>\n          </a>\n        </li>\n        <li>\n          <a class="pointer bulk-unlink-btn">\n            <div class=\'dropdown-menu-action-item\'>\n              <div class=\'action-icon\'><i class="icon icon-unlink action-icon"></i></div>\n              <span>Bulk Unlink</span>\n            </div>\n          </a>\n        </li>\n        <li class="hidden">\n          <a class="pointer delete-btn danger-link">\n            <div class=\'dropdown-menu-action-item\'>\n              <div class=\'action-icon\'><i class="icon icon-trashcan action-icon"></i></div>\n              <span>Delete</span>\n            </div>\n          </a>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class="width-100-percent m-t-14 p-b-14 p-l-28 p-r-20 font-12 item-divider">\n    <span class="inline-block in-grey-1050">The chart of accounts listed below contribute to ');
    
      __out.push(__sanitize(this.childItem.id ? this.childItem.get('name') : this.parentItem.get('name')));
    
      __out.push('.</span>\n  </div>\n  ');
    
      if (this.itemAccounts.length > 0) {
        __out.push('\n    ');
        if (this.isEditMode) {
          __out.push('\n        <div class="link-row item-divider">\n          <div class="display-flex align-items-center select-all-js pointer ">\n            <div class="checkbox checkbox-primary">\n              <input class="pointer check-all-accounts-input" type="checkbox">\n              <label class="pointer"></label>\n            </div>\n            <span>Select All</span>\n          </div>\n          <a class="pointer font-semi-bold m-l-auto cancel-btn">Cancel</a>\n          <button type=\'button\' class="btn btn-blue m-l-17 continue-btn">Unlink</button>\n        </div>\n        ');
          ref = this.itemAccounts;
          for (i = 0, len = ref.length; i < len; i++) {
            itemAccount = ref[i];
            __out.push('\n          <div class="document-clickable pointer link-row item-divider">\n            <div class="checkbox checkbox-primary">\n              <input id="');
            __out.push(__sanitize(itemAccount.id));
            __out.push('" class="pointer check-account-input" type="checkbox" itemId="');
            __out.push(__sanitize(itemAccount.id));
            __out.push('">\n              <label class="pointer"></label>\n            </div>\n            <span>');
            __out.push(__sanitize(this.getItemAccountName(itemAccount)));
            __out.push('</span>\n          </div>\n        ');
          }
          __out.push('\n    ');
        } else {
          __out.push('\n        ');
          ref1 = this.itemAccounts;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            itemAccount = ref1[j];
            __out.push('\n          <div class="link-row item-divider">\n            <span>');
            __out.push(__sanitize(this.getItemAccountName(itemAccount)));
            __out.push('</span>\n            <a id=\'');
            __out.push(__sanitize(itemAccount.get('id')));
            __out.push('\' class="pointer font-semi-bold m-l-auto unlink-btn">Unlink</a>\n          </div>\n        ');
          }
          __out.push('\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    <div class="width-100-percent padding-28 font-12">\n      <span class="inline-block in-grey-1050">No chart of accounts linked.<br/>Select relevant chart of accounts from the list on right to link.</span>\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}