module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!this.match_type) {
        __out.push('\n  <div class="col-xs-4">\n    <div class="form-group relative">\n      <input id="vendorName" class="input-vendor typeahead form-control" type="text" placeholder="Vendor" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.vendor_name));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-4">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From Date" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.transaction_date_start_string));
        __out.push('"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To Date" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.transaction_date_end_string));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-4">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" placeholder="$ Min" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-1">\n    <div class="form-group">\n      <input type="text" class="form-control typeahead verified-typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.verified_value));
        __out.push('"/>\n    </div>\n  </div>\n');
      } else if (this.match_type === Docyt.Common.Constants.TRANSACTION_TYPES['CATEGORY_TYPE']) {
        __out.push('\n <div class="m-l-15">\n   <div class="checkbox pointer checkbox-primary">\n     <input class="check-all-js pointer" type="checkbox">\n     <label class="pointer"></label>\n   </div>\n </div>\n  <div class="col-xs-3">\n    <div class="form-group">\n      <input type="text" class="form-control input-category-code" placeholder="Category or Code" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.category_code));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="form-group relative category-typeahead-wrapper">\n      <input id="accountType" class="input-account-type typeahead form-control" type="text" placeholder="Account Type" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.account_type));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="form-group relative category-typeahead-wrapper">\n      <input id="detailType" class="input-detail-type typeahead form-control" type="text" placeholder="Detail Type" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.detail_type));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="form-group relative category-typeahead-wrapper">\n      <input class="form-control input-mapped-classes" type="text" placeholder="Mapped Departments" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.mapped_classes));
        __out.push('"/>\n    </div>\n  </div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}