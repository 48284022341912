import React, { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { useRefreshReport } from '@src/hooks/queries/report_service/custom_reports';
import { IReport } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { UpdateReportIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { reportUpdatingState } from '../../atoms';

import styles from '../styles.module.scss';

interface IUpdateReportActionProps {
  report: IReport,
}

const UpdateReportAction = ({
  report,
}: IUpdateReportActionProps): JSX.Element => {
  const refreshReport = useRefreshReport();
  const setReportUpdatingState = useSetRecoilState(reportUpdatingState);

  const handleRefreshReport = useCallback(() => {
    refreshReport.mutate(report.id, {
      onSuccess: (data) => {
        setReportUpdatingState(`${data.name}: Update in progress`);
      },
    });
  }, [refreshReport, report.id, setReportUpdatingState]);

  return (
    <>
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <UpdateReportIcon fontSize={ 18 } /> }
        title="Update Report"
        onSelect={ handleRefreshReport }
      />
      <MutationStatus mutation={ refreshReport } />
    </>
  );
};

export default UpdateReportAction;
