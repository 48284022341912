import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ThumbsDownIcon } from '@src/components/utils/icomoon';

interface IUnApproveInvoicesActionProps {
  invoiceIds: TID[],
}

const UnApproveInvoicesAction = ({
  invoiceIds,
}: IUnApproveInvoicesActionProps) => {
  const handleSelect = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(`Event: ap_service:invoices:bulk:action, ids: ${invoiceIds}, type: 'Retract Approval'`);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <ThumbsDownIcon fontSize={ 18 } /> }
      name="Retract Approval"
      onClick={ handleSelect }
    />
  );
};

export default UnApproveInvoicesAction;
