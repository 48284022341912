import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import {
  SuccessIcon,
} from '@src/components/utils/icomoon';

interface IStatusIconProps {
  document: ITransactionServiceDocument,
}

const StatusIcon = ({
  document,
}: IStatusIconProps) => {
  if (document.revenueReconciliationItem) {
    return <SuccessIcon fontSize={ 18 } />;
  }
  return null;
};

export default React.memo(StatusIcon);
