import React from 'react';

import { TID } from '@src/types/common';

import Section from '@src/components/utils_v2/section';

import { BulkActions } from './actions/bulk_actions';
import Banner from './banner';
import Header from './header';
import { useInvoiceQueueCollection } from './hooks';
import List from './list';

import styles from './styles.module.scss';

interface IInvoiceQueueProps {
  businessId: TID,
  currentUserId: TID,
  type: string,
}

const InvoiceQueue = ({
  businessId,
  currentUserId,
  type,
}: IInvoiceQueueProps) => {
  const collection = useInvoiceQueueCollection({
    businessId,
    type,
  });

  return (
    <>
      <Header
        businessId={ businessId }
        type={ type }
      />
      <Banner
        showBulkActionResult={ false }
        showReloadNotification={ false }
      />

      <Section.Provider section={ collection.section }>
        <div className="display-flex align-items-center m-b-4 justify-content-between">
          <div className="display-flex align-items-center" style={ {gap: '15px'} }>
            <BulkActions
              currentUserId={ currentUserId }
              selectedInvoices={ collection.selectedRecords }
            />
          </div>
        </div>
        <div className={ styles['tables-container'] }>
          <List
            collection={ collection }
            currentUserId={ currentUserId }
          />
        </div>
      </Section.Provider>
    </>
  );
};

export default React.memo(InvoiceQueue);
