import { useMutation, useQuery } from 'react-query';

import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import {
  IUploadCSVResponse,
  IUploadCSVParams,
  uploadCSV,
  IJournal,
  IGetJournalsParams,
  IGetJournalsResponse,
  getJournals,
  IGetJournalResponse,
  getJournal,
  IDeleteJournalParams,
  deleteJournal,
  IManagementGroupsResponse,
  IJournalNamesResponse,
  getJournalNames,
  getFilterManagementGroups,
  getJournalEntriesCSV,
  JournalEntriesCSVParams,
  getSingleJournalEntriesCSV,
  SingleJournalEntriesParams,
} from '@src/requests/joural_entries';

const JOURNALS_QUERY = 'journals';
const JOURNAL_QUERY = 'journal';
const JOURNAL_NAMES_QUERY = 'journal_names';
const J_FILTER_MANAGEMENT_GROUPS_QUERY = 'j_filter_management_groups';
const useUploadCSV = () => {
  return useMutation<IUploadCSVResponse, Error, IUploadCSVParams>(uploadCSV);
};

const useDeleteJournal = () => {
  return useMutation<void, Error, IDeleteJournalParams>(deleteJournal);
};

const useGetJournals = createUseGetInfiniteCollection<
  IJournal,
  IGetJournalsParams,
  IGetJournalsResponse
>({
  queryKey: JOURNALS_QUERY,
  request:  getJournals,
});

const useGetJournal = (id: number, businessId: number) => {
  return useQuery<IGetJournalResponse, Error>({
    queryKey: JOURNAL_QUERY,
    queryFn:  () => getJournal(id, businessId),
  });
};

const useGetJournalNames = (businessId: number) => {
  return useQuery<IJournalNamesResponse, Error>({
    queryKey: JOURNAL_NAMES_QUERY,
    queryFn:  () => getJournalNames(businessId),
  });
};

const useGetFilterManagementGroups = (businessId: number) => {
  return useQuery<IManagementGroupsResponse, Error>({
    queryKey: J_FILTER_MANAGEMENT_GROUPS_QUERY,
    queryFn:  () => getFilterManagementGroups(businessId),
  });
};
const useGetJournalEntriesCSV = () => {
  return useMutation<Blob, Error, JournalEntriesCSVParams>(getJournalEntriesCSV);
};

const useGetSingleJournalEntries = () => {
  return useMutation<Blob, Error, SingleJournalEntriesParams>(getSingleJournalEntriesCSV);
};

export {
  useUploadCSV,
  useGetJournals,
  useGetJournal,
  JOURNALS_QUERY,
  useDeleteJournal,
  useGetJournalNames,
  useGetFilterManagementGroups,
  useGetJournalEntriesCSV,
  useGetSingleJournalEntries,
};
