module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1;
    
      this.isDisabled = this.beforeBookDate ? 'disabled' : '';
    
      __out.push('\n<button class="btn dropdown-toggle font-15 font-bold ');
    
      __out.push(__sanitize(this.button_text ? 'invoice-amount-button' : 'header-icon-button'));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.button_class));
    
      __out.push('" type="button" id="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  ');
    
      if (this.button_text) {
        __out.push('\n    ');
        __out.push(__sanitize(this.button_text));
        __out.push('\n    <span class="fa fa-angle-down m-l-5"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-more-icon font-22"></span>\n  ');
      }
    
      __out.push('\n</button>\n');
    
      if (this.roleName !== "") {
        __out.push('\n');
        if (!this.disable_click) {
          __out.push('\n<div class="invoice-queue-dropdown ');
          if (this.permissions["canSettlementPay"] || this.permissions["canSettlementMarkPaid"]) {
            __out.push(__sanitize('settlement-queue-dropdown'));
          }
          __out.push(' dropdown-menu dropdown-menu-right" aria-labelledby="invoice-');
          __out.push(__sanitize(this.id));
          __out.push('-action-dropdown-btn">\n  <a class="dropdown-item font-14 send-message-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" href="/document/');
          __out.push(__sanitize(this.document_id));
          __out.push('/chat">\n    <i class="fa fa-comment-o"></i> <span>Send Message</span>\n  </a>\n  ');
          if (this.permissions["canHoldRecurring"]) {
            __out.push('\n  <button class="dropdown-item font-14 hold-recurring-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Hold Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canRescheduleRecurring"]) {
            __out.push('\n  <button class="dropdown-item font-14 reschedule-recurring-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-recurring"></i> <span>Reschedule Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canPayNow"]) {
            __out.push('\n  <button class="dropdown-item font-14 pay-recurring-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-check-card"></i> <span>Pay Now</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canDestroyRecurring"]) {
            __out.push('\n  <button class="dropdown-item font-14 destroy-recurring-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-trashcan"></i> <span>Delete Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canApprove"]) {
            __out.push('\n  <button class="dropdown-item font-14 approve-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-thumbs-up"></i> <span>Approve Invoice</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (!this.is_duplicate && this.permissions["canVerify"]) {
            __out.push('\n  <button class="dropdown-item font-14 verify-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-thumbs-up"></i> <span>Verify Data</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (!this.is_duplicate && this.permissions["canVerifyAndApprove"]) {
            __out.push('\n  <button class="dropdown-item font-14 verify-approve-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-thumbs-up"></i> <span>Verify and Approve</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.is_duplicate && this.permissions["canResolveDuplicate"]) {
            __out.push('\n    <button class="dropdown-item font-14 resolve-duplicate-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-duplicate"></i> <span>Resolve Duplicate</span>\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canUnApprove"]) {
            __out.push('\n  <button class="dropdown-item font-14 unapprove-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-thumbs-down"></i> <span>Retract Approval</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canPayViaDocyt"]) {
            __out.push('\n  <button class="dropdown-item font-14 pay-via-docyt-btn align-items-center display-flex justify-content-between ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <div>\n      <i class="icon icon-check-card"></i> <span>Pay by Docyt Check</span>\n    </div>\n    ');
            if (this.preferred_payment_method === 'Docyt Check') {
              __out.push('\n    <div class="invoice-status-panel font-12 font-semi-bold approval m-l-6">Preferred</div>\n    ');
            }
            __out.push('\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canPayViaAch"]) {
            __out.push('\n  <button class="dropdown-item font-14 pay-via-ach-btn display-flex align-items-center justify-content-between ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <div>\n      <i class="icon icon-ach"></i> <span>Pay by Docyt ACH</span>\n    </div>\n    ');
            if (this.preferred_payment_method === 'Docyt ACH') {
              __out.push('\n    <div class="invoice-status-panel font-12 font-semi-bold approval m-l-6">Preferred</div>\n    ');
            }
            __out.push('\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canSettlementPay"]) {
            __out.push('\n    <button class="dropdown-item font-14 pay-via-docyt-btn display-flex align-items-center justify-content-between ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <div>\n        <i class="icon icon-check-card"></i> <span>Generate Physical Docyt Check</span>\n      </div>\n      ');
            if (((ref = this.settlement_vendor) != null ? ref.preferred_payment_method : void 0) === 'Docyt Check') {
              __out.push('\n      <div class="invoice-status-panel font-12 font-semi-bold approval m-l-6">Preferred</div>\n      ');
            }
            __out.push('\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canSettlementPayByACH"]) {
            __out.push('\n    <button class="dropdown-item font-14 pay-via-ach-btn display-flex align-items-center justify-content-between ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <div>\n        <i class="icon icon-ach"></i> <span>Pay by Docyt ACH</span>\n      </div>\n      ');
            if (((ref1 = this.settlement_vendor) != null ? ref1.preferred_payment_method : void 0) === 'Docyt ACH') {
              __out.push('\n      <div class="invoice-status-panel font-12 font-semi-bold approval m-l-6">Preferred</div>\n      ');
            }
            __out.push('\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canSettlementMarkPaid"]) {
            __out.push('\n    <button class="dropdown-item font-14 mark-as-paid-by-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-paid"></i> <span>Mark as Paid with Manual Check</span>\n    </button>\n    <button class="dropdown-item font-14 mark-as-paid-by-bank-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-business"></i> <span>Mark as Paid with Bank Account</span>\n    </button>\n    <button class="dropdown-item font-14 mark-as-paid-by-settle-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-paid"></i> <span>Mark as Paid using Advanced Settlement</span>\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canViewCheck"]) {
            __out.push('\n  <a class="dropdown-item font-14 ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" href="/document/');
            __out.push(__sanitize(this.check_id));
            __out.push('">\n    <i class="icon icon-queue"></i> <span>View Check</span>\n  </a>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canMoveBack"]) {
            __out.push('\n  <button class="dropdown-item font-14 move-to-queue-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-queue"></i> <span>Move Invoice back to queue</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canMarkPaid"]) {
            __out.push('\n  <div class="font-14 dropdown-submenu">\n    <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('">\n      <i class="icon icon-paid"></i>\n      <span>Mark as paid</span>\n    </a>\n    <div class="dropdown-menu">\n      <button class="dropdown-item font-14 mark-as-paid-by-cash-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-cash"></i> <span>Cash</span>\n      </button>\n      <button class="dropdown-item font-14 mark-as-paid-by-card-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-credit-card"></i> <span>Credit Card</span>\n      </button>\n      <button class="dropdown-item font-14 mark-as-paid-by-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-paid"></i> <span>Manual Check</span>\n      </button>\n      <button class="dropdown-item font-14 mark-as-paid-by-bank-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-business"></i> <span>Bank Account(ACH / Debit Card)</span>\n      </button>\n      <button class="dropdown-item font-14 mark-as-paid-by-settle-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-paid"></i> <span>Advanced Settlement...</span>\n      </button>\n      <button class="dropdown-item font-14 mark-as-paid-by-vendor-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-vendor-credit"></i> <span>Vendor Credit</span>\n      </button>\n    </div>\n  </div>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canSplit"]) {
            __out.push('\n  <div class="font-14 dropdown-submenu">\n    <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('">\n      <i class="icon icon-split_icon"></i>\n      <span>Split Invoice</span>\n    </a>\n    <div class="dropdown-menu">\n      <button class="dropdown-item font-14 split-statement-invoices-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-split_to_invoices"></i> <span>Split Statement to Invoices</span>\n      </button>\n      <button class="dropdown-item font-14 split-invoice-multiple-payments-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-multiple_payments">\n          <i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i>\n          <i class="path5"></i><i class="path6"></i><i class="path7"></i>\n        </i>\n        <span>Split Invoice to Multiple Payments</span>\n      </button>\n      <button class="dropdown-item font-14 split-invoice-across-businesses-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-split_across_businesses"></i> <span>Split Invoice Across Businesses</span>\n      </button>\n      <button class="dropdown-item font-14 split-invoice-by-page-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-split_by_page"></i> <span>Split Invoice by Page</span>\n      </button>\n    </div>\n  </div>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canEdit"]) {
            __out.push('\n  <button class="dropdown-item font-14 edit-invoice-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="fa fa-pencil-square-o"></i> <span>Edit Invoice</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canViewVendor"]) {
            __out.push('\n  <button class="dropdown-item font-14 view-vendor-profile-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-Payee-profile"></i> <span>View Vendor Profile</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (!this.is_duplicate && this.permissions["canDonPay"]) {
            __out.push('\n  <button class="dropdown-item font-14 do-not-pay-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Do Not Pay</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canCancelPay"]) {
            __out.push('\n  <button class="dropdown-item font-14 cancel-payment-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Cancel Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canCancelScheduledPay"]) {
            __out.push('\n  <button class="dropdown-item font-14 destroy-recurring-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Cancel Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canVoidCheck"]) {
            __out.push('\n  <button class="dropdown-item font-14 void-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Cancel Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canCancelCheck"]) {
            __out.push('\n  <button class="dropdown-item font-14 cancel-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Cancel Payment</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canCancelDocytCheck"]) {
            __out.push('\n  <button class="dropdown-item font-14 cancel-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-stop"></i> <span>Cancel Docyt Check</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canDeleteCheck"]) {
            __out.push('\n  <button class="dropdown-item font-14 delete-check-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-trashcan"></i> <span>Delete Check</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canForward"]) {
            __out.push('\n    <button class="dropdown-item font-14 forward-btn pointer ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon-forward-o font-25"></i><span>Forward</span>\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (Docyt.checkDocytUserByEmail('docyt.com')) {
            __out.push('\n    <button class="dropdown-item font-14 report-invoice-btn" type="button">\n      <i class="fa fa-wrench"></i> <span>Report an Issue</span>\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.hasQboError) {
            __out.push('\n    <button class="dropdown-item font-14 re-sync-btn pointer ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon-qbo-on font-25"></i><span>Re-sync</span>\n    </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canDelete"]) {
            __out.push('\n  <div class="divider"></div>\n  <button class="dropdown-item font-14 destroy-invoice-btn delete-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n    <i class="icon icon-trashcan"></i> <span>');
            __out.push(__sanitize(this.isExpenseReport ? 'Reject Expense Report' : 'Delete Invoice'));
            __out.push('</span>\n  </button>\n  ');
          }
          __out.push('\n  ');
          if (this.permissions["canInTrash"]) {
            __out.push('\n    <button class="dropdown-item font-14 restore-invoice-btn pointer ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-restore font-24"></i><span>Restore</span>\n    </button>\n    <div class="divider"></div>\n    <button class="dropdown-item font-14 delete-invoice-btn delete-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n      <i class="icon icon-new-trash font-20"></i><span>Delete Permanently</span>\n    </button>\n  ');
          }
          __out.push('\n</div>\n');
        }
        __out.push('\n');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}