import React, { useCallback, useEffect, useRef } from 'react';

import { TID } from '@src/types/common';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

import EmployeeSettings from './settings/employee_settings';
import AccountSettings from './settings/settings';

interface IBankingAccountsSettingsContainerProps {
  businessId: TID,
  account?: IReconciliationPaymentAccount,
  paymentAccount: IPaymentAccount,
  statementDocytId?: string,
  onDone: () => void,
}

const BankingAccountsSettingsContainer = ({
  businessId,
  account,
  paymentAccount,
  statementDocytId,
  onDone,
}: IBankingAccountsSettingsContainerProps): JSX.Element => {
  // TODO: We didn't implement main table for accounts.
  // All logic of listRegionRef should be removed while implementing main table.
  const listRegionRef = useRef<HTMLElement | null>(null);
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  useEffect(() => {
    listRegionRef.current = document.getElementById('banking-accounts-settings-region');
    if (listRegionRef.current) { listRegionRef.current.classList.remove('hidden'); }
  }, []);

  const redirectToBankStatement = useCallback(() => {
    if (!statementDocytId || !account) return;

    let redirectUrl;

    if (statementDocytId === window.Docyt.Common.Constants.MARK_AS_NO_AVAILABLE) {
      const year = account.startImportDate
        ? new Date(account.startImportDate).getFullYear() : new Date().getFullYear();
      redirectUrl = bankStatementsDocRequestEnabled
        ? `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements/${account.id}?year=${year}`
        : `/businesses/${businessId}/banking_accounts/reviewed/${account.id}?year=${year}`;
    } else {
      redirectUrl = bankStatementsDocRequestEnabled
        ? `/businesses/${businessId}/reconciliation_center/month_end_documents/pending_bank_statements/${statementDocytId}`
        : `/businesses/${businessId}/banking_accounts/pending_review/${statementDocytId}`;
    }
    backboneNavigateToUrl(redirectUrl);
  }, [account, bankStatementsDocRequestEnabled, businessId, statementDocytId]);

  const handleCloseModal = useCallback(() => {
    if (listRegionRef.current) { listRegionRef.current.classList.add('hidden'); }

    onDone();
    redirectToBankStatement();
  }, [onDone, redirectToBankStatement]);
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <SideView.Provider>
          <div className="page-main width-100-percent">
            {
              account ? (
                <AccountSettings
                  account={ account }
                  onCancel={ handleCloseModal }
                  onDone={ handleCloseModal }
                />
              ) : (
                <EmployeeSettings
                  paymentAccount={ paymentAccount }
                  onCancel={ handleCloseModal }
                  onDone={ handleCloseModal }
                />
              )
            }
          </div>
          <SideView.Render />
        </SideView.Provider>
      </BusinessProvider>
    </Root>
  );
};

export default BankingAccountsSettingsContainer;
