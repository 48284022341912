import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { TDate } from '@src/types/common';
import { formatDate, MOMENT_SIMPLE_YEAR_DATE_FORMAT, parseApiDate } from '@src/utils/date_helpers';

import Form from '@src/components/ui/form';

import { confirmValidation, IVerifyBankFeedConfirmFormValues } from './schema';

interface IVerifyBankFeedConfirmFormProps {
  formId?: string,
  lastTransactionDate?: TDate,
  onSubmit: SubmitHandler<IVerifyBankFeedConfirmFormValues>,
}

const VerifyBankFeedConfirmForm = ({
  formId,
  lastTransactionDate,
  onSubmit,
}: IVerifyBankFeedConfirmFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IVerifyBankFeedConfirmFormValues>({
    resolver: yupResolver(confirmValidation),
  });

  const endDate = parseApiDate(lastTransactionDate)!;
  endDate.setDate(endDate.getDate() + 3);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <span className="display-flex m-b-10">
        Verifying the bank statement will move unselected transactions older than
        { ' ' }
        { formatDate(lastTransactionDate, MOMENT_SIMPLE_YEAR_DATE_FORMAT) }
        { ' ' }
        to the trash. Any unselected transactions between
        { ' ' }
        { formatDate(lastTransactionDate, MOMENT_SIMPLE_YEAR_DATE_FORMAT) }
        { ' and ' }
        { formatDate(endDate, MOMENT_SIMPLE_YEAR_DATE_FORMAT) }
        { ' ' }
        will carry over to the next statement period.
      </span>
      <span className="display-flex m-t-20 m-b-10">
        Type &quot;CONFIRM&quot; and click &quot;Continue&quot; to implement these changes.
      </span>
      <Form.TextField
        hideLabel
        className="width-100-percent"
        error={ errors.confirmName?.message }
        placeholder='Type "CONFIRM"'
        { ...register('confirmName') }
      />
    </Form>
  );
};

export {
  IVerifyBankFeedConfirmFormProps,
  IVerifyBankFeedConfirmFormValues,
  VerifyBankFeedConfirmForm as default,
};
