import {
  IBusinessVendor,
} from '@src/types/business_vendors';
import {
  IGetBusinessVendorsParams,
  IGetBusinessVendorsResponse,
  IGetBusinessVendorParams,
  IAddressBookParams,
  IUpdateAddressBookParams,
  IAddressAuthorizationParams,
  ILedgerAddressParams,
  ICreateDataAddressResponse,
  IRemoveAddressBookParams,
} from '@src/types/vendor';
import { trimNumKeys, camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiPost, apiPut, apiGet, apiDelete } from './helpers';

const getBusinessVendors = (
  params: IGetBusinessVendorsParams,
): Promise<IGetBusinessVendorsResponse> => {
  return apiGet(
    '/api/v1/business_vendors/with_multiple_businesses?exclude_individual_vendor=true',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.businessVendors,
    } as IGetBusinessVendorsResponse;
  });
};

const getBusinessVendor = (
  params: IGetBusinessVendorParams,
): Promise<IBusinessVendor> => {
  return apiGet(
    `/api/v1/business_vendors/${params.vendorId}`,
    underscoreKeys({ ...params, businessId: params.businessId }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data.vendor);
    return {
      ...cdata,
      docytId: (cdata.docytId || cdata.docytNetworkId),
    };
  });
};

const postAddressBook = (
  params: IAddressBookParams,
): Promise<ICreateDataAddressResponse> => {
  return apiPost(
    '/api/v1/business_vendors/add_address',
    trimNumKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateDataAddressResponse);
};

const putUpdateAddressBook = (
  params: IUpdateAddressBookParams,
): Promise<ICreateDataAddressResponse> => {
  return apiPut(
    '/api/v1/business_vendors/update_address',
    trimNumKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateDataAddressResponse);
};

const changeAddressAuthorization = (
  params: IAddressAuthorizationParams,
): Promise<ICreateDataAddressResponse> => {
  return apiPut(
    '/api/v1/addresses/authorize',
    trimNumKeys(params),
  );
};

const putLedgerAddress = (
  params: ILedgerAddressParams,
): Promise<ICreateDataAddressResponse> => {
  return apiPut(
    `/api/v1/business_vendors/${params.vendorId}`,
    trimNumKeys(params.data),
  ).then((data) => camelizeKeys(data) as ICreateDataAddressResponse);
};

const postRemoveAddressBook = (
  params: IRemoveAddressBookParams,
): Promise<ICreateDataAddressResponse> => {
  return apiDelete(
    '/api/v1/business_vendors/destroy_address/',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as ICreateDataAddressResponse);
};

export {
  ICreateDataAddressResponse,
  IAddressBookParams,
  IUpdateAddressBookParams,
  IAddressAuthorizationParams,
  ILedgerAddressParams,
  IRemoveAddressBookParams,
  postAddressBook,
  putUpdateAddressBook,
  putLedgerAddress,
  changeAddressAuthorization,
  getBusinessVendors,
  getBusinessVendor,
  postRemoveAddressBook,
};
