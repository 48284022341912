import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateAddressBook } from '@src/hooks/queries/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import AddressBookModalForm from '../form/edit_address_book_form';
import { IUpdateAddressBookInput } from '../form/schema';

import styles from './styles.module.scss';

interface IAddressDeleteProps extends IUseModalProps {
  vendor: IBusinessVendor;
  businessId: number;
  formId: string;
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  address: IAddress;
}

const AddressEditBookModal = ({
  formId,
  vendor,
  businessId,
  isOpen,
  onCancel,
  onDone,
  setVendorData,
  address,
}: IAddressDeleteProps) => {
  const queryClient = useQueryClient();
  const updateAddressBook = useUpdateAddressBook();

  const { mutate: updateAddress } = updateAddressBook;

  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleUpdateAddress = useCallback(
    (data: IUpdateAddressBookInput) => {
      setErrorMessage(undefined);
      updateAddress(
        {
          ...data,
          businessId,
          vendorId:  vendor.id,
          addressId: address.id,
        },
        {
          onSuccess: (response) => {
            queryClient.invalidateQueries(QueryKey.businessVendors);

            const newAddress = response.address;
            const updatedVendor = {
              ...vendor,
              addresses: [...(vendor.addresses || []), newAddress],
            };

            setVendorData(updatedVendor);
            setSuccessMessage('Address has been successfully updated.');
            onDone();
          },
          onError: handleError,
        },
      );
    },
    [updateAddress, businessId, vendor, handleError, queryClient, setVendorData, onDone, address],
  );

  return (
    <>
      {(updateAddressBook.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Cannot Authorize Address" onHidden={ () => setErrorMessage(undefined) } />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      )}
      <Modal
        className={ styles['modal-address-book'] }
        show={ isOpen }
        title={ (
          <p className={ styles['vendor-profile-modal-title'] }>
            Edit Address
          </p>
        ) }
      >
        <Modal.Body>
          <AddressBookModalForm
            address={ address }
            businessId={ businessId }
            formId={ formId }
            setIsFormValid={ setIsFormValid }
            vendorId={ vendor.id }
            onSubmit={ handleUpdateAddress }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            disabled={ !isFormValid }
            form={ formId }
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useAddressEditBookModal = makeUseModal(AddressEditBookModal);

export {
  IAddressDeleteProps,
  useAddressEditBookModal,
  AddressEditBookModal as default,
};
