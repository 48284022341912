import React from 'react';

import { IPaymentProcessor } from '@src/types/payment_processors';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { reconciledForPaymentProcessor } from '@src/components/revenue_center/utils';
import { SuccessIcon } from '@src/components/utils/icomoon';

interface IStatusIconProps {
  document: IRevenueServiceDocument,
  paymentProcessor: IPaymentProcessor
}

const StatusIcon = ({
  document,
  paymentProcessor,
}: IStatusIconProps) => {
  const isReconciled = reconciledForPaymentProcessor(document, paymentProcessor.id);
  if (isReconciled) {
    return <SuccessIcon fontSize={ 18 } />;
  }
  return null;
};

export default React.memo(StatusIcon);
