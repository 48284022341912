import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ILongArrowIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'down' | 'left' | 'right' | 'up',
}

const LongArrowIcon = ({
  variant,
  ...props
}: ILongArrowIconProps) => {
  return (
    <FaIcon
      icon={ `long-arrow-${variant}` }
      { ...props }
    />
  );
};

export default LongArrowIcon;
