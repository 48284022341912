import React from 'react';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';

import Table from '@src/components/ui/table';

import styles from '../styles.module.scss';

interface IfinancialInstitutionBankAccountCenterTableItemProps {
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount,
}

const financialInstitutionBankAccountCenterTableItem = ({
  financialInstitutionBankAccount,
}: IfinancialInstitutionBankAccountCenterTableItemProps) => {
  return (
    <Table.Row className={ styles['financial-banking-accounts-item'] }>
      <Table.Cell>
        { financialInstitutionBankAccount.name }
      </Table.Cell>
      <Table.Cell>
        { financialInstitutionBankAccount.last4 }
      </Table.Cell>
      <Table.Cell />
      <Table.Cell>
        {
          financialInstitutionBankAccount.bankAccountName
            ? (<span>{ financialInstitutionBankAccount.bankAccountName }</span>)
            : (<span className={ styles['no-bank-account-mapped'] }>No Banking Account mapped</span>)
        }
      </Table.Cell>
      <Table.Cell />
      <Table.Cell />
    </Table.Row>
  );
};

export default React.memo(financialInstitutionBankAccountCenterTableItem);
