import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  QBOSyncFilterField,
  VendorFilterField,
} from '@src/components/common_v2/filter_fields';
import Filter from '@src/components/ui_v2/filter';

import StarFlagFilterField from './fields/star_flag_filter_field';
import StatusFilterField from './fields/status_filter_field';

const InvoiceQueueFilter = () => {
  const business = useBusinessContext();

  return (
    <Filter.TableContainer>
      <StarFlagFilterField />
      <VendorFilterField
        businessId={ business.id }
        name="vendorId"
      />
      <StatusFilterField
        placeholder="Statuses"
      />
      <Filter.TextField
        label="Invoice Number"
        name="invoice_number"
        placeholder="Invoice#"
      />
      <Filter.DateField
        name="invoice_date"
      />
      <QBOSyncFilterField
        name="qbo_sync_status"
        placeholder="QBO Status"
      />
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
    </Filter.TableContainer>
  );
};

export default React.memo(InvoiceQueueFilter);
