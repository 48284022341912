import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface RevenueCaptureImportMenuProps {
  businessId: TID,
  integrationId: null | undefined | TID
}

const RevenueCaptureImportMenu = ({
  businessId,
  integrationId,
}: RevenueCaptureImportMenuProps) => {
  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.REVENUE_CENTER_MAPPING_TEMPLATE,
  );
  if (!featureQuery.data || (featureQuery.data && integrationId != null)) return null;
  return (
    <Button className={ styles['ml-auto'] } variant="primary">
      Import using Excel
    </Button>
  );
};

export default React.memo(RevenueCaptureImportMenu);
