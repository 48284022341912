module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      this.isDisabled = this.beforeBookDate ? 'disabled' : '';
    
      __out.push('\n<button class="btn dropdown-toggle font-15 font-bold ');
    
      __out.push(__sanitize(this.button_text ? 'receipt-amount-button' : 'header-icon-button'));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.button_class));
    
      __out.push('" type="button" id="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  ');
    
      if (this.button_text) {
        __out.push('\n    ');
        __out.push(__sanitize(this.button_text));
        __out.push('\n    <span class="fa fa-angle-down m-l-5"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-more-icon font-22"></span>\n  ');
      }
    
      __out.push('\n</button>\n');
    
      if (this.user_role && this.user_role.value !== "") {
        __out.push('\n<div class="receipt-queue-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="receipt-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n  <a class="dropdown-item font-14 send-message-receipt-btn pointer ');
        __out.push(__sanitize(this.isDisabled));
        __out.push('">\n    <i class="fa fa-comment-o"></i> <span>Send a Message</span>\n  </a>\n  ');
        if (this.is_duplicate && this.permissions["canVerify"]) {
          __out.push('\n  <button class="dropdown-item font-14 resolve-duplicate-receipt-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n    <i class="icon icon-duplicate"></i> <span>Resolve Duplicate</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (!this.is_duplicate && this.permissions["canVerify"]) {
          __out.push('\n  <button class="dropdown-item font-14 verify-receipt-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n    <i class="icon icon-thumbs-up"></i> <span>Approve Receipt Data</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canSplit"]) {
          __out.push('\n  <div class="font-14 dropdown-submenu">\n    <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('">\n      <i class="icon icon-split_icon"></i>\n      <span>Split Receipt</span>\n    </a>\n    <div class="dropdown-menu">\n      ');
          if (this.canSplitAcrossBusiness) {
            __out.push('\n      <button class="dropdown-item font-14 split-receipt-across-businesses-btn ');
            __out.push(__sanitize(this.isDisabled));
            __out.push('" type="button">\n        <i class="icon icon-split_across_businesses"></i> <span>Split Receipt Across Businesses</span>\n      </button>\n      ');
          }
          __out.push('\n      <button class="dropdown-item font-14 split-receipt-by-page-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n        <i class="icon icon-split_by_page"></i> <span>Split Receipt by Page</span>\n      </button>\n      <button class="dropdown-item font-14 split-receipts-by-amount-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n        <i class="icon icon-multiple_payments">\n          <i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i>\n          <i class="path5"></i><i class="path6"></i><i class="path7"></i>\n        </i>\n        <span>Split Receipts by Amount</span>\n      </button>\n    </div>\n  </div>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canForward"]) {
          __out.push('\n    <button class="dropdown-item font-14 pointer forward-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n      <i class="icon-forward-o font-25"></i><span>Forward</span>\n    </button>\n  ');
        }
        __out.push('\n  ');
        if (Docyt.checkDocytUserByEmail('docyt.com')) {
          __out.push('\n    <button class="dropdown-item font-14 report-receipt-btn" type="button">\n      <i class="fa fa-wrench"></i> <span>Report an Issue</span>\n    </button>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canEdit"]) {
          __out.push('\n  <button class="dropdown-item font-14 edit-receipt-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n    <i class="fa fa-pencil-square-o"></i> <span>Edit Receipt</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canExport"]) {
          __out.push('\n  <button class="dropdown-item font-14 export-receipt-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n    <i class="icon icon-download"></i> <span>Export</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (this.hasQboError) {
          __out.push('\n    <button class="dropdown-item font-14 re-sync-btn pointer ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n      <i class="icon-qbo-on font-25"></i><span>Re-sync</span>\n    </button>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canDelete"]) {
          __out.push('\n  <div class="divider"></div>\n  <button class="dropdown-item font-14 destroy-receipt-btn delete-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n    <i class="icon icon-trashcan"></i> <span>Delete Receipt</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (this.permissions["canInTrash"]) {
          __out.push('\n    <button class="dropdown-item font-14 restore-receipt-btn pointer ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n      <i class="icon icon-restore font-24"></i><span>Restore</span>\n    </button>\n    <div class="divider"></div>\n    <button class="dropdown-item font-14 delete-receipt-btn delete-btn ');
          __out.push(__sanitize(this.isDisabled));
          __out.push('" type="button">\n      <i class="icon icon-new-trash font-20"></i><span>Delete Permanently</span>\n    </button>\n  ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}