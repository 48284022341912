import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { getFormattedInvoiceDueDate } from '../../utils';

interface IInvoiceDueDateProps {
  document: IAccountsPayableServiceDocument,
}

const InvoiceDueDate = ({
  document,
}: IInvoiceDueDateProps) => {
  const formattedDate = getFormattedInvoiceDueDate(document.invoiceDueDate);
  if (!formattedDate.dueDate) return null;

  return (
    <>
      <div className={
        `p-l-10 text-nowrap ${formattedDate.dueDated ? 'in-red-400' : ''}`
      }
      >
        { formattedDate.dueDate }
      </div>
      {
        formattedDate.dueDated && (
          <div className="font-11 in-white m-l-10 font-bold overdue-badge display-inline-block">
            Overdue
          </div>
        )
      }
    </>
  );
};

export default React.memo(InvoiceDueDate);
