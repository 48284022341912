module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, k, len, len1, len2, payment_processor, rc_payment_account, ref, ref1, ref2;
    
      this.name_suffix = this.isMarking ? '' : '*';
    
      __out.push('\n');
    
      if (!this.isChatMode) {
        __out.push('\n<div class="document-fields">\n    ');
        if (this.hasCategorizationServiceForEmployee || this.hasReconciliationServiceForEmployee) {
          __out.push('\n    <div>\n        <div class="document-field-item document-field-name">Transaction Type</div>\n        ');
          if (parseFloat(this.amount) < 0) {
            __out.push('\n        <div class="document-field-item document-field-value">\n            <select class="transaction-type-select selectpicker" data-dropup-auto="false">\n                <option class="transaction-type-select-option" value="expense" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'expense' || this.transaction_type === "check") {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Expense</option>\n                <option class="transaction-type-select-option" value="transfer" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'transfer') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Transfer</option>\n                <option class="transaction-type-select-option" value="payroll" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'payroll') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Payroll Charges</option>\n                <option class="transaction-type-select-option" value="chargeback" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'chargeback') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Chargeback</option>\n                <option class="transaction-type-select-option" value="revenue_reversal" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'revenue_reversal') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Revenue Reversal</option>\n                <option class="transaction-type-select-option" value="customer_claims" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'customer_claims') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Customer Claims</option>\n                <option class="transaction-type-select-option" value="equity_withdrawal" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'equity_withdrawal') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Equity Withdrawal</option>\n                <option class="transaction-type-select-option" value="asset_purchase" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'asset_purchase') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Asset Withdrawal</option>\n                <option class="transaction-type-select-option" value="liability_payments" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'liability_payments') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Liability Payments</option>\n                <option class="transaction-type-select-option" value="loan_payments" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'loan_payments') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Loan Payments</option>\n            </select>\n        </div>\n        ');
          } else {
            __out.push('\n        <div class="document-field-item document-field-value">\n            <select class="transaction-type-select selectpicker" data-dropup-auto="false">\n                <option class="transaction-type-select-option" value="revenue" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'revenue' || this.transaction_type === "check") {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Income</option>\n                <option class="transaction-type-select-option" value="refund" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'refund') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Vendor Refunds</option>\n                <option class="transaction-type-select-option" value="transfer" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'transfer') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Transfer</option>\n                <option class="transaction-type-select-option" value="payroll" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'payroll') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Payroll Charges</option>\n                <option class="transaction-type-select-option" value="other_income" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'other_income') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Other Income</option>\n                <option class="transaction-type-select-option" value="loan_proceeds" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'loan_proceeds') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Loan Proceeds</option>\n                <option class="transaction-type-select-option" value="equity_investment" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'equity_investment') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Equity Investment</option>\n                <option class="transaction-type-select-option" value="other_receivables" ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' ');
            if (this.transaction_type === 'other_receivables') {
              __out.push(__sanitize('selected'));
            }
            __out.push('>Other Receivables</option>\n            </select>\n        </div>\n        ');
          }
          __out.push('\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Vendor');
          __out.push(__sanitize(this.name_suffix));
          __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <div id="transactionVendorsRegion" class="input-vendor"></div>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    <div class="withdraw-type field-input-container">\n        <div class="document-field-item document-field-name">\n            ');
        if (this.transaction_type === 'equity_investment') {
          __out.push(__sanitize('Investment'));
        }
        __out.push('\n            ');
        if (this.transaction_type === 'loan_proceeds' || this.transaction_type === 'other_receivables') {
          __out.push(__sanitize('Proceeds'));
        }
        __out.push('\n            ');
        if (this.transaction_type === 'equity_withdrawal' || this.transaction_type === 'asset_purchase') {
          __out.push(__sanitize('Withdrawal'));
        }
        __out.push('\n            ');
        if (this.transaction_type === 'liability_payments' || this.transaction_type === 'loan_payments') {
          __out.push(__sanitize('Payment'));
        }
        __out.push('\n            ');
        __out.push(__sanitize(' Type'));
        __out.push(__sanitize(this.name_suffix));
        __out.push('\n        </div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <select class="sub-type-select selectpicker" data-dropup-auto="false" title="');
        __out.push(__sanitize(this.subTypeTitle));
        __out.push('">\n                ');
        if (this.transaction_type === 'equity_investment' || this.transaction_type === 'loan_proceeds' || this.transaction_type === 'other_receivables') {
          __out.push('\n                <option value="ach_transfer_from" ');
          if (this.sub_type === 'ach_transfer_from') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>ACH Transfer from another bank</option>\n                <option value="cash_check_deposit" ');
          if (this.sub_type === 'cash_check_deposit') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Cash / Check Deposits</option>\n                ');
        }
        __out.push('\n                ');
        if (this.transaction_type === 'equity_investment' || this.transaction_type === 'other_receivables') {
          __out.push('\n                <option value="vendor_refund" ');
          if (this.sub_type === 'vendor_refund') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Vendor refund for personal expense</option>\n                ');
        }
        __out.push('\n                ');
        if (this.transaction_type === 'equity_withdrawal') {
          __out.push('\n                <option value="ach_transfer_to" ');
          if (this.sub_type === 'ach_transfer_to') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>ACH Transfer to another bank</option>\n                <option value="payment_credit_card" ');
          if (this.sub_type === 'payment_credit_card') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Payment to personal credit card</option>\n                <option value="payment_vendor" ');
          if (this.sub_type === 'payment_vendor') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Payment to Vendor for personal expense</option>\n                <option value="cash_check_withdraw" ');
          if (this.sub_type === 'cash_check_withdraw') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Cash / Check withdraw</option>\n                ');
        }
        __out.push('\n                ');
        if (this.transaction_type === 'asset_purchase') {
          __out.push('\n                <option value="ach_transfer_to" ');
          if (this.sub_type === 'ach_transfer_to') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>ACH Transfer to another bank</option>\n                <option value="payment_vendor" ');
          if (this.sub_type === 'payment_vendor') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Payment to Vendor for personal expense</option>\n                <option value="purchase_cash_check" ');
          if (this.sub_type === 'purchase_cash_check') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Purchase using Cash / Check</option>\n                ');
        }
        __out.push('\n                ');
        if (this.transaction_type === 'liability_payments' || this.transaction_type === 'loan_payments') {
          __out.push('\n                <option value="ach_transfer_to" ');
          if (this.sub_type === 'ach_transfer_to') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>ACH Transfer to another bank</option>\n                <option value="payment_cash_check" ');
          if (this.sub_type === 'payment_cash_check') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Payment using Cash / Check</option>\n                ');
        }
        __out.push('\n            </select>\n        </div>\n    </div>\n    ');
        if (this.hasCategorizationServiceForEmployee || this.hasReconciliationServiceForEmployee) {
          __out.push('\n    ');
          if (['expense', 'check', 'refund', 'revenue', 'other_income'].includes(this.transaction_type)) {
            __out.push('\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Category');
            __out.push(__sanitize(this.name_suffix));
            __out.push('</div>\n        <div class="document-field-item category-field-value document-field-value">\n            ');
            if (!this.serviceDocumentSplits || this.serviceDocumentSplits.length < 1) {
              __out.push('\n            <div>');
              __out.push(__sanitize(this.category));
              __out.push('</div>\n            ');
            }
            __out.push('\n            <a class="pointer line-height-26 ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('btn-action disabled'));
            }
            __out.push('" id="split-account-dropdown-btn">\n                ');
            if (this.serviceDocumentSplits && this.serviceDocumentSplits.length >= 1) {
              __out.push('\n                ');
              __out.push(__sanitize(this.serviceDocumentSplits.length));
              __out.push(' Splits\n                ');
            } else {
              __out.push('\n                ');
              __out.push(__sanitize(this.category ? 'Edit' : 'Select Category'));
              __out.push('\n                ');
            }
            __out.push('\n            </a>\n        </div>\n    </div>\n    ');
          } else {
            __out.push('\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Category');
            __out.push(__sanitize(this.name_suffix));
            __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <input class="input-category typeahead form-control" type="text" value="" placeholder="Enter Category Name or Code" />\n        </div>\n    </div>\n    ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    ');
        if (this.transaction_type === 'loan_payments') {
          __out.push('\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Principal Category');
          __out.push(__sanitize(this.name_suffix));
          __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <input class="input-principal-category typeahead form-control" type="text" value="" placeholder="Enter Category Name or Code" />\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Interest Department</div>\n        <div class="document-field-item document-field-value template-typeahead-wrapper">\n            <input class="input-interest-class typeahead form-control" type="text" value="');
          __out.push(__sanitize(this.interest_accounting_class ? this.interest_accounting_class.name : ''));
          __out.push('" placeholder="Enter Department" />\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Interest Category');
          __out.push(__sanitize(this.name_suffix));
          __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <input class="input-interest-category typeahead form-control" type="text" value="" placeholder="Enter Category Name or Code" />\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Principal Amount');
          __out.push(__sanitize(this.name_suffix));
          __out.push('</div>\n        <div class="document-field-item document-field-value">\n            <input class="input-principal-amount input-loan-amount form-control" type="text" placeholder="Enter Amount" value="" />\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Interest Amount');
          __out.push(__sanitize(this.name_suffix));
          __out.push('</div>\n        <div class="document-field-item document-field-value">\n            <input class="input-interest-amount input-loan-amount form-control" type="text" placeholder="Enter Amount" value="" />\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">\n            Total Amount\n            <span class="total-amount-tooltip total-tooltip-js font-18 m-l-8 icon icon-unverified" data-toggle="tooltip" data-placement="bottom" title="Total amount is sum of Principal Amount and Interest Amount. In order to verify a transaction Total Amount should match Transaction Amount."><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n        </div>\n        <div class="document-field-item document-field-value">\n            <span class="label-total-amount form-control"></span>\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Txn Amount</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <span class="label-txn-amount form-control">');
          __out.push(__sanitize(parseFloat(-this.amount).toFixed(2)));
          __out.push('</span>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">Payment Processor');
        __out.push(__sanitize(this.name_suffix));
        __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <select id = "payment-processor-select" class="payment-processor-select selectpicker" data-dropup-auto="false" title="Select Payment Processor">\n                ');
        ref = this.payment_processors;
        for (i = 0, len = ref.length; i < len; i++) {
          payment_processor = ref[i];
          __out.push('\n                <option value="');
          __out.push(__sanitize(payment_processor.id));
          __out.push('" ');
          if (this.user_payment_processor_id === payment_processor.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(payment_processor.name));
          __out.push('</option>\n                ');
        }
        __out.push('\n            </select>\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">From Account');
        __out.push(__sanitize(this.name_suffix));
        __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <select class="from-account-select selectpicker" data-dropup-auto="false" title="Select Payment Account">\n                ');
        ref1 = this.rc_payment_accounts;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          rc_payment_account = ref1[j];
          __out.push('\n                <option value="');
          __out.push(__sanitize(rc_payment_account.id));
          __out.push('" ');
          if (this.from_account_id === rc_payment_account.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push(' ');
          if (this.to_account_id === rc_payment_account.id) {
            __out.push(__sanitize('disabled'));
          }
          __out.push('>');
          __out.push(__sanitize(this.rcPaymentAccountName(rc_payment_account)));
          __out.push('</option>\n                ');
        }
        __out.push('\n            </select>\n        </div>\n    </div>\n    <div class="field-input-container">\n        <div class="document-field-item document-field-name">To Account');
        __out.push(__sanitize(this.name_suffix));
        __out.push('</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n            <select class="to-account-select selectpicker" data-dropup-auto="false" title="Select Payment Account">\n                ');
        ref2 = this.rc_payment_accounts;
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          rc_payment_account = ref2[k];
          __out.push('\n                <option value="');
          __out.push(__sanitize(rc_payment_account.id));
          __out.push('" ');
          if (this.to_account_id === rc_payment_account.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push(' ');
          if (this.from_account_id === rc_payment_account.id) {
            __out.push(__sanitize('disabled'));
          }
          __out.push('>');
          __out.push(__sanitize(this.rcPaymentAccountName(rc_payment_account)));
          __out.push('</option>\n                ');
        }
        __out.push('\n            </select>\n        </div>\n    </div>\n    ');
        if (this.hasCategorizationServiceForEmployee || this.hasReconciliationServiceForEmployee) {
          __out.push('\n        <div class="field-input-container">\n            <div class="document-switch-container">\n                <label class="document-switch">\n                    <input type="checkbox" name="do-not-learn" class="do-not-learn pointer" value="');
          __out.push(__sanitize(this.do_not_learn));
          __out.push('" ');
          if (this.do_not_learn) {
            __out.push('checked');
          }
          __out.push(' ');
          if (!this.hasReconciliationServiceForEmployee) {
            __out.push(__sanitize('disabled'));
          }
          __out.push(' >\n                    <span class="slider round"></span>\n                </label>\n                <label for="do-not-learn" class="do-not-learn-label display-flex align-items-center">\n                    Docyt AI will learn the above categorization details\n                </label>\n            </div>\n        </div>\n        ');
          if (this.noDocumentOptionVisible) {
            __out.push('\n        <div class="field-input-container">\n            <div class="checkbox checkbox-primary no-document-wrap">\n                <div>\n                    <input type="checkbox" name="no-document-required" class="no-document-required-checkbox pointer" value="');
            __out.push(__sanitize(this.no_document_required));
            __out.push('" ');
            if (this.no_document_required) {
              __out.push('checked');
            }
            __out.push(' ');
            if (!this.hasReconciliationServiceForEmployee) {
              __out.push(__sanitize('disabled'));
            }
            __out.push(' >\n                    <label for="no-document-required-checkbox" class="document-checkbox-label">No document needed</label>\n                </div>\n                <a href="/businesses/');
            __out.push(__sanitize(this.business.id));
            __out.push('/reconciliation_center/settings">Docyt AI Settings</a>\n                \n            </div>\n            <div class="document-note-container m-t-20 m-b-10 hidden">\n                <textarea class="document-note-area form-control" placeholder="Add note">');
            __out.push(__sanitize(this.documentNote));
            __out.push('</textarea>\n            </div>\n        </div>\n        ');
          }
          __out.push('\n        <div class="field-input-container">\n            <div class="checkbox checkbox-primary m-b-0">\n                <input type="checkbox" name="no-push-qbo" class="no-push-qbo pointer" value="');
          __out.push(__sanitize(this.no_push_qbo));
          __out.push('" ');
          if (this.no_push_qbo) {
            __out.push('checked');
          }
          __out.push(' ');
          if (!this.hasReconciliationServiceForEmployee) {
            __out.push(__sanitize('disabled'));
          }
          __out.push(' >\n                <label for="no-push-qbo" class="no-push-qbo-label">Do not push the transaction to ledger</label>\n            </div>\n        </div>\n    ');
        }
        __out.push('\n    ');
        if (this.hasCategorizationServiceForEmployee) {
          __out.push('\n        <div id="rule-matched-region"></div>\n    ');
        }
        __out.push('\n    ');
        if (this.hasCategorizationServiceForEmployee) {
          __out.push('\n        <div id="past-transactions-region" class="past-transactions-region"></div>\n    ');
        }
        __out.push('\n    <div id="transaction-matched-items-region" class="transaction-matched-items-region"></div>\n    <div id="unverified-related-transaction-document-region" class="related-transaction-document-region m-t-10"></div>\n    <div id="unverified-matched-document-region" class="matched-document-region"></div>\n    <div id="accrual-entries-transaction-region"></div>\n</div>\n');
      }
    
      __out.push('\n\n\n\n<div class="m-t-10 p-t-5 p-b-10 transaction-right-side-footer bg-white">\n    ');
    
      if (!this.isMarking) {
        __out.push('\n        ');
        if (this.isMultipleSelected) {
          __out.push('\n            <button class="btn btn-block btn-blue btn-save hidden">Save</button>\n        ');
        }
        __out.push('\n        <button class="btn btn-block btn-blue btn-verify ');
        __out.push(__sanitize(this.checkVerify() ? 'verify-transaction-btn' : 'disabled'));
        __out.push('">Categorize Transaction</button>\n    ');
      } else {
        __out.push('\n        <button class="btn btn-block btn-blue btn-verify verify-transaction-btn">');
        __out.push(__sanitize(this.flagged_state === 'open' ? 'Mark as Reviewed - Review Next' : 'Mark as Reopened'));
        __out.push('</button>\n    ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}