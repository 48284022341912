import React from 'react';

import { ICombinedServiceDocument } from '@src/types/combined_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { ChatIcon } from '@src/components/utils/icomoon';

type TMatchDocumentsCombinedListActionsProps = {
  document: ICombinedServiceDocument,
  disabledRow: boolean
}

const MatchDocumentsCombinedListActions = ({
  document,
  disabledRow
}: TMatchDocumentsCombinedListActionsProps) => {
  return (
    <>
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}/chat` }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
        disabled={ disabledRow }
      />
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}` }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Detail"
      />
    </>
  );
};

export default MatchDocumentsCombinedListActions;
