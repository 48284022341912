import React, { useEffect, useMemo } from 'react';

import { useGetBusinessLaborReportMappedByDepartment } from '@src/hooks/queries/report_service/report_datas';

import QueryStatus from '@src/components/utils/query_status';

import DepartmentItem from './department_item';
import { IEditMappingLaborProps } from '../hooks';

interface IBusinessReportsEditMappingAcceptedDepartmentProps extends IEditMappingLaborProps {
  setDepartment: React.Dispatch<
    React.SetStateAction<
      {
        dimensionCode: string;
        dimensionLabel: string;
      }[]
    >
  >;
}

const BusinessReportsEditMappingAcceptedDepartment = ({
  data,
  parentId,
  childId,
  setDepartment
}: IBusinessReportsEditMappingAcceptedDepartmentProps) => {
  const departmentQuery = useGetBusinessLaborReportMappedByDepartment(
    {
      reportId: data.report?.id as string,
      itemId: childId ? childId as string : parentId as string
    }
  );
  const departments = useMemo(() => departmentQuery.data || [], [departmentQuery.data]);

  useEffect(() => {
    setDepartment(departments);
  }, [setDepartment, departments]);

  return (
    <>
      { departments.map((department: { dimensionCode: string; dimensionLabel: string; }) => (
        <DepartmentItem
          key={ department.dimensionCode }
          childId={ childId }
          data={ data }
          dimensionLabel={ department.dimensionLabel }
          parentId={ parentId }
        />
      )) }
      <QueryStatus query={ departmentQuery } />
    </>
  );
};

export default React.memo(BusinessReportsEditMappingAcceptedDepartment);
