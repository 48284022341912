import React, { useCallback } from 'react';

import { useDestroyTransactionServiceDocument,
  useMoveToTrashTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import useDeleteTransactionErrorModalModal from '../delete_transaction_error_modal';
import useDeleteTransactionModal from './delete_restore_action/action_modal';

interface ITransactionDeleteActionProps {
  transaction: ITransactionServiceDocument,
  isTrash?: boolean,
}

const TransactionDeleteAction = ({
  transaction,
  isTrash,
}: ITransactionDeleteActionProps): JSX.Element | null => {
  const destroy = useDestroyTransactionServiceDocument();
  const moveToTrash = useMoveToTrashTransactionServiceDocument();

  const { mutate: destroyTransactionServiceDocument } = destroy;
  const { mutate: moveToTrashTransactionServiceDocument } = moveToTrash;

  const deleteTransactionErrorModal = useDeleteTransactionErrorModalModal();

  const handleError = useCallback((response) => {
    if (response?.response?.data?.errors[0]
      === window.Docyt.Common.Constants.Messages.TRANSACTION_DELETE_ERROR_MSG) {
      deleteTransactionErrorModal.open();
    }
  }, [deleteTransactionErrorModal]);

  const handleConfirm = useCallback(() => {
    if (isTrash) {
      destroyTransactionServiceDocument({ id: transaction.id });
    } else {
      moveToTrashTransactionServiceDocument(
        { value: 'true', document_ids: [transaction.documentId] },
        { onError: handleError },
      );
    }
  }, [destroyTransactionServiceDocument,
    moveToTrashTransactionServiceDocument,
    handleError,
    isTrash,
    transaction.documentId,
    transaction.id]);

  const destroyModal = useDeleteTransactionModal({
    onDone: handleConfirm,
  });

  if (!isTrash && transaction.parentBankTransactionId) return null;

  return (
    <>
      <MutationStatus
        mutation={ isTrash ? destroy : moveToTrash }
        successMessage={ isTrash ? 'Deleted successfully' : 'Move to Trash successfully' }
      />
      <deleteTransactionErrorModal.Component
        { ...deleteTransactionErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.TRANSACTION_DELETE_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANKING_STATEMENT_VERIFIED_IN_MSG }
      />
      <destroyModal.Component
        { ...destroyModal.props }
        actionType={ isTrash ? 'delete' : 'moveToTrash' }
        transaction={ transaction }
      />
      <ActionsDropdown.DangerAction
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        name="Delete Transaction"
        onClick={ destroyModal.open }
      />
    </>
  );
};

export default TransactionDeleteAction;
