import { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetBusinessVendorAddress } from '@src/hooks/queries/vendor_address_book';
import { useSorting } from '@src/hooks/url_params';
import { IAddress } from '@src/types/address';
import {
  IBusinessVendor,
  TSelectAddressVendorSortColumn,
} from '@src/types/business_vendors';
import { TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface ISelectMergeableVendorParams {
  businessVendor: IBusinessVendor,
}

interface ISelectMergeableVendorCollection {
  query: UseQueryResult<IBusinessVendor, Error>;
  records: IAddress[];
  section: TSection;
  sorting: ISorting<TSelectAddressVendorSortColumn>;
  selectedRecord?: IAddress;
  refetch: () => void;
}

const defaultSorting: ISortingParams<TSelectAddressVendorSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const useSelectMergeableVendorCollection = ({
  businessVendor,
}: ISelectMergeableVendorParams): ISelectMergeableVendorCollection => {
  const section = useMemo(() => ({
    section: Section.SelectMergeableVendor,
  }), []);

  const sorting = useSorting<TSelectAddressVendorSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetBusinessVendorAddress({
    businessId: businessVendor.businessId,
    vendorId:   businessVendor.id,
    ...sorting.data,
  });

  const records = useMemo(() => {
    return query.data?.addresses || [];
  }, [query.data]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecord = useMemo(() => {
    if (selectedIds.length === 0) return undefined;
    return records.find((r) => r.id === selectedIds[0]);
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecord,
    refetch: query.refetch,
  };
};

export {
  ISelectMergeableVendorCollection,
  useSelectMergeableVendorCollection,
};
