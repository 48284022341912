module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive check-registers-list invoices-table-wrapper invoice-queue-list service-detail-view">\n    <table class="table">\n    <colgroup span="10">\n      <col width="143px"></col>\n      <col width="200px"></col>\n      <col width="180px"></col>\n      <col></col>\n      <col></col>\n      <col></col>\n      <col></col>\n      <col></col>\n      <col width="50px"></col>\n      <col width="50px"></col>\n    </colgroup>\n    <thead>\n        <tr>\n            <th width="2%"></th>\n            <th class="sortable font-14 font-bold sorted-desc" data-orderby="check_num" data-orderdir="ASC">Check #</th>\n            <th class="sortable font-14 font-bold" data-orderby="vendor" data-orderdir="ASC">Payee</th>\n            <th class="sortable font-14 font-bold" data-orderby="account_name" data-orderdir="ASC">Account</th>\n            <th class="sortable font-14 font-bold" data-orderby="check_date" data-orderdir="ASC">Check Date</th>\n            <th class="sortable font-14 font-bold" data-orderby="outstanding" data-orderdir="ASC">Outstanding</th>\n            <th class="sortable font-14 font-bold" data-orderby="memo" data-orderdir="ASC">Check Memo</th>\n            <th class="sortable font-14 font-bold" data-orderby="status" data-orderdir="ASC">Status</th>\n            <th class="sortable font-14 font-bold amount-header" data-orderby="amount" data-orderdir="ASC">Amount</th>\n            <th width="50px"></th>\n            <th width="50px"></th>\n            <th width="50px"></th>\n        </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}