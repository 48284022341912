import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateDataAddressResponse,
  IAddressBookParams,
  IUpdateAddressBookParams,
  IAddressAuthorizationParams,
  ILedgerAddressParams,
  IRemoveAddressBookParams,
  getBusinessVendor,
  postAddressBook,
  postRemoveAddressBook,
  putUpdateAddressBook,
  putLedgerAddress,
  changeAddressAuthorization,
} from '@src/requests/vendor_address_book';
import { IBusinessVendor } from '@src/types/business_vendors';

const useAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IAddressBookParams>(
    postAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useUpdateAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IUpdateAddressBookParams>(
    putUpdateAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useAddressAuthorization = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IAddressAuthorizationParams>(
    changeAddressAuthorization,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useLedgerAddress = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, ILedgerAddressParams>(
    putLedgerAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useRemoveAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IRemoveAddressBookParams>(
    postRemoveAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useGetBusinessVendorAddress = (vendorId: any) => {
  return useQuery<IBusinessVendor, Error>(
    [QueryKey.businessVendors, vendorId],
    () => getBusinessVendor(vendorId),
  );
};

export {
  useAddressBook,
  useUpdateAddressBook,
  useAddressAuthorization,
  useLedgerAddress,
  useGetBusinessVendorAddress,
  useRemoveAddressBook,
};
