module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var account, group, i, j, len, len1, ref, ref1;
    
      __out.push('<ul class="nav nav-sidebar m-t-8">\n  <li>\n    <a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/reconciliation_center/ledger_syncing_centre" class="pointer submenu-title">\n      ');
    
      if (this.ledger_sync_errors_count === 0) {
        __out.push('\n      <i class="icon icon-success font-20">\n        <span class="path1"></span><span class="path2"></span>\n      </i>\n      ');
      } else {
        __out.push('\n      <span class="nav-item-number ledger-sync-errors-count">');
        __out.push(__sanitize(this.ledger_sync_errors_count));
        __out.push('</span>\n      ');
      }
    
      __out.push('\n      <span class="nav-item-text">Ledger Sync Errors</span> \n      <!-- <span><i class="icon-qbo-error service-icon-font" /></span> -->\n      <span class="icon icon-more-info font-16"><span class="path1"></span></span>\n    </a>\n  </li>\n</ul>\n\n');
    
      ref = this.payment_accounts;
      for (i = 0, len = ref.length; i < len; i++) {
        group = ref[i];
        __out.push('\n  <hr class="m-t-0 m-b-0" />\n  <ul class="nav nav-sidebar ');
        __out.push(__sanitize(group.name !== 'Asset' || group.name === 'Asset' && this.showPaymentProcessorReconciliation ? '' : 'hidden'));
        __out.push('">\n    ');
        ref1 = group.accounts;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          account = ref1[j];
          __out.push('\n      <li class="');
          if (this.selected_payment_account.id === account.id) {
            __out.push(__sanitize('active'));
          }
          __out.push('">\n        <a href="" class="payment-account-select" data-id="');
          __out.push(__sanitize(account.id));
          __out.push('">\n          ');
          if (this.statuses[account.id] === 'reconciled') {
            __out.push('\n            <i class="icon icon-success font-20">\n              <span class="path1"></span><span class="path2"></span>\n            </i>\n          ');
          } else if (this.statuses[account.id] === 'acknowledged' || this.statuses[account.id] === 'in_progress') {
            __out.push('\n            <i class="icon icon-progress-arrow-orange font-20">\n              <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n            </i>\n          ');
          } else if (this.statuses[account.id] === 'broken') {
            __out.push('  \n            <span class="icon-warning-triangle font-20 warning-color"></span> \n          ');
          }
          __out.push('\n          <span class="nav-item-text">');
          __out.push(__sanitize(account.name));
          __out.push('</span>\n          <span class="nav-item-type">');
          __out.push(__sanitize(group.name));
          __out.push('</span>\n        </a>\n      </li>\n    ');
        }
        __out.push('\n  </ul>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}