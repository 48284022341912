import React from 'react';

import castArray from 'lodash/castArray';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Text from '@src/components/ui/text';
import Tooltip from '@src/components/ui_v2/tooltip';

import Breadcrumb from './breadcrumb';
import Help from './title_help';
import { IBreadcrumb } from './types';

import styles from './styles.module.scss';

interface IDetailsPageHeaderProps extends IUIStyleProps {
  breadcrumbs?: IBreadcrumb | IBreadcrumb[],
  children?: React.ReactNode,
  divider?: 'horizontal' | 'vertical' | 'both',
  help?: React.ReactNode,
  title: React.ReactNode,
  subtitle?: React.ReactNode,
}

const DetailsPageHeader = ({
  breadcrumbs,
  children,
  help,
  title,
  subtitle,
  divider = 'vertical',
  ...props
}: IDetailsPageHeaderProps): JSX.Element => {
  const [classes] = uiStyleProps(
    styles['details-region-header'],
    props,
    styles[`details-region-divider-${divider}`],
  );
  const crumbs = breadcrumbs && castArray(breadcrumbs);

  return (
    <div className={ classes }>
      <div className={ styles.title }>
        <Text as="h2">
          { crumbs && crumbs.map((crumb, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Breadcrumb key={ i } breadcrumb={ crumb } />
          )) }
          <Tooltip.Hover
            content={ title }
            style={ { cursor: 'pointer' } }
          >
            { title }
          </Tooltip.Hover>
          { subtitle && <Text as="p" className={ styles.subtitle }>{ subtitle }</Text> }
        </Text>
        { help && (
          <>
            { ' ' }
            <Help text={ help } />
          </>
        ) }
      </div>
      { children && (
        <div className={ styles.actions }>
          { children }
        </div>
      ) }
    </div>
  );
};

export default React.memo(DetailsPageHeader);
