module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table bg-white" style="table-layout: fixed;">\n  <colgroup span="8">\n    <col></col>\n    <col width="93"></col>\n    <col></col>\n    ');
    
      if (this.type === 'profit_loss') {
        __out.push('\n    <col></col>\n    ');
      }
    
      __out.push('\n    <col></col>\n    <col></col>\n  </colgroup>\n  <thead>\n    <tr>\n      <th class="relative width-30px check-all-accounts-box">\n          <div class="checkbox checkbox-primary">\n            <input class="pointer check-all-accounts-input" type="checkbox">\n            <label class="pointer"></label>\n          </div>\n      </th>\n      ');
    
      if (this.type === 'profit_loss') {
        __out.push('\n        <th class="relative sortable" data-orderby="number" data-orderdir="ASC" width="20%">Code</th>\n        <th class="relative sortable" data-orderby="account_type" data-orderdir="ASC" width="20%">Type</th>\n        <th class="relative" data-orderby="accounting_class" data-orderdir="ASC" width="20%">Department</th>\n        <th class="relative sortable sorted-desc" data-orderby="category" data-orderdir="ASC" width="40%">Account</th>\n        <th></th>\n      ');
      } else {
        __out.push('\n        <th class="relative sortable" data-orderby="number" data-orderdir="ASC" width="20%">Code</th>\n        <th class="relative sortable" data-orderby="account_type" data-orderdir="ASC" width="20%">Type</th>\n        <th class="relative sortable sorted-desc" data-orderby="category" data-orderdir="ASC" width="30%">Account</th>\n        <th class="relative" width="30%">Detail Type</th>\n        <th></th>\n      ');
      }
    
      __out.push('\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>\n');
    
      if (this.type === 'profit_loss' && this.accountingClasses.length === 0) {
        __out.push('\n<div class="display-flex align-items-center p-b-20" style="height: 120px;">\n  <span class=\'in-grey-1050 m-l-auto m-r-auto\'>Select Departments and click Apply to fetch the chart of accounts from QuickBooks</span>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}