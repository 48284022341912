import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { TID } from '@src/types/common';
import { fetchLDBusinessFeature, fetchLDUserFeature } from '@src/utils/config';

const useLDBusinessFeatureQuery = (businessId?: TID, feature?: string) => {
  return useQuery(
    [QueryKey.ldBusinessFeature, { businessId, feature }],
    () => fetchLDBusinessFeature(String(businessId), feature!),
    {
      enabled:     !!(businessId && feature), // don't to any query if one this is not provided
      initialData: false, // The feature is disable by default
    },
  );
};

const useLDUserFeatureQuery = (userId?: TID, feature?: string) => {
  return useQuery(
    [QueryKey.ldUserFeature, { userId, feature }],
    () => fetchLDUserFeature(String(userId), feature!),
    {
      enabled:     !!(userId && feature),
      initialData: false,
    },
  );
};

export {
  useLDBusinessFeatureQuery,
  useLDUserFeatureQuery
};
