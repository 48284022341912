import { TID } from '@src/types/common';
import { IReconciliationCenter } from '@src/types/reconciliation_centers';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetReconciliationCenterByBusinessIdParams {
  businessId: TID,
}

interface IGetReconciliationCenterByBusinessIdResponse {
  reconciliationCenter: IReconciliationCenter,
}

const getReconciliationCenterByBusinessId = (
  params: IGetReconciliationCenterByBusinessIdParams,
): Promise<IGetReconciliationCenterByBusinessIdResponse> => {
  return apiGet(
    '/api/v1/reconciliation_centers/by_business_id',
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IGetReconciliationCenterByBusinessIdResponse;
  });
};

export {
  IGetReconciliationCenterByBusinessIdParams,
  IGetReconciliationCenterByBusinessIdResponse,
  getReconciliationCenterByBusinessId,
};
