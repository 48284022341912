import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { SettingsCogIcon } from '@src/components/utils/icomoon';

import Actions from './actions';

interface ISettingsPageProps {
  currentBusinessClientId?: TID,
  curBusinessId: TID,
  isReceivableOnly: boolean,
  isBusinessUser: boolean,
}

const SettingsPage = ({
  currentBusinessClientId,
  curBusinessId,
  isReceivableOnly,
  isBusinessUser,
}: ISettingsPageProps): JSX.Element => {
  return (
    <Root>
      <ActionsDropdown
        className="header-item-dropdown"
        title={ <SettingsCogIcon fontSize={ 24 } /> }
        titleClassName="header__notifications"
      >
        <Actions
          curBusinessId={ curBusinessId }
          currentBusinessClientId={ currentBusinessClientId }
          isBusinessUser={ isBusinessUser }
          isReceivableOnly={ isReceivableOnly }
        />
      </ActionsDropdown>
    </Root>
  );
};

export default SettingsPage;
