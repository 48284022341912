import React, { FC } from 'react';

import axios from 'axios';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAdjustmentEntryByParams } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { SpinnerIcon } from '@src/components/utils/fa_icons';

import DetailTable from './detail_table';
import EditPanel from './edit_panel';
import NameInfo from './name_info';

import styles from './styles.module.scss';

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const qboId = params.get('qbo_id') || undefined;
  const docytId = params.get('docyt_id') || undefined;
  return { qboId, docytId };
};

const Content: FC = () => {
  const business = useBusinessContext();
  const businessId = business.id;
  const params = getQueryParams();
  const { data, isLoading, isError, error } = useGetAdjustmentEntryByParams(params, businessId);

  const adjustmentEntry = data?.adjustmentEntry as IAdjustmentEntry;

  if (isLoading) {
    return <div className={ styles['loading-container'] }><SpinnerIcon spin fontSize={ 30 } /></div>;
  }

  if (isError) {
    let message = 'An unexpected error occurred';

    if (axios.isAxiosError(error)) {
      if (Array.isArray(error?.response?.data?.errors) && error?.response?.data.errors.length > 0) {
        message = error?.response?.data.errors[0];
      } else if (error?.response?.data?.error) {
        message = error.response.data.error;
      }
    }

    return (
      <div className={ styles['error-container'] }>
        <span>Error:</span>
        { message }
      </div>
    );
  }

  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        <NameInfo data={ adjustmentEntry } />
        <EditPanel data={ adjustmentEntry } />
      </div>

      <DetailTable data={ adjustmentEntry } />
    </div>
  );
};

export default Content;
