import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { StarIcon } from '@src/components/utils/fa_icons';

const starFlagOptions: TIconOption[] = [
  {
    icon:  <StarIcon fontSize={ 18 } />,
    label: 'Unstarred',
    value: 'unflagged',
  },
  {
    icon:  <StarIcon fontSize={ 18 } type="yellow" />,
    label: 'Starred',
    value: 'flagged',
  },
];

interface IStarFlagFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.IconSelectField>, 'options' | 'name'>
{
}

const StarFlagFilterField = ({
  ...props
}: IStarFlagFilterFieldProps): JSX.Element => {
  return (
    <Filter.IconSelectField
      isSearchable={ false }
      name="star_flag"
      options={ starFlagOptions }
      placeholder="Star Flag"
      { ...props }
    />
  );
};

export default React.memo(StarFlagFilterField);
