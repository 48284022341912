import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getPaymentAccounts,
  createPaymentAccount,
  updatePaymentAccount,
  IGetPaymentAccountsParams,
  ICreatePaymentAccountParams,
  IUpdatePaymentAccountParams,
  IGetPaymentAccountsResponse,
} from '@src/requests/payment_accounts';

const PAYMENT_ACCOUNTS_QUERY = 'payment_accounts';

const useGetPaymentAccounts = (params: IGetPaymentAccountsParams, enabled: boolean) => {
  return useQuery<IGetPaymentAccountsResponse, Error>(
    [PAYMENT_ACCOUNTS_QUERY, params],
    () => getPaymentAccounts(params),
    {
      enabled,
    },
  );
};

const useCreatePaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    ICreatePaymentAccountParams
  >(
    createPaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reconciliationPaymentAccounts);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

const useUpdatePaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdatePaymentAccountParams>(
    updatePaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reconciliationPaymentAccounts);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

export {
  useGetPaymentAccounts,
  useCreatePaymentAccount,
  useUpdatePaymentAccount,
};
