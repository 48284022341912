import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IRevenueServiceDocumentsFilter } from '@src/types/revenue_service_documents';

import RevenueCenterReconcileActionsView from '@src/components/revenue_center/reconcile/actions_view/actions_view';
import {
  useReconciliationRSDCollection,
  useTSDCollection,
} from '@src/components/revenue_center/reconcile/hooks';
import { ReconcileDocuments } from '@src/components/revenue_center/reconcile/index';
import ReconcileSidemenu from '@src/components/revenue_center/reconcile/sidemenu/sidemenu';
import { useRevenueSystemContext } from '@src/components/revenue_center/revenue_system_provider';
import { useFilterField } from '@src/components/ui_v2/filter';
import FullPageModal from '@src/components/ui_v2/full_page_modal';

import { usePaymentProcessorContext } from '../payment_processor_provider';

const ReconcilePage = (): JSX.Element | null => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();
  const revenueSystem = useRevenueSystemContext();
  const paymentProcessor = usePaymentProcessorContext();

  const [showOnlyUnreconciled] = useState(true);

  const rsdCollection = useReconciliationRSDCollection({
    revenueService,
    revenueSystem,
    paymentProcessorId: paymentProcessor?.id,
    showOnlyUnreconciled,
  });
  const tsdCollection = useTSDCollection({
    businessId:         business.id,
    paymentProcessorId: paymentProcessor?.id,
    revenueSection:     rsdCollection.section,
  });

  const [onlyUnreconciled, setOnlyUnreconciled] =
    useFilterField<IRevenueServiceDocumentsFilter, string>('onlyUnreconciled', rsdCollection.section);

  const handleOnlyUnreconciledChanged = useCallback((value: boolean) => {
    setOnlyUnreconciled(String(value));
  }, [setOnlyUnreconciled]);

  const handleCancelClick = useCallback(() => {
    window.Docyt.vent.trigger('close:reconcile:page');
  }, []);

  return (
    <FullPageModal>
      <FullPageModal.Header
        divider="horizontal"
        title="Reconcile Payment Processors"
        onCancel={ handleCancelClick }
      />
      <FullPageModal.Body
        hasSidemenu
      >
        <FullPageModal.SideMenu>
          <ReconcileSidemenu
            revenueSystem={ revenueSystem }
          />
        </FullPageModal.SideMenu>
        { paymentProcessor && (
          <ReconcileDocuments
            currentPaymentProcessor={ paymentProcessor }
            rsdCollection={ rsdCollection }
            showOnlyUnreconciled={ onlyUnreconciled === 'true' }
            tsdCollection={ tsdCollection }
            onClickCheckBox={ handleOnlyUnreconciledChanged }
          />
        ) }
      </FullPageModal.Body>
      <FullPageModal.FooterHeader>
        <RevenueCenterReconcileActionsView
          revenueSystem={ revenueSystem }
          rsdCollection={ rsdCollection }
          tsdCollection={ tsdCollection }
        />
      </FullPageModal.FooterHeader>
    </FullPageModal>
  );
};

export default React.memo(ReconcilePage);
