import React, { useCallback } from 'react';

import { UseQueryResult } from 'react-query';

import { IAddress } from '@src/types/address';
import { IBusinessVendor, TSelectAddressVendorSortColumn } from '@src/types/business_vendors';
import { ISorting } from '@src/types/sorting';
import { formatFullMonthDayYear } from '@src/utils/date_helpers';

import CollectionTable from '@src/components/ui_v2/collection_table';
import {
  QBOOnIcon,
} from '@src/components/utils/icomoon';
import { useItemSelector } from '@src/components/utils_v2/items_selector';

import AddressAction from '../action/address_action';

import styles from '../styles.module.scss';

interface ISelectMergeableVendorTableProps {
  infiniteQuery: UseQueryResult<IBusinessVendor, Error>,
  records: IAddress[],
  sorting: ISorting<TSelectAddressVendorSortColumn>,
  vendor: IBusinessVendor;
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>
}

const SelectMergeableVendorTable = ({
  infiniteQuery,
  records,
  sorting,
  vendor,
  setVendorData,
}: ISelectMergeableVendorTableProps) => {
  const { setSelected } = useItemSelector();

  const handleRowClick = useCallback((address: IAddress) => {
    setSelected(address?.id);
  }, [setSelected]);

  const quickbooksBadge = useCallback((address: IAddress) => {
    if (address?.id === vendor?.ledgerAddressId) {
      return <QBOOnIcon fontSize={ 18 } />;
    }
    return '';
  }, [vendor?.ledgerAddressId]);

  const internationalLabel = useCallback((address: IAddress) => {
    if (address?.addressCountry !== 'US') {
      return <span className={ styles['international-label'] }>International</span>;
    }
    return '';
  }, []);

  const authorizedLabel = useCallback((address: IAddress) => {
    if (address?.isAuthorized) {
      return <span className={ styles['authorized-label'] }>Authorized</span>;
    }
    return '';
  }, []);

  const addressAction = useCallback((address: IAddress) => {
    return (
      <AddressAction
        address={ address }
        businessId={ vendor?.businessId }
        setVendorData={ setVendorData }
        vendor={ vendor }
      />
    );
  }, [vendor, setVendorData]);

  return (
    <CollectionTable<IAddress, TSelectAddressVendorSortColumn>
      isRegionScroll
      className={ styles['select-mergeable-vendor-container'] }
      height="230px"
      query={ infiniteQuery }
      records={ records }
      showSettings={ false }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.TextColumn<IAddress, TSelectAddressVendorSortColumn>
        name="name"
        title="Recipient Name"
        value={ (address) => address?.name || '' }
        width="15%"
      />
      <CollectionTable.TextColumn<IAddress>
        hideValueTooltip
        name="addressLine1"
        title="Address"
        value={ (address) => `${address?.addressLine1} ${address?.addressLine2 ?? ''}, ${address?.addressCity}, ${address?.addressState}, ${address?.addressCountry}, ${address?.addressZip}` }
        width="15%"
      />
      <CollectionTable.TextColumn<IAddress, TSelectAddressVendorSortColumn>
        hideValueTooltip
        name="addedOn"
        title="Added on"
        value={ (address) => formatFullMonthDayYear(address?.createdAt) || '' }
        width="5%"
      />
      <CollectionTable.TextColumn<IAddress, TSelectAddressVendorSortColumn>
        hideValueTooltip
        name="addedBy"
        title="Added by"
        value={ (address) => address?.addressableType || '' }
        width="4%"
      />
      <CollectionTable.TextColumn<IAddress>
        hideValueTooltip
        name="quickbooksBadge"
        title=""
        value={ quickbooksBadge }
        width="2%"
      />
      <CollectionTable.TextColumn<IAddress>
        hideValueTooltip
        name="isInternational"
        title=""
        value={ internationalLabel }
        width="5%"
      />
      <CollectionTable.TextColumn<IAddress>
        hideValueTooltip
        name="isAuthorized"
        title=""
        value={ authorizedLabel }
        width="5%"
      />
      <CollectionTable.IconColumn<IAddress>
        name="action"
        title=""
        value={ addressAction }
        width="2%"
      />
    </CollectionTable>
  );
};

export default React.memo(SelectMergeableVendorTable);
