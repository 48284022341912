module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.chart_of_account_class_id ? 'document_checked' : ''));
    
      __out.push(' account-select-checkbox-column vertical-align-middle-important">\n  ');
    
      if (this.spinning) {
        __out.push('\n  <div class="checkbox-loader"></div>\n  ');
      } else {
        __out.push('\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" class="pointer checkbox-unclickable" ');
        __out.push(__sanitize(this.chart_of_account_class_id ? 'checked' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.was_linked ? 'checked disabled' : ''));
        __out.push('>\n    <label></label>\n  </div>\n  ');
      }
    
      __out.push('\n</td>\n<td class="document-clickable pointer detail-column" title="');
    
      __out.push(__sanitize(this.number));
    
      __out.push('">');
    
      __out.push(__sanitize(this.number));
    
      __out.push('</td>\n<td class="document-clickable pointer detail-column">');
    
      __out.push(__sanitize(this.acc_type_name));
    
      __out.push('</td>\n<td class="document-clickable pointer name-column flex-wrapper">\n  <span class="font-20 m-r-5 icon ');
    
      __out.push(__sanitize(this.qbo_status === 'synced' ? 'icon-qbo-on' : this.qbo_status === 'invalid' ? 'icon-warning in-red-400' : 'icon-qbo-off'));
    
      __out.push('">\n    <span class=\'path1\'></span>\n  </span>\n  <span class="account-name">');
    
      __out.push(__sanitize(this.coaName));
    
      __out.push('</span>\n</td>\n<td></td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}