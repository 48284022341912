import React, { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import {
  revenueServicePath,
  revenueServiceSettingsPath,
  revenueServicePathUnassignedPath,
  revenueServicePathAddSystemPath,
} from '@src/routes';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  AboutDocytIcon,
  SettingsGreenIcon,
  EnvelopeCustomIcon, AttachIcon,
} from '@src/components/utils/icomoon';

import { sortRevenueSystems } from '@src/components/revenue_center/utils'

import RevenueSystemItem from './revenue_system_item';

const RevenueCenterSidemenu = () => {
  const business = useBusinessContext();
  const service = useRevenueServiceContext();

  const revenueReportTypeMap = useMemo(() => {
    return new Map(service.revenueReportTypes.map((rt) => [rt.id, rt]));
  }, [service.revenueReportTypes]);

  const sortedRevenueSystems = useMemo(() => {
    return sortRevenueSystems(service.revenueSystems);
  }, [service.revenueSystems]);

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE }
        />
      ) }
    >
      { sortedRevenueSystems.length === 0 ? (
        <ServiceSidebar.Item
          href={ revenueServicePath(business.id) }
          icon={ <AboutDocytIcon /> }
          title="Get Started"
        />
      ) : (
        <ServiceSidebar.Item
          href={ revenueServicePathAddSystemPath(business.id) }
          icon={ <AttachIcon /> }
          title="Add Revenue System"
        />
      ) }
      {
        sortedRevenueSystems.map((system) => (
          <RevenueSystemItem
            key={ system.id }
            revenueReportType={ revenueReportTypeMap.get(system.revenueReportTypeId) }
            revenueSystem={ system }
          />
        ))
      }
      <ServiceSidebar.Item
        badge={ service.unassignedCount }
        href={ revenueServicePathUnassignedPath(business.id) }
        icon={ <EnvelopeCustomIcon variant="brown" /> }
        title="For review"
      />
      <ServiceSidebar.Item
        href={ revenueServiceSettingsPath(business.id) }
        icon={ <SettingsGreenIcon /> }
        title="Settings"
      />
    </ServiceSidebar>
  );
};

export default RevenueCenterSidemenu;
