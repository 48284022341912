import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAccountsPayableServiceDocumentsResponse } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { formatDate } from '@src/utils/date_helpers';

import InvoiceQueueFilter from '@src/components/accounts_payable/invoice_queue/filter';
import PossibleMatch from '@src/components/accounts_payable/invoice_queue/list/action_icons/possible_match';
import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { VendorAvatar } from '@src/components/ui/avatars';
import CollectionTable from '@src/components/ui_v2/collection_table';

import ChartOfAccountView from './action_icons/chart_of_account_view/chart_of_account_view';
import DocumentPreview from './action_icons/document_preview';
import InvoiceChat from './action_icons/invoice_chat';
import InvoiceDueDate from './action_icons/invoice_due_date';
import InvoiceStarFlag from './action_icons/invoice_star_flag';
import InvoiceStatus from './action_icons/invoice_status';
import Action from './actions';

interface IInvoiceQueueTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAccountsPayableServiceDocumentsResponse, Error>,
  records: IAccountsPayableServiceDocument[],
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
  currentUserId: TID,
}

const vendorAvatar = (document: IAccountsPayableServiceDocument) => {
  return <VendorAvatar size="100%" vendor={ document.vendor || null } />;
};

const documentPreviewButton = (document: IAccountsPayableServiceDocument) => {
  return <DocumentPreview document={ document } />;
};

const chartOfAccountView = (document: IAccountsPayableServiceDocument) => {
  return <ChartOfAccountView document={ document } />;
};

const possibleMatchIcon = (document: IAccountsPayableServiceDocument) => {
  return <PossibleMatch document={ document } />;
};

const chatIcon = (document: IAccountsPayableServiceDocument) => {
  return <InvoiceChat document={ document } />;
};

const dueDateFormatted = (document: IAccountsPayableServiceDocument) => {
  return <InvoiceDueDate document={ document } />;
};

const uploaderName = (document: IAccountsPayableServiceDocument) => {
  return (
    <span className="inline-block">
      { document.uploaderName }
    </span>
  );
};

const invoiceQBOStatusIcon = (document: IAccountsPayableServiceDocument) => {
  return <QBOStatusIcon fontSize={ 18 } model={ document } />;
};

const actions = (document: IAccountsPayableServiceDocument) => {
  return <Action document={ document } />;
};

const InvoiceQueueTable = ({
  infiniteQuery,
  records,
  sorting,
  currentUserId,
}: IInvoiceQueueTableProps) => {
  const starFlagIcon = (document: IAccountsPayableServiceDocument) => {
    return (
      <InvoiceStarFlag
        currentUserId={ currentUserId }
        document={ document }
      />
    );
  };

  const invoiceStatusLabel = (document: IAccountsPayableServiceDocument) => {
    return (
      <InvoiceStatus
        currentUserId={ currentUserId }
        document={ document }
      />
    );
  };

  const typeLabel = useCallback((document: IAccountsPayableServiceDocument) => {
    if (document.isExpenseReport) {
      return <span>Reimbursement</span>;
    }

    return <span>Bill</span>;
  }, []);

  return (
    <CollectionTable<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ <InvoiceQueueFilter /> }
      height="750px"
      idField="documentId"
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="star_flag"
        title="Star Flag"
        value={ starFlagIcon }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        icon={ vendorAvatar }
        name="vendor_id"
        sortColumn="merchant"
        title="Payee"
        value={ (document) => document.vendor?.name ?? 'Unknown Vendor' }
        width="45%"
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="document_preview"
        title="Document Preview"
        value={ documentPreviewButton }
      />
      <CollectionTable.LabelColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="status"
        sortColumn="state"
        title="Status"
        value={ invoiceStatusLabel }
        variant={ (document) => document.state }
        width="160px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="category"
        sortColumn="category"
        title="Chart Of Account"
        value={ chartOfAccountView }
        width="35%"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="invoice_number"
        sortColumn="invoice_number"
        title="Invoice #"
        value={ (document) => document.invoiceNumber }
        width="150px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="is_expense_report"
        title="Type"
        value={ typeLabel }
        width="110px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="invoice_date"
        sortColumn="invoice_date"
        title="Bill Date"
        value={ (document) => document.invoiceDate && `${formatDate(document.invoiceDate, 'MMM DD, YYYY')}` }
        width="100px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="due_date"
        sortColumn="due_date"
        title="Due In"
        value={ dueDateFormatted }
        width="100px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hidden
        name="accounting_class"
        title="Department"
        value={ (document) => {
          if (document.serviceDocumentSplitsCount !== 0) {
            return (`${document.serviceDocumentSplitsCount} Splits`);
          }

          return document?.accountingClass?.name || '';
        } }
        width="200px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="uploader_name"
        sortColumn="uploader_name"
        title="Uploader"
        value={ uploaderName }
        width="140px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hidden
        name="account_number"
        sortColumn="account_number"
        title="Account No"
        value={ (document) => (document.accountNumber || '') }
        width="100px"
      />
      <CollectionTable.AmountColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="amount"
        sortColumn="invoice_amount"
        title="Amount"
        value={ (document) => (document.invoiceAmount || '0.0') }
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="possible_matches"
        title="Possible Matches"
        value={ possibleMatchIcon }
        width="48px"
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="chat"
        title="Chat"
        value={ chatIcon }
        width="48px"
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="qbo_sync_status"
        title="QBO Status"
        value={ invoiceQBOStatusIcon }
      />
      <CollectionTable.ActionsColumn<IAccountsPayableServiceDocument>
        name="actions"
        title="Actions"
        value={ actions }
      />
    </CollectionTable>
  );
};

export default React.memo(InvoiceQueueTable);
