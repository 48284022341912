module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<a class="btn font-15 font-bold in-grey-800 btn-payment-account-dropdown" type="button" id="payment-account-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  <span class="fa fa-ellipsis-h m-l-5"></span>\n</a>\n<ul class="reconciliation-payment-account-dropdown dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="payment-account-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n  ');
    
      if (!this.reconciliation_payment_account_id) {
        __out.push('\n    ');
        if (!this.is_archived) {
          __out.push('\n    ');
          if ((this.financial_institution_bank_account && this.financial_institution_bank_account.is_active) || (this.plaid_item && this.plaid_item.active)) {
            __out.push('\n    <li><a tabindex="-1" class="resync-transactions-btn"><i class="icon icon-qbo-outline font-18 m-r-15"></i> Re-sync transactions</a></li>\n    ');
          }
          __out.push('\n    <li><a tabindex="-1" class="account-settings-btn"><i class="icon icon-settings font-18 m-r-15"></i> Account Settings</a></li>\n    ');
        }
        __out.push('\n    ');
        if (this.payment_accounts_count === 1) {
          __out.push('\n      ');
          if (this.is_archived) {
            __out.push('\n        <li><a tabindex="-1" class="unarchive-account-btn"><i class="icon icon-undo font-16 m-r-15"></i> Unarchive Account</a></li>\n      ');
          } else {
            __out.push('\n        <li><a tabindex="-1" class="archive-account-btn"><i class="icon icon-account-archive font-16 m-r-15"></i> Archive Account</a></li>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    ');
        if (!this.is_archived) {
          __out.push('\n      <li><a tabindex="-1" class="employee-cards-btn"><i class="icon icon-employee-cards font-16 m-r-15"></i> Add/View Employee Cards</a></li>\n      ');
          if (this.transaction_import_type === "manual" && this.id) {
            __out.push('\n      <li class="btn-import-csv"><a tabindex="-1"><i class="icon icon-upload-csv-icon font-22 m-r-15"></i> Import CSV</a></li>\n      ');
          }
          __out.push('\n      <li><a tabindex="-1" class="disconnect-account-btn danger-link display-flex align-items-center"><span class="icon-cross-red font-22 m-r-20"></span> Disconnect Account</a></li>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    ');
        if (this.archived) {
          __out.push('\n    <li><a tabindex="-1" class="unarchive-account-btn"><i class="icon icon-undo font-16 m-r-15"></i> Unarchive Account</a></li>\n    ');
        } else {
          __out.push('\n    <li><a tabindex="-1" class="archive-account-btn"><i class="icon icon-account-archive font-16 m-r-15"></i> Archive Account</a></li>\n    ');
        }
        __out.push('\n    ');
        if (this.transaction_import_type === "manual" && this.id) {
          __out.push('\n    <li class="btn-import-csv"><a tabindex="-1"><i class="icon icon-upload-csv-icon font-22 m-r-15"></i> Import CSV</a></li>\n    ');
        }
        __out.push('\n    <li><a tabindex="-1" class="delete-account-btn danger-link"><i class="icon icon-trashcan m-r-15"></i> Delete Account</a></li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}