import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

interface IServiceSidebarItemProps {
  href: string,
  title: string,
  isHidden?: boolean,
  onClick?: () => void,
  prefixIcon?: React.ReactNode,
  suffixIcon?: React.ReactNode,
}

const ServiceSidebarItem = ({
  href,
  title,
  isHidden,
  onClick,
  prefixIcon,
  suffixIcon,
}: IServiceSidebarItemProps): JSX.Element | null => {
  const location = useLocation();

  const itemClasses = classNames(
    styles['dropdown-btn'],
    'new-sidenav',
    {
      active: location.pathname === href || `${location.pathname}${location.search}` === href,
    },
  );

  const subMenuItemClasses = classNames(
    styles['submenu-item-container'],
    {
      [styles['active-indicator']]: location.pathname === href || `${location.pathname}${location.search}` === href,
    },
  );

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
      return;
    }

    Backbone.history.navigate(href, { trigger: true });
  }, [href, onClick]);

  if (isHidden) return null;

  return (
    <li className={ itemClasses }>
      <a className="submenu-title" href={ href } onClick={ handleClick }>
        { prefixIcon }
        <Tooltip.Hover className={ subMenuItemClasses } content={ title }>
          <span className="nav-item-text">{title}</span>
        </Tooltip.Hover>
        { suffixIcon }
      </a>
    </li>
  );
};

export default ServiceSidebarItem;
