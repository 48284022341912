import React, { useState } from 'react';

import { rcMonthEndDocmentsBankStatementsPath, rcMonthEndDocmentsOtherDocumentsPath, rcMonthEndReconciliationPath } from '@src/routes';
import { TID } from '@src/types/common';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  BankTransfersNavIcon,
  CategoryIcon,
  EquityLiabilityAssetsIcon,
  ExpensesNavIcon,
  LoanPaymentsIcon,
  MoneyExchangeIcon,
  PayrollLiabilityNavIcon,
  ReconciliationSummaryIcon,
  RevenueNavIcon,
  SettingsGreenIcon,
  AnalyticsIcon,
  UncategorizedIcon,
  UnreconciledIcon,
  AllTransactionsIcon,
  MonthEndDocumentsIcon,
  BankStatementsIcon,
  OtherDocumentsIcon,
  LedgerSyncingCentreIcon,
} from '@src/components/utils/icomoon';

import DocumentRequestsIcon from '../utils/icomoon/document_requests';

interface IReconciliationCenterSidebarProps {
  businessId: TID,
}

const ReconciliationCenterSidebar = ({
  businessId,
}: IReconciliationCenterSidebarProps): JSX.Element => {
  const [monthEndCloseEnabled, setMonthEndCloseEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
      String(businessId),
    ),
  );

  if (monthEndCloseEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
      String(businessId),
    ).then((res) => {
      setMonthEndCloseEnabled(res);
    });
  }

  let monthEndItem = null;
  if (monthEndCloseEnabled) {
    monthEndItem = (
      <ServiceSidebar.Item
        href={ rcMonthEndReconciliationPath(businessId) }
        icon={ <AnalyticsIcon /> }
        title="Month-End Close"
      />
    );
  }

  return (
    <Root>
      <ServiceSidebar>
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center` }
          icon={ <ReconciliationSummaryIcon /> }
          title="Summary"
        />
        { monthEndItem }
        <ServiceSidebar.SubMenu
          expandedKey="doc_request_expanded"
          icon={ <MonthEndDocumentsIcon /> }
          launchDarklyFeature={ window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG }
          title="Month End Documents"
        >
          <ServiceSidebar.Item
            active={ window.location.pathname.includes(`/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`) }
            href={ rcMonthEndDocmentsBankStatementsPath(businessId) }
            icon={ <BankStatementsIcon /> }
            launchDarklyFeature={ window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG }
            title="Bank Statements"
          />
          <ServiceSidebar.Item
            href={ rcMonthEndDocmentsOtherDocumentsPath(businessId) }
            icon={ <OtherDocumentsIcon /> }
            launchDarklyFeature={ window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG }
            title="Other Documents"
          />
        </ServiceSidebar.SubMenu>
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/journal_entries` }
          icon={ <DocumentRequestsIcon /> }
          title="Journal Entries"
        />
        <ServiceSidebar.Item
          active={ window.location.pathname === `/businesses/${businessId}/reconciliation_center/transactions` }
          href={ `/businesses/${businessId}/reconciliation_center/transactions` }
          icon={ <UncategorizedIcon /> }
          title="Uncategorized Transactions"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/documents` }
          icon={ <UnreconciledIcon /> }
          title="Uncleared Documents"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/all_transactions` }
          icon={ <AllTransactionsIcon /> }
          title="All Transactions"
        />
        <ServiceSidebar.SubMenu
          expandedKey="reconciliation_expanded"
          icon={ <MoneyExchangeIcon /> }
          title="Transaction Types"
        >
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/expenses` }
            icon={ <ExpensesNavIcon /> }
            title="Expenses"
          />
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/revenue` }
            icon={ <RevenueNavIcon /> }
            title="Income"
          />
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/bank_transfers` }
            icon={ <BankTransfersNavIcon /> }
            title="Bank Transfers"
          />
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/payroll` }
            icon={ <PayrollLiabilityNavIcon /> }
            title="Payroll Charges"
          />
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/equity` }
            icon={ <EquityLiabilityAssetsIcon /> }
            title="Equity / Liabilities / Assets"
          />
          <ServiceSidebar.Item
            href={ `/businesses/${businessId}/reconciliation_center/loan_payments` }
            icon={ <LoanPaymentsIcon /> }
            title="Loan Payments"
          />
        </ServiceSidebar.SubMenu>
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/categories` }
          icon={ <CategoryIcon /> }
          title="Chart of Accounts"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre` }
          icon={ <LedgerSyncingCentreIcon /> }
          title="Ledger Syncing Center"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/settings` }
          icon={ <SettingsGreenIcon /> }
          title="Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default ReconciliationCenterSidebar;
