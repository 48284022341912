import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getReconciliationCenterByBusinessId,
  IGetReconciliationCenterByBusinessIdParams,
  IGetReconciliationCenterByBusinessIdResponse,
} from '@src/requests/reconciliation_centers';

const useReconciliationCenterByBusinessId = (
  params: IGetReconciliationCenterByBusinessIdParams,
) => {
  return useQuery<IGetReconciliationCenterByBusinessIdResponse, Error>(
    [QueryKey.reconciliationCenterByBusinessId, params],
    () => getReconciliationCenterByBusinessId(params),
  );
};

export {
  useReconciliationCenterByBusinessId,
};
