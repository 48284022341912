import React, { useMemo } from 'react';

import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import {
  canBulkEditCategory,
  canBulkVerifyInvoices,
  canBulkApproveInvoices,
  canBulkUnApproveInvoices,
  canBulkEditInvoices,
  canBulkDeleteInvoices,
  canBulkMarkPaidInvoices,
  canBulkPayViaAchInvoices,
  canBulkPayViaDocytInvoices,
} from '@src/components/accounts_payable/invoice_queue/helpers';
import Dropdown from '@src/components/ui/dropdown';
import { AngleDownIcon } from '@src/components/utils/fa_icons';

import ApproveInvoicesAction from './approve_invoices_action';
import DeleteInvoicesAction from './delete_invoices_action';
import DocytAchInvoicesAction from './docyt_ach_invoices_action';
import DocytCheckInvoicesAction from './docyt_check_invoices_action';
import EditCategoryInvoicesAction from './edit_category_invoices_action';
import EditInvoicesAction from './edit_invoices_action';
import ForwardInvoicesAction from './forward_invoices_action';
import MarkPaidInvoicesAction from './mark_paid_invoices_action';
import MergeInvoicesAction from './merge_invoices_action';
import UnApproveInvoicesAction from './unapprove_invoices_action';
import VerifyInvoicesAction from './verify_invoices_action';

interface IBulkActionsProps {
  selectedInvoices: IAccountsPayableServiceDocument[],
  currentUserId: TID,
}

const BulkActions = ({
  selectedInvoices,
  currentUserId,
}: IBulkActionsProps): JSX.Element => {
  const service = useAccountsPayableServiceContext();
  const userRole = service.businessServiceRole;

  const selectedInvoiceIds = useMemo(() => {
    return selectedInvoices.map((d) => d.id) || [];
  }, [selectedInvoices]);

  return (
    <Dropdown
      className="bulk-action-container"
    >
      <Dropdown.ButtonToggle
        className="btn-blue-on-hover"
        disabled={ selectedInvoices.length === 0 }
        fontSize={ 15 }
        fontVariant="bold"
      >
        Bulk Actions
        <AngleDownIcon ml={ 5 } />
      </Dropdown.ButtonToggle>
      {
        selectedInvoiceIds.length > 0 && (
          <Dropdown.Menu
            className="toggle-action-dropdown"
          >
            {
              canBulkEditCategory(selectedInvoices) && (
                <EditCategoryInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            <MergeInvoicesAction invoiceIds={ selectedInvoiceIds } />
            <ForwardInvoicesAction invoiceIds={ selectedInvoiceIds } />
            {
              canBulkVerifyInvoices(selectedInvoices, userRole) && (
                <VerifyInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkApproveInvoices(selectedInvoices, currentUserId, userRole) && (
                <ApproveInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkUnApproveInvoices(selectedInvoices, currentUserId, userRole) && (
                <UnApproveInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkEditInvoices(selectedInvoices, userRole) && (
                <EditInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkPayViaAchInvoices(selectedInvoices, userRole) && (
                <DocytAchInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkPayViaDocytInvoices(selectedInvoices, userRole) && (
                <DocytCheckInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkMarkPaidInvoices(selectedInvoices, userRole) && (
                <MarkPaidInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
            {
              canBulkDeleteInvoices(selectedInvoices, userRole) && (
                <DeleteInvoicesAction invoiceIds={ selectedInvoiceIds } />
              )
            }
          </Dropdown.Menu>
        )
      }
    </Dropdown>
  );
};

export {
  IBulkActionsProps,
  BulkActions,
};
