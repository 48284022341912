import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useSetUnLinkBusinessLaborReportsDepartment } from '@src/hooks/queries/report_service/report_datas';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import { IEditMappingLaborQueryDataProps } from '../hooks';

interface IBusinessReportsEditMappingUnlinkModalProps extends IUseModalProps {
  itemId: string,
  data: IEditMappingLaborQueryDataProps,
}

const BusinessReportsEditMappingUnlinkModal = ({
  data,
  itemId,
  isOpen,
  onCancel,
  onDone
}: IBusinessReportsEditMappingUnlinkModalProps) => {
  const unlinkDimension = useSetUnLinkBusinessLaborReportsDepartment();
  const { mutate } = unlinkDimension;

  const handleProceed = useCallback(() => {
    mutate({
      reportId: data.report.id as string,
      itemId: itemId as string
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:link:destroyed');
        onDone();
      },
    });
  }, [mutate, data.report, itemId, onDone]);

  return (
    <>
      <Modal.Standard
        proceedTitle="Unlink"
        show={ isOpen }
        title="Unlink Dimension label"
        onCancel={ onCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="text-center">
            Are you sure you want to unlink Dimension label?
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ unlinkDimension } />
    </>
  );
};

const useEditMappingUnlinkModal = makeUseModal(BusinessReportsEditMappingUnlinkModal);

export {
  IBusinessReportsEditMappingUnlinkModalProps,
  useEditMappingUnlinkModal,
  BusinessReportsEditMappingUnlinkModal as default,
};
