import React, { useEffect, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBankFeedTransactionServiceDocuments } from '@src/hooks/queries/bank_feed_transaction_service_documents';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { IGetBankFeedInformationResponse } from '@src/requests/bank_feed_transaction_service_documents';
import { IBankStatement, TBankFeedTransactionServiceDocumentColumn } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';

import BalanceInfo from './balance_info';
import TransactionsFilter, { ITransactionsFilter } from './filter_transactions';
import Table from './transactions_table/table';

interface ITransactionsListProps {
  businessId: TID,
  bankStatement: IBankStatement,
  bankFeedInformation?: IGetBankFeedInformationResponse,
  onChangeBankFeedTransactions: (bankFeedBalance: number, unselectedCount: number) => void,
}

const DefaultSorting: ISortingParams<TBankFeedTransactionServiceDocumentColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'asc',
};

const TransactionsList = ({
  businessId,
  bankStatement,
  bankFeedInformation,
  onChangeBankFeedTransactions,
}: ITransactionsListProps): JSX.Element => {
  const isCreditCard =
    bankStatement.reconciliationPaymentAccount.accountType
      === window.Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE;

  const sorting = useSorting<TBankFeedTransactionServiceDocumentColumn>(
    { defaultSorting: DefaultSorting },
  );

  const filter = useFilter<ITransactionsFilter>({ });

  let startTransactionDate: string;
  const isVerified =
    bankStatement.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED;

  if (isVerified) {
    startTransactionDate = bankStatement.firstBankFeedTransactionDate;
  } else {
    startTransactionDate = bankFeedInformation?.lastVerifiedStatementTransactionDate
      || bankStatement.firstBankFeedTransactionDate;
  }

  useEffect(() => {
    filter.update(
      {
        transaction_date: {
          gte: startTransactionDate,
          lte: bankStatement.lastBankFeedTransactionDate,
        },
      },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankStatement,
    bankFeedInformation?.lastVerifiedStatementTransactionDate,
  ]);

  const query = useGetBankFeedTransactionServiceDocuments({
    businessId,
    bankStatementId: bankStatement.id,
    filter:          filter.data,
    ...sorting.data,
  });

  const bankFeedTransactionsPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const transactions = useMemo(() => {
    return flatten(bankFeedTransactionsPages.map((p) => p.collection));
  }, [bankFeedTransactionsPages]);

  const hasEmployeeCards = useMemo(() => {
    return bankFeedTransactionsPages[0]?.meta.hasEmployeeCards || false;
  }, [bankFeedTransactionsPages]);

  const selectedTransactions = useMemo(() => {
    return transactions.filter((t) => t.bankStatement?.id === bankStatement.id);
  }, [bankStatement.id, transactions]);

  const verifiedTransactions = useMemo(() => {
    return transactions.filter((t) => t.bankStatement?.state
      === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED);
  }, [transactions]);

  const deposit = Number(bankFeedInformation?.deposit) || 0;
  const withdrawals = Number(bankFeedInformation?.withdrawals) || 0;
  const charges = Number(bankFeedInformation?.charges) || 0;
  const payments = Number(bankFeedInformation?.payments) || 0;

  const bankFeedBalance = useMemo(() => {
    const balance = bankStatement.reconciliationPaymentAccount.accountType === 'credit_card'
      ? Number(bankStatement.beginningBalance) + charges - payments
      : Number(bankStatement.beginningBalance) + deposit - withdrawals;
    return Number(balance.toFixed(2));
  }, [
    bankStatement.beginningBalance,
    bankStatement.reconciliationPaymentAccount.accountType,
    charges, deposit, payments, withdrawals]);

  const allSetBankStatement = bankFeedInformation?.allSetBankStatement || false;

  useEffect(() => {
    onChangeBankFeedTransactions(
      bankFeedBalance,
      transactions.length - selectedTransactions.length - verifiedTransactions.length,
    );
  }, [
    bankFeedBalance,
    onChangeBankFeedTransactions,
    selectedTransactions.length,
    verifiedTransactions.length,
    transactions.length]);

  return (
    <>
      <BalanceInfo
        bankFeedBalance={ bankFeedBalance }
        beginningBalance={ bankStatement.beginningBalance }
        charges={ charges }
        closingBalance={ bankStatement.closingBalance }
        deposit={ deposit }
        isCreditCard={ isCreditCard }
        payments={ payments }
        withdrawals={ withdrawals }
      />
      <div className="banking-accounts-table-wrapper">
        <TransactionsFilter
          filter={ filter }
        />
        <Table
          allSetBankStatement={ allSetBankStatement }
          bankStatement={ bankStatement }
          businessId={ businessId }
          endDate={ filter.data?.transaction_date?.lte }
          hasEmployeeCards={ hasEmployeeCards }
          isCreditCard={ isCreditCard }
          query={ query }
          selectedTransactionsCount={ selectedTransactions.length }
          sorting={ sorting }
          startDate={ filter.data?.transaction_date?.gte }
          transactions={ transactions }
        />
      </div>
    </>
  );
};

export default TransactionsList;
